import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { Tabs, TabsProps } from 'antd';
import { IError, IProject } from '../../service/Type';
import { loadProject } from '../../service/api/Project';
import WebsitePage from './WebsitePage';
import WebsitePost from './WebsitePost';
import WebsiteProduct from './WebsiteProduct';
import { Helmet } from 'react-helmet';

export default function Website() {
  const params = useParams();
  const { projectId } = params;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [project, setProject] = useState<IProject>();

  const load = async () => {
    const projectResult = await loadProject(projectId as string);
    if (projectResult.result) {
      await setProject(projectResult.result);
    }
    if (projectResult.errors) {
      await setError(projectResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, [projectId]);

  const onChange = (key: string) => {
    console.log(key);
  };

  if (loading) {
    return <div className="page">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="page">Hata</div>;
  }

  if (!project) {
    return <div className="page">Hata</div>;
  }

  const items: TabsProps['items'] = [];

  items.push({
    key: 'page',
    label: 'Sayfalar',
    children: <WebsitePage project={project} onDelete={() => load()} />,
  });

  if (project.isEcommerce) {
    items.push({
      key: 'product',
      label: 'Ürünler',
      children: <WebsiteProduct project={project} />,
    });
  }

  if (project.isBlog) {
    items.push({
      key: 'post',
      label: 'Yazılar',
      children: <WebsitePost project={project} />,
    });
  }

  return (
    <>
      <Helmet>
        <title>{project?.title}</title>
      </Helmet>
      <div className="page">
        <div className="page-title">
          <h1>
            {project?.logo && <img src={project?.logo} alt={project?.title} />}
            {project?.title}
          </h1>
          <span style={{ flex: 1 }} />
          <span>
            <LinkOutlined /> Website Linki:
            <span> </span>
            <a href={`${project?.linkSchema}${project?.link}`} target="_blank" rel="noreferrer">
              {`${project?.linkSchema}${project?.link}`}
            </a>
          </span>
        </div>

        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </>
  );
}
