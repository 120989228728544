import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentMissionVisionValues } from '../../../../service/api/Content';

export interface EProps {
  missionTitle: string;
  mission: string;
  visionTitle: string;
  vision: string;
  valuesTitle: string;
  values: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Süreç Adımları',
  description: 'Kullanıcıların belirli bir süreci veya adımları takip etmelerini sağlayan yönlendirici bölüm.',
  type: 'step-1',
  code: 'STP1',
  category: ['step'],
  suitable: ['main'],
  propsDefault: async (project: IProject, page: IPage) => {
    const cMissionVisionValues = await contentMissionVisionValues(
      project.projectId,
      page.pageId,
      `Write mission, vision and values content. "`,
    );

    return {
      missionTitle: 'Misyonumuz',
      visionTitle: 'Vizyonumuz',
      valuesTitle: 'Değerlerimiz',
      mission: cMissionVisionValues?.result?.mission || '',
      vision: cMissionVisionValues?.result?.vision || '',
      values: cMissionVisionValues?.result?.values || '',
    };
  },
};

export default config;
