import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  title: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Önemli Uyarılar',
  description: 'Kullanıcıları önemli bilgilendirme veya uyarılar için kullanılan dinamik bir uyarı mesajı bloğu.',
  type: 'alert-2',
  code: 'ALRT2',
  category: ['content'],
  suitable: ['header', 'main', 'footer'],
  schema: {
    type: 'object',
    required: ['title'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
    },
  },
  styleDefault: {
    container: 'full',
    background: '#ccc',
    color: '#fff',
  },
  propsDefault: async () => {
    return {
      title: 'Yeni sayımız yayınlandı!',
    };
  },
};

export default config;
