import { EditorConfigW } from '../../../../service/Type';

export interface CarouselSlide {
  src: string;
  keep?: number;
  type: 'image' | 'video' | 'youtube';
  text?: React.ReactElement;
  button?: React.ReactElement;
}

export interface EProps {
  slides: CarouselSlide[];
  remove: number;
}

const config: EditorConfigW<EProps> = {
  title: 'Görsel Slayt Gösterisi',
  description: 'Görsellerin veya içeriklerin otomatik veya kullanıcı etkileşimiyle değiştiği, dinamik bir görsel slayt gösterisi.',
  type: 'slider-3',
  code: 'SLDR3',
  category: ['slider'],
  suitable: ['main'],
  schema: {
    title: '',
    type: 'object',
    required: ['slides'],
    properties: {
      slides: {
        type: 'array',
        title: 'Slidelar',
        items: {
          properties: {
            type: {
              type: 'string',
              title: 'Slide Tipi',
              oneOf: [
                { title: 'Görsel', const: 'image' },
                { title: 'Youtube', const: 'youtube' },
              ],
            },
          },
          dependencies: {
            type: {
              oneOf: [
                {
                  properties: {
                    type: { enum: ['image'] },
                    src: { type: 'string', title: 'Görsel' },
                    additionalProperties: false,
                  },
                },
                {
                  properties: {
                    type: { enum: ['video', 'youtube'] },
                    url: { type: 'string', title: 'Youtube ID' },
                    keep: { type: 'number', title: 'Video Süresi' },
                    additionalProperties: false,
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
  schemaUI: {
    wrapper: {
      'ui:widget': 'radio',
    },
    height: {
      'ui:widget': 'range',
      'ui:description': 'Minimum: 300, Maksimum: 1000',
    },
    slides: {
      items: {
        'ui:title': 'Slide',
        src: {
          'ui:widget': 'imageUpload',
        },
        url: {
          'ui:widget': 'text',
        },
        type: {
          'ui:widget': 'select',
        },
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      remove: 0,
      slides: [
        {
          type: 'image',
          src: '/img/slider/slide-1.jpg',
        },
        {
          type: 'image',
          src: '/img/slider/slide-2.jpg',
        },
        {
          type: 'image',
          src: '/img/slider/slide-3.jpg',
        },
        {
          type: 'image',
          src: '/img/slider/slide-4.jpg',
        },
      ],
      height: 500,
      wrapper: 'block',
    };
  },
};

export default config;
