import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  link: string;
  text: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Sayfa Başlığı ve Girişi',
  description: 'Herhangi bir sayfanın başlığını ve kısa açıklamasını içeren, sayfanın konusunu belirleyen bölüm.',
  type: 'title-2',
  code: 'BSLK2',
  category: ['title', 'content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'description'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      link: { type: 'string', title: 'Link', default: '' },
      text: { type: 'string', title: 'Buton Text', default: '' },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for sub hero section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || page.title,
      description:
        cTitleDescription?.result?.description ||
        'Etkileyici bir başlık yazmak için net, dikkat çekici, değer vaadi sunan, hedef kitleyi düşünen, SEO odaklı, özgün ve etkileşimi teşvik eden bir yaklaşım benimseyin.',
      link: '',
      text: 'İletişime Geçin',
    };
  },
};

export default config;
