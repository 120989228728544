import { Formik, ErrorMessage } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { Form, Button, Card, Select, ColorPicker, Row, Col } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { fontList } from '../../../service/Font';
import EditorThemeConfigProvider from '../../../component/editor/EditorThemeConfigProvider';
import './WebsiteSettingStyle.scss';
import { IProject } from '../../../service/TypeGraphql';

interface WebsiteSettingStyleProps {
  project: IProject;
}

export default function WebsiteSettingStyle({ project }: WebsiteSettingStyleProps) {
  const initialValues = {
    font: 'Roboto',
    colorPrimary: '#106fff',
    colorText: '#333',
  };

  const schema = yup.object().shape({
    font: yup.string().required('Website adı girilmelidir').max(256, 'Website adı en fazla 256 karakter uzunluğunda olabilir'),
    colorPrimary: yup.string().required('Ana renk seçilmelidir').max(256, 'Website açıklaması en fazla 256 karakter uzunluğunda olabilir'),
    colorText: yup.string().required('Yazı rengi seçilmelidir').max(256, 'Website açıklaması en fazla 256 karakter uzunluğunda olabilir'),
  });

  const onSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange
      validateOnBlur
      onSubmit={async (values) => {
        await onSubmit(values);
        return true;
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Card title="Font ve Renk Ayarları" style={{ marginBottom: 16 }}>
                <Form.Item label="Font">
                  <Select
                    value={values.font ? values.font : ''}
                    style={{ width: '100%' }}
                    onChange={(e) => setFieldValue('font', e)}
                    options={fontList.map((font) => ({
                      label: font.title,
                      value: font.title,
                    }))}
                  />
                  <ErrorMessage name="font" component="div" className="ant-form-feedback" />
                </Form.Item>

                {fontList.find((f) => f.title === values.font)?.url && (
                  <link rel="stylesheet" href={fontList.find((f) => f.title === values.font)?.url} />
                )}

                <div className="font-title">Örnek Paragraf</div>
                <p
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  <strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                  to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem
                  Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <div className="font-title">Örnek Başlık H1</div>
                <h1
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h1>
                <div className="font-title">Örnek Başlık H2</div>
                <h2
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h2>
                <div className="font-title">Örnek Başlık H3</div>
                <h3
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h3>
                <div className="font-title">Örnek Başlık H4</div>
                <h4
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h4>
                <div className="font-title">Örnek Başlık H5</div>
                <h5
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h5>
                <div className="font-title">Örnek Başlık H6</div>
                <h6
                  style={{
                    fontFamily: fontList.find((f) => f.title === values.font)?.fontFamily,
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </h6>
              </Card>
            </Col>
            <Col xs={24} lg={12}>
              <Card title="Renkler" style={{ marginBottom: 16 }}>
                <Form.Item label="Ana Renk">
                  <ColorPicker
                    value={values.colorPrimary ? values.colorPrimary : ''}
                    onChange={(e) => setFieldValue('colorPrimary', e)}
                    showText
                  />
                  <ErrorMessage name="colorPrimary" component="div" className="ant-form-feedback" />
                </Form.Item>
                <Form.Item label="Yazı Rengi">
                  <ColorPicker value={values.colorText ? values.colorText : ''} onChange={(e) => setFieldValue('colorText', e)} showText />
                  <ErrorMessage name="colorText" component="div" className="ant-form-feedback" />
                </Form.Item>
                <EditorThemeConfigProvider project={project}>
                  <div className="font-title">Örnek Ana Renk Buton</div>
                  <p>
                    <Button type="primary">Lorem Ipsum is simply dummy text</Button>
                  </p>
                  <div className="font-title">Örnek İkinci Renk Buton</div>
                  <p>
                    <Button type="default">Lorem Ipsum is simply dummy text</Button>
                  </p>
                  <div className="font-title">Örnek Paragraf</div>
                  <p>
                    <strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                    to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem
                    Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
                    Ipsum.
                  </p>
                </EditorThemeConfigProvider>
              </Card>
            </Col>
          </Row>

          <Button
            onClick={() => handleSubmit()}
            type="primary"
            disabled={isSubmitting}
            icon={<SaveOutlined />}
            style={{ marginBottom: 16 }}
          >
            Kaydet
          </Button>
        </Form>
      )}
    </Formik>
  );
}
