import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { TabsProps } from 'antd';
import { contentFaq, contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  texts: TabsProps['items'];
}

const config: EditorConfigW<EProps> = {
  title: 'Sekmeli İçerik Bloğu',
  description: 'İçerikleri kategorilere ayırarak düzenli bir şekilde sunan, sekme tabanlı bir içerik düzenleme bloğu.',
  type: 'tab-1',
  code: 'TAB1',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      texts: {
        type: 'array',
        title: 'Sekme',
        items: {
          type: 'object',
          required: ['label', 'children'],
          title: '',
          properties: {
            label: {
              type: 'string',
              title: 'Başlık',
            },
            children: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for description section in page: "${page.title}"`,
    );

    const cFaq = await contentFaq(
      project.projectId,
      page.pageId,
      `Write several title and description for description section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || 'Açıklamalar',
      description: cTitleDescription?.result?.description || 'Detaylar. ',
      texts: (cFaq.result || []).map((result, resultKey) => ({
        key: String(resultKey),
        label: result.title,
        children: result.description,
      })),
    };
  },
};

export default config;
