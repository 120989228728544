import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../component/Breadcrumb';
import { Layout, Menu } from 'antd';
import { SettingOutlined, GoogleOutlined, FontColorsOutlined, HomeOutlined, BarcodeOutlined, AreaChartOutlined } from '@ant-design/icons';
import WebsiteSettingGeneral from './module/WebsiteSettingGeneral';
import WebsiteSettingStyle from './module/WebsiteSettingStyle';
import WebsiteSettingHome from './module/WebsiteSettingHome';
import WebsiteSettingDomain from './module/WebsiteSettingDomain';
import WebsiteSettingAnalytic from './module/WebsiteSettingAnalytic';
import WebsiteSettingSeo from './module/WebsiteSettingSeo';
import { IProject } from '../../service/TypeGraphql';
import { loadProject } from '../../service/api/Project';
import { Helmet } from 'react-helmet';

export default function WebsiteSetting() {
  const location = useLocation();
  const params = useParams();
  const projectId = params.projectId;
  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState<IProject>();

  const loadProjectDetail = async () => {
    const projectResult = await loadProject(projectId as string);
    if (projectResult.result) {
      await setProject(projectResult.result);
    }
    await setLoading(false);
  };

  const load = async () => {
    await loadProjectDetail();
  };

  useEffect(() => {
    load();
  }, [projectId]);

  if (loading) {
    return <>yükleniyor</>;
  }

  const items = [
    {
      key: `/project/${projectId}/ayarlar`,
      path: `/`,
      label: <Link to={`/project/${projectId}/ayarlar`}>Genel Ayarlar</Link>,
      icon: <SettingOutlined />,
      element: <WebsiteSettingGeneral />,
    },
    {
      key: `/project/${projectId}/ayarlar/font-ve-renkler`,
      path: `/font-ve-renkler`,
      label: <Link to={`/project/${projectId}/ayarlar/font-ve-renkler`}>Font ve Renkler</Link>,
      icon: <FontColorsOutlined />,
      element: <WebsiteSettingStyle project={project as IProject} />,
    },
    {
      key: `/project/${projectId}/ayarlar/anasayfa`,
      path: `/anasayfa`,
      label: <Link to={`/project/${projectId}/ayarlar/anasayfa`}>Ana Sayfa</Link>,
      icon: <HomeOutlined />,
      element: <WebsiteSettingHome />,
    },
    {
      key: `/project/${projectId}/ayarlar/domain`,
      path: `/domain`,
      label: <Link to={`/project/${projectId}/ayarlar/domain`}>Domain Ayarları</Link>,
      icon: <BarcodeOutlined />,
      element: <WebsiteSettingDomain />,
    },
    {
      key: `/project/${projectId}/ayarlar/analitik`,
      path: `/analitik`,
      label: <Link to={`/project/${projectId}/ayarlar/analitik`}>Analitik</Link>,
      icon: <AreaChartOutlined />,
      element: <WebsiteSettingAnalytic />,
    },
    {
      key: `/project/${projectId}/ayarlar/arama-motoru-optimizasyonu`,
      path: `/arama-motoru-optimizasyonu`,
      label: <Link to={`/project/${projectId}/ayarlar/arama-motoru-optimizasyonu`}>Arama Motorları Optimizasyonu</Link>,
      icon: <GoogleOutlined />,
      element: <WebsiteSettingSeo />,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Ayarlar | {project?.title}</title>
      </Helmet>
      <div className="page">
        <Breadcrumb
          items={[
            {
              title: <Link to={`/project/1`}>Altın Kasap</Link>,
            },
            {
              title: 'Ayarlar',
            },
          ]}
        />

        <div className="page-title">
          <h1>Ayarlar</h1>
          <span style={{ flex: 1 }} />
        </div>

        <Layout>
          <Layout.Sider width={280} style={{ background: 'none', margin: '0 16px 0 0' }}>
            <Menu theme="light" mode="vertical" selectedKeys={[location.pathname]} items={items} style={{ flex: 1 }} />
          </Layout.Sider>
          <Layout.Content style={{ minHeight: 280 }}>
            <Routes>
              {items.map((item) => (
                <Route key={item.key} path={item.path} element={item.element} />
              ))}
            </Routes>
          </Layout.Content>
        </Layout>
      </div>
    </>
  );
}
