import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  team: {
    title: string;
    image?: string;
    name: string;
    urlLinkedin?: string;
    urlFacebook?: string;
    urlX?: string;
    urlInstagram?: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Takımımız',
  description: 'Şirket çalışanları veya takım üyelerinin profillerinin, görevlerinin ve kısa biyografilerinin yer aldığı tanıtıcı bölüm.',
  type: 'team-3',
  code: 'TM3',
  category: ['team'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'team'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      team: {
        type: 'array',
        title: 'Ekip Üyeleri',
        items: {
          type: 'object',
          required: ['title', 'image', 'name'],
          title: '',
          properties: {
            image: {
              type: 'string',
              title: 'Resim',
            },
            title: {
              type: 'string',
              title: 'Başlık',
            },
            name: {
              type: 'string',
              title: 'Ad Soyad',
            },
            urlLinkedin: {
              type: 'string',
              title: "Linkedin Link'i",
            },
            urlFacebook: {
              type: 'string',
              title: "Facebook Link'i",
            },
            urlX: {
              type: 'string',
              title: "Twitter Link'i",
            },
            urlInstagram: {
              type: 'string',
              title: "Instagram Link'i",
            },
          },
        },
      },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
    team: {
      items: {
        image: {
          'ui:widget': 'imageUpload',
          crop: true,
          width: 400,
          height: 600,
        },
      },
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const content = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for team section in page: "${page.title}"`,
    );

    return {
      title: content?.result?.title || 'Ekimibiz',
      description: content?.result?.description || 'Deneyimli ve Uzman Profesyoneller',
      team: [
        {
          title: 'Kurucu Ortak & CEO',
          image: '/img/staff/staff-1.jpg',
          name: 'John Doe',
          urlLinkedin: 'https://www.linkedin.com/',
        },
        {
          title: 'Kurucu Ortak & CTO',
          image: '/img/staff/staff-2.jpg',
          name: 'John Doe',
          urlLinkedin: 'https://www.linkedin.com/',
        },
        {
          title: 'Kurucu Ortak & CMO',
          image: '/img/staff/staff-3.webp',
          name: 'John Doe',
          urlLinkedin: 'https://www.linkedin.com/',
        },
        {
          title: 'Yazılım Geliştirici',
          image: '/img/staff/staff-4.webp',
          name: 'John Doe',
          urlLinkedin: 'https://www.linkedin.com/',
        },
        {
          title: 'UI & UX Designer',
          image: '/img/staff/staff-4.webp',
          name: 'John Doe',
          urlLinkedin: 'https://www.linkedin.com/',
        },
      ],
    };
  },
};

export default config;
