import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  youtubeUrl: string;
  height: number;
}

const config: EditorConfigW<EProps> = {
  title: 'Video İçerik Bloğu',
  description: 'Videoların gömüldüğü veya sergilendiği, kullanıcıların izleyebileceği multimedya içerik bölümü.',
  type: 'video-1',
  code: 'VDY1',
  category: ['video'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['youtubeUrl', 'height'],
    properties: {
      youtubeUrl: { type: 'string', title: 'YouTube Link', default: '' },
      height: { type: 'number', title: 'Video Yüksekliği', default: 500 },
    },
  },
  schemaUI: {},
  propsDefault: async () => {
    return {
      youtubeUrl: 'AE6vzgCHjn0',
      height: 500,
    };
  },
};

export default config;
