import React from 'react';
import './Browser.scss';

interface BrowserProps {
  link?: any;
  favicon?: string;
  title?: string;
  children?: any;
}

export default function Browser({ link, favicon, title, children }: BrowserProps) {
  return (
    <div className="browser" style={{ zoom: 0.75 }}>
      <div className="browser-bar">
        <div className="browser-button">
          <div />
          <div />
          <div />
        </div>
        <div className="browser-title">
          {favicon && <img src={favicon} alt="" />}
          {title}
          <span> </span>
          <div className="browser-title-close">ⓧ</div>
        </div>
      </div>
      <div className="browser-url">
        <div className="browser-link">🔒 {link}</div>
      </div>
      <div className="browser-content">{children}</div>
    </div>
  );
}
