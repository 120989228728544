import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import type {
  IError,
  IPage,
  IPageUpdateData,
  IPageCreateEmptyData,
  IPageCreateFromTemplateData,
  IPageListFilters,
  IStatus,
  IPageUpdateMetaData,
} from '../Type';

export const loadPageList = async (
  filter: IPageListFilters,
): Promise<{
  result: IPage[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query pages($options: PageListOptions) {
          pageList(options: $options) {
            pageId
            title
            path
            type
          }
        }
      `,
      variables: {
        options: {
          filter,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageList,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: [],
  };
};

export const loadPage = async (
  pageId: string,
): Promise<{
  result?: IPage;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query page($pageId: ID!) {
          page(pageId: $pageId) {
            pageId
            title
            path
            type
            pageBlocks {
              pageId
              blockId
              sort
              part
              block {
                blockId
                type
                style
                props
              }
            }
            seoTitle
            seoDescription
            seoImage
          }
        }
      `,
      variables: {
        pageId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.page,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageCreateFromTemplate = async (
  data: IPageCreateFromTemplateData,
): Promise<{
  result?: IPage;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageCreateFromTemplate($data: PageCreateFromTemplateData!) {
          pageCreateFromTemplate(data: $data) {
            pageId
          }
        }
      `,
      variables: {
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageCreateFromTemplate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageCreateEmpty = async (
  data: IPageCreateEmptyData,
): Promise<{
  result?: IPage;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageCreateEmpty($data: PageCreateEmptyData!) {
          pageCreateEmpty(data: $data) {
            pageId
          }
        }
      `,
      variables: {
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageCreateEmpty,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageUpdate = async (
  pageId: string,
  data: IPageUpdateData,
): Promise<{
  result?: IPage;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageUpdate($pageId: ID!, $data: PageUpdateData!) {
          pageUpdate(pageId: $pageId, data: $data) {
            pageId
            title
          }
        }
      `,
      variables: {
        pageId,
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageUpdate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageUpdateMeta = async (
  pageId: string,
  data: IPageUpdateMetaData,
): Promise<{
  result?: IPage;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageUpdateMeta($pageId: ID!, $data: PageUpdateMetaData!) {
          pageUpdateMeta(pageId: $pageId, data: $data) {
            pageId
            title
          }
        }
      `,
      variables: {
        pageId,
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageUpdateMeta,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageActivate = async (
  pageId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageActivate($pageId: ID!) {
          pageActivate(pageId: $pageId) {
            message
            status
          }
        }
      `,
      variables: {
        pageId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageActivate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageDeactivate = async (
  pageId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageDeactivate($pageId: ID!) {
          pageDeactivate(pageId: $pageId) {
            message
            status
          }
        }
      `,
      variables: {
        pageId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageDeactivate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageDelete = async (
  pageId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageDelete($pageId: ID!) {
          pageDelete(pageId: $pageId) {
            message
            status
          }
        }
      `,
      variables: {
        pageId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageDelete,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const pageActivateAll = async (
  projectId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation pageActivateAll($projectId: ID!) {
          pageActivateAll(projectId: $projectId) {
            message
            status
          }
        }
      `,
      variables: {
        projectId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.pageActivateAll,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
