import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  title: string;
  description: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Etkinlik Takvimi',
  description: 'Etkinlikler, toplantılar veya önemli tarihlerin gösterildiği dinamik bir takvim bloğu.',
  type: 'calendar-1',
  code: 'CLDR1',
  category: ['calendar'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
    },
  },
  schemaUI: {
    tabs: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      title: 'Takvim',
      description: 'Takvim Açıklaması',
    };
  },
};

export default config;
