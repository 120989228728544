import React from 'react';
import { Button, Checkbox, Col, Row, Space } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SET_BUILDER_PAGE } from '../service/actionTypes';
import { IProjectCreateData } from '../../../service/TypeGraphql';
import './BuilderContent.scss';

interface BuilderContentProps {
  state: IProjectCreateData;
  dispatch: any;
  onPrevious: any;
  onComplete: any;
  setPercent: any;
}

export default function BuilderContent({ state, dispatch, onPrevious, onComplete, setPercent }: BuilderContentProps) {
  const options = [
    { value: 'about', label: 'Hakkımızda' },
    { value: 'contact', label: 'İletişim' },
    { value: 'why-us', label: 'Neden Biz?' },
    { value: 'product-list', label: 'Ürünlerimiz' },
    { value: 'service-list', label: 'Hizmetlerimiz' },
    { value: 'help', label: 'Yardım Merkezi' },
    { value: 'blog', label: 'Blog' },
    { value: 'policy-privacy', label: 'Gizlilik Sözleşmesi' },
    { value: 'policy-term-of-use', label: 'Kullanım Sözleşmesi' },
    { value: 'policy-cookie', label: 'Çerez Politikası' },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);

    dispatch({
      type: SET_BUILDER_PAGE,
      payload: { page: checkedValues },
    });

    setPercent(checkedValues.length > 0 ? 100 : 0);
  };

  return (
    <div
      className="builder-content"
      style={{
        height: 'calc(100vh - 143px)',
      }}
    >
      <h2>Websitenizde Hangi Sayfalar Olmalı?</h2>
      <Checkbox.Group
        value={state.page as CheckboxValueType[]}
        onChange={onChange}
        style={{ width: '100%', display: 'block', marginBottom: 16 }}
      >
        {options.map((option) => (
          <Row key={option.value} style={{ width: '100%' }}>
            <Col>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </Col>
          </Row>
        ))}
      </Checkbox.Group>

      <Space>
        <Button onClick={() => onPrevious()}>Geri</Button>
        <Button type="primary" onClick={() => onComplete()}>
          Devam Et
        </Button>
      </Space>
    </div>
  );
}
