import React from 'react';
import { Card, Drawer } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { EditorConfig, EditorProps, EditorStyle, IPage, IPageBlock, IProject } from '../../service/Type';
import { blockList } from './component/Data';
import ComponentStyle from './component-shared/ComponentStyle';
import './EditorBlockSwitch.scss';

interface EditorBlockSwitchProps {
  open: boolean;
  onCancel: any;
  onUpdate: (type: string, props: EditorProps, style: EditorStyle) => void;
  pageBlocks: IPageBlock[];
  blockId: string;
  project: IProject;
  page: IPage;
}

export default function EditorBlockSwitch({ open, onCancel, onUpdate, blockId, pageBlocks, page, project }: EditorBlockSwitchProps) {
  const pageBlockToEdit = pageBlocks.find((pageBlock) => pageBlock.blockId === blockId) as IPageBlock;

  const blockConfig = blockList.find((block) => block.config.type === pageBlockToEdit.block.type)?.config as EditorConfig;

  const blockListFiltered = blockList.filter((block) => block.config.category.some((cat) => blockConfig.category.includes(cat)));

  return (
    <Drawer title="Blok Değiştir" open={open} onClose={onCancel} width="80%" placement="left">
      <div className="block-switch">
        {blockListFiltered.map((block) => (
          <Card
            className="template-wrapper"
            size="small"
            key={block.config.type}
            title={block.config.title}
            onClick={() => {
              onUpdate(
                block.config.type,
                {
                  ...block.config.propsDefault,
                  ...pageBlockToEdit.block.props,
                },
                {
                  ...pageBlockToEdit.block.style,
                  ...block.config.styleDefault,
                },
              );
            }}
          >
            <div className="template">
              <React.Suspense fallback={'Yükleniyor...'}>
                <ComponentStyle style={block.config.styleDefault}>
                  <block.viewer
                    blockId={pageBlockToEdit.blockId}
                    project={project}
                    page={page}
                    style={{
                      ...block.config.styleDefault,
                      ...pageBlockToEdit.block.style,
                    }}
                    props={{
                      ...pageBlockToEdit.block.props,
                    }}
                  />
                </ComponentStyle>
              </React.Suspense>
            </div>
            <div className="template-select">
              <SelectOutlined style={{ fontSize: '64px' }} />
            </div>
          </Card>
        ))}
      </div>
    </Drawer>
  );
}
