import React from 'react';
import { Result } from 'antd';

export default function NotFound() {
  return (
    <>
      <div className="page">
        <Result status={404} title="Sayfa Bulunamadı" />
      </div>
    </>
  );
}
