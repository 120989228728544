import React, { useEffect } from 'react';
import { Button, ColorPicker, Space, message, Upload, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { SET_BUILDER, SET_BUILDER_COLOR, SET_BUILDER_FONT, SET_BUILDER_FONT_SIZE } from '../service/actionTypes';
import { IProjectCreateData } from '../../../service/TypeGraphql';
import { fontList } from '../../../service/Font';
// import { extractColors } from 'extract-colors';
import Preview from './Preview';
import './BuilderDesign.scss';

interface BuilderDesignProps {
  state: IProjectCreateData;
  dispatch: any;
  onPrevious: any;
  onComplete: any;
  setPercent: any;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export default function BuilderDesign({ state, dispatch, onPrevious, onComplete, setPercent }: BuilderDesignProps) {
  useEffect(() => {
    let count = 0;
    count += state.logo ? 1 : 0;
    count += state.favicon ? 1 : 0;
    count += state.fontFamily ? 1 : 0;

    setPercent((count / 3) * 100);
  }, [state.logo, state.favicon, state.fontFamily]);

  const propsLogo: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    showUploadList: false,
    customRequest: (info) => {
      console.log(info);
    },
    beforeUpload: (file) => {
      console.log(file);

      getBase64(file as RcFile, (img) => {
        dispatch({
          type: SET_BUILDER,
          payload: { logo: img },
        });
      });

      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsLogoDark: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    showUploadList: false,
    customRequest: (info) => {
      console.log(info);
    },
    beforeUpload: (file) => {
      console.log(file);

      getBase64(file as RcFile, (img) => {
        dispatch({
          type: SET_BUILDER,
          payload: { logoDark: img },
        });
      });

      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsFavicon: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    showUploadList: false,
    customRequest: (info) => {
      console.log(info);
    },
    beforeUpload: (file) => {
      console.log(file);

      getBase64(file as RcFile, (img) => {
        dispatch({
          type: SET_BUILDER,
          payload: { favicon: img },
        });
      });

      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const isValid = () => {
    return !!state.logo && !!state.favicon;
  };

  return (
    <div className="builder">
      <div className="builder-design">
        <h2>Logonuzu yükleyin</h2>
        <div className="builder-design-color">
          <Upload {...propsLogo}>
            <Button icon={<UploadOutlined />} />
          </Upload>
          <img src={state.logo || '/img/logo-large.png'} alt="" />
        </div>

        <h2>Karanlık Mod Logonuzu yükleyin</h2>
        <div className="builder-design-color">
          <Upload {...propsLogoDark}>
            <Button icon={<UploadOutlined />} />
          </Upload>
          <img src={state.logoDark || '/img/logo-large.png'} alt="" />
        </div>

        <h2>Favicon yükleyin</h2>
        <div className="builder-design-color">
          <Upload {...propsFavicon}>
            <Button icon={<UploadOutlined />} />
          </Upload>
          <img src={state.favicon || '/img/logo.png'} alt="" />
        </div>

        <h2>Font büyüklüğü</h2>
        <Select
          value={state.fontSize}
          style={{ width: '100%', marginBottom: 16 }}
          onChange={(fontSize) => {
            dispatch({
              type: SET_BUILDER_FONT_SIZE,
              payload: { fontSize },
            });
          }}
          options={[
            {
              label: '20 (Çok Büyük)',
              value: 20,
            },
            {
              label: '18 (Büyük)',
              value: 18,
            },
            {
              label: '16 (Normal)',
              value: 16,
            },
            {
              label: '14 (Küçük)',
              value: 14,
            },
            {
              label: '12 (Çok Küçük)',
              value: 12,
            },
          ]}
        />

        <h2>Font seçin</h2>
        <Select
          value={state.fontFamily}
          style={{ width: '100%', marginBottom: 16 }}
          onChange={(fontFamily) => {
            dispatch({
              type: SET_BUILDER_FONT,
              payload: { fontFamily },
            });
          }}
          options={fontList.map((font) => ({
            label: font.title,
            value: font.fontFamily,
          }))}
        />

        {fontList.find((f) => f.fontFamily === state.fontFamily)?.url && (
          <link rel="stylesheet" href={fontList.find((f) => f.fontFamily === state.fontFamily)?.url} />
        )}

        <h2>Renkleri seçin</h2>
        <p className="mb-2">Ana Renk:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorPrimary}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorPrimary: value.toHex() },
              })
            }
          />
        </div>
        <p className="mb-2">Yazı Rengi:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorText}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorText: value.toHex() },
              })
            }
          />
        </div>
        <p className="mb-2">Arka Plan Rengi:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorBackground}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorBackground: value.toHex() },
              })
            }
          />
        </div>
        <p className="mb-2">Başarı Rengi:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorSuccess}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorSuccess: value.toHex() },
              })
            }
          />
        </div>
        <p className="mb-2">Hata Rengi:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorError}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorError: value.toHex() },
              })
            }
          />
        </div>
        <p className="mb-2">Uyarı Rengi:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorWarning}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorWarning: value.toHex() },
              })
            }
          />
        </div>
        <p className="mb-2">Bilgi Rengi:</p>
        <div className="mb-4">
          <ColorPicker
            value={state.colorInfo}
            showText
            onChange={(value) =>
              dispatch({
                type: SET_BUILDER_COLOR,
                payload: { colorInfo: value.toHex() },
              })
            }
          />
        </div>

        <Space>
          <Button onClick={() => onPrevious()}>Geri</Button>
          <Button type="primary" onClick={() => onComplete()} disabled={!isValid()}>
            Devam Et
          </Button>
        </Space>
      </div>
      <div className="builder-preview">
        <Preview state={state} />
      </div>
    </div>
  );
}
