import { EditorConfigW, IProject } from '../../../../service/Type';
import { sliderBySection } from '../../../../service/api/ImageStock';

interface Slide {
  type: 'image' | 'video' | 'youtube';
  src?: string;
  url?: string;
}

export interface EProps {
  height: number;
  slides: Slide[];
}

const config: EditorConfigW<EProps> = {
  title: 'Görsel Slayt Gösterisi',
  description: 'Görsellerin veya içeriklerin otomatik veya kullanıcı etkileşimiyle değiştiği, dinamik bir görsel slayt gösterisi.',
  type: 'slider-1',
  code: 'SLDR1',
  category: ['slider'],
  suitable: ['main'],
  schema: {
    title: '',
    type: 'object',
    required: ['wrapper', 'height', 'slides'],
    properties: {
      height: {
        type: 'number',
        title: 'Slider Yüksekliği',
        default: 500,
        maximum: 1000,
        minimum: 300,
      },
      slides: {
        type: 'array',
        title: 'Slidelar',
        items: {
          properties: {
            type: {
              type: 'string',
              title: 'Slide Tipi',
              oneOf: [
                { title: 'Görsel', const: 'image' },
                { title: 'Youtube', const: 'youtube' },
              ],
            },
          },
          dependencies: {
            type: {
              oneOf: [
                {
                  properties: {
                    type: { enum: ['image'] },
                    src: { type: 'string', title: 'Görsel' },
                    additionalProperties: false,
                  },
                },
                {
                  properties: {
                    type: { enum: ['video', 'youtube'] },
                    url: { type: 'string', title: 'Youtube ID' },
                    additionalProperties: false,
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
  schemaUI: {
    height: {
      'ui:widget': 'range',
      'ui:description': 'Minimum: 300, Maksimum: 1000',
    },
    slides: {
      items: {
        'ui:title': 'Slide',
        src: {
          'ui:widget': 'imageUpload',
        },
        url: {
          'ui:widget': 'text',
        },
        type: {
          'ui:widget': 'select',
        },
      },
    },
  },
  propsDefault: async (project: IProject) => {
    const slides = await sliderBySection(project.projectId, project.type);
    console.log(slides);

    return {
      slides: slides.result
        ? slides.result.map((slide) => ({
            type: 'image',
            src: slide.urls?.raw,
          }))
        : [],
      height: 500,
      wrapper: 'block',
    };
  },
};

export default config;
