import { DescriptionsProps } from 'antd';
import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentFaq, contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  texts: DescriptionsProps['items'];
}

const config: EditorConfigW<EProps> = {
  title: 'Kavram ve Tanımlar',
  description: 'Terimler, kavramlar veya ürünler hakkında detaylı bilgilerin sunulduğu açıklama bölümü.',
  type: 'description-1',
  code: 'DSC1',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      texts: {
        type: 'array',
        title: 'Tanımlar',
        items: {
          type: 'object',
          required: ['label', 'children'],
          title: '',
          properties: {
            label: {
              type: 'string',
              title: 'Başlık',
            },
            children: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for description section in page: "${page.title}"`,
    );

    const cFaq = await contentFaq(
      project.projectId,
      page.pageId,
      `Write 10 title and description for description section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || 'Açıklamalar',
      description: cTitleDescription?.result?.description || 'Detaylar. ',
      texts: (cFaq.result || []).map((result) => ({
        label: result.title,
        children: result.description,
      })),
    };
  },

  // propsDefault: async () => {
  //   return {
  //     title: 'Tanımlamalar',
  //     description: 'Tanımlamalar',
  //     texts: [
  //       {
  //         key: '1',
  //         label: 'Product',
  //         children: 'Cloud Database',
  //       },
  //       {
  //         key: '2',
  //         label: 'Billing Mode',
  //         children: 'Prepaid',
  //       },
  //       {
  //         key: '3',
  //         label: 'Automatic Renewal',
  //         children: 'YES',
  //       },
  //     ],
  //   };
  // },
};

export default config;
