export const builderCategory = ['Servis ve Tamir Site Şablonları'];

export const builderTypes = [
  {
    value: 'Avukat Bürosu',
  },
  {
    value: 'Avukatlar İçin Websitesi',
  },
  {
    value: 'Kasap',
  },
  {
    value: 'Ev Yenileme Şirketi',
  },
  {
    value: 'Temizlik Şirketi',
  },
  {
    value: 'İnşaat Şirketi',
  },
  {
    value: 'Sanal Asistan',
  },
  {
    value: 'Döşeme Şirketi',
  },
  {
    value: 'Marangoz',
  },
  {
    value: 'Güneş Paneli Kurulumu',
  },
  {
    value: 'Geri Dönüşüm Tesisi',
  },
  {
    value: 'Peyzaj Hizmetleri',
  },
  {
    value: 'Nakliyat Şirketi',
  },
  {
    value: 'Çilingir',
  },
  {
    value: 'HVAC Teknisyeni',
  },
  {
    value: 'Bisiklet Tamirhanesi',
  },
  {
    value: 'Ev Geliştirme Şirketi',
  },
  {
    value: 'Boya Badana Şirketi',
  },
  {
    value: 'Mekanik Tamir',
  },
  {
    value: 'Tesisatçılık Şirketi',
  },
  {
    value: 'Otomobil Tamir Servisi',
  },
  {
    value: 'Kuru Temizleme Dükkanı',
  },
  {
    value: 'Döşemeci',
  },
  {
    value: 'Oto Yıkama',
  },
  {
    value: 'Özel Ders Sitesi',
  },
  {
    value: 'Otomobil Parçaları Mağazası',
  },
  {
    value: 'Yayıncılık Şirketi',
  },
];
