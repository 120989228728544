import { IAction } from '../../Type';
import { LOAD_INITIAL, ON_BOARD_MODULE } from '../actionTypes';
import { IProfile } from '../../Type';

type IProfileReducer = {
  profile: IProfile;
};

const initialState: IProfileReducer = {
  // @ts-ignore
  profile: {},
};

export default function reducer(state = initialState, { type, payload }: IAction) {
  switch (type) {
    case LOAD_INITIAL:
      return {
        ...state,
        profile: payload.profile as IProfile,
      };
    case ON_BOARD_MODULE:
      return {
        ...state,
        profile: {
          ...state.profile,
          isOnBoardedModule: [...state.profile.isOnBoardedModule, payload.module],
        },
      };
    default:
      return state;
  }
}
