import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  title: string;
  description: string;
  link: string;
  text: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Sayfa Bulunamadı',
  description:
    'Kullanıcıların yanlış bir URL girerse ya da mevcut olmayan bir sayfaya ulaşmaya çalışırken karşılaştığı hata mesajı sayfası.',
  type: 'error-404-4',
  code: 'E4044',
  category: ['error'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'description'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      link: { type: 'string', title: 'Link', default: '' },
      text: { type: 'string', title: 'Buton Text', default: '' },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      title: 'Aradığınız Sayfa Bulunamadı',
      description:
        'Üzgünüz, aradığınız sayfayı bulamadık. Lütfen adresi kontrol edin veya siteye dönerek başka bir şey arayın. Anlayışınız için teşekkür ederiz.',
      link: '/',
      text: "Ana Sayfa'ya Geri Dön",
    };
  },
};

export default config;
