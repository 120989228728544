import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

type Theme = 'light' | 'dark';

interface ThemeContextType {
  theme: Theme;
  themeSet: (e: Theme) => void;
  themeToggle: () => void;
  projectId: string;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};

interface ThemeProviderProps {
  projectId: string;
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, projectId }) => {
  let isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  if (localStorage.getItem('theme')) {
    isDark = localStorage.getItem('theme') === 'dark';
  }

  const [theme, setTheme] = useState<Theme>(isDark ? 'dark' : 'light');

  const themeToggle = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const themeSet = (e: Theme) => setTheme(e);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, themeToggle, themeSet, projectId }}>{children}</ThemeContext.Provider>;
};
