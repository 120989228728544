import { EditorConfigW } from '../../../../service/Type';

interface Stat {
  icon: string;
  text: string;
  value: string;
}

export interface EProps {
  title: string;
  description: string;
  stats: Stat[];
}

const config: EditorConfigW<EProps> = {
  title: 'Başarı İstatistikleri',
  description: 'Şirketin başarıları, kullanıcı sayısı gibi önemli verilerin ve istatistiklerin grafiklerle sunulduğu bilgilendirici bölüm.',
  type: 'statistic-3',
  code: 'STAT-3',
  category: ['statistic'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      stats: {
        type: 'array',
        title: 'İstatistikler',
        items: {
          type: 'object',
          required: ['text', 'value'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            value: {
              type: 'string',
              title: 'Değer',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  propsDefault: async (project) => {
    return {
      title: 'Başlık',
      description: 'İndirim',
      stats: [
        {
          icon: `
             <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12 mb-3 inline-block"
                viewBox="0 0 24 24"
                style="color: ${project.colorPrimary}"
              >
                <path d="M8 17l4 4 4-4m-4-5v9" />
                <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29" />
              </svg>
          `,
          text: 'İndirmeler',
          value: '2.7K',
        },
        {
          icon: `
             <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12 mb-3 inline-block"
                viewBox="0 0 24 24"
                style="color: ${project.colorPrimary}"
              >
                <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75" />
              </svg>
          `,
          text: 'Kullanıcılar',
          value: '22.7K',
        },
        {
          icon: `
             <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12 mb-3 inline-block"
                viewBox="0 0 24 24"
                style="color: ${project.colorPrimary}"
              >
                <path d="M3 18v-6a9 9 0 0118 0v6" />
                <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z" />
              </svg>
          `,
          text: 'Dosyalar',
          value: '74',
        },
        {
          icon: `
             <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-12 h-12 mb-3 inline-block"
                viewBox="0 0 24 24"
                style="color: ${project.colorPrimary}"
              >
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
              </svg>
          `,
          text: 'Yerler',
          value: '221.7K',
        },
      ],
    };
  },
};

export default config;
