import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentPage } from '../../../../service/api/Content';

export interface EProps {
  content: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Genel İçerik Bloğu',
  description: 'Genel içerik sunumları için kullanılabilen, metin ve görselleri bir araya getiren içerik bloğu.\n',
  type: 'content-1',
  code: 'CTN1',
  category: ['content'],
  suitable: ['main'],
  propsDefault: async (project: IProject, page: IPage) => {
    const cPage = await contentPage(project.projectId, page.pageId, `Write page content in page: "${page.title}"`);

    return {
      content: cPage?.result?.content || '',
    };
  },
};

export default config;
