import React from 'react';
import { Alert, Col, ConfigProvider, Row } from 'antd';
import trTr from 'antd/locale/tr_TR';
import { IProjectCreateData } from '../../../service/TypeGraphql';
import Header from '../../../component/editor/component/header-5/viewer';
import Title from '../../../component/editor/component/title-2/viewer';
import Browser from '../../../component/Browser';
import './Preview.scss';

interface PreviewProps {
  state: IProjectCreateData;
}

export default function Preview({ state }: PreviewProps) {
  return (
    <Browser title={state.title} link={`http://${state.link}.${process.env.REACT_APP_RENDER_BASE}`}>
      {/*
      <pre>{JSON.stringify(state, null, 2)}</pre>
      */}
      <div
        style={{
          fontFamily: state.fontFamily,
          color: `#${state.colorText}`,
          backgroundColor: `#${state.colorBackground}`,
        }}
      >
        <ConfigProvider
          locale={trTr}
          theme={{
            token: {
              colorPrimary: state.colorPrimary,
              colorSuccess: state.colorSuccess,
              colorWarning: state.colorWarning,
              colorError: state.colorError,
              colorInfo: state.colorInfo,
              colorTextBase: state.colorText,
              colorBgBase: state.colorBackground,
              colorLink: state.colorPrimary,
              fontFamily: state.fontFamily,
            },
          }}
        >
          <Header
            project={state as any}
            page={{ ...state, path: '/' } as any}
            props={{
              logoHeight: 80,
              title: 'Test',
              description: 'Test',
              image: '/img/header/hero-image.jpg',
              links: [
                {
                  link: '#',
                  text: 'Ana Sayfa',
                },
                {
                  link: '#',
                  text: 'Hakkımızda',
                },
                {
                  link: '#',
                  text: 'İletişim',
                },
              ],
              buttonsHeader: [
                {
                  link: '/hakkimizda',
                  text: 'Hakkımızda',
                  icon: '',
                  type: 'transparent',
                },
                {
                  link: '/iletisim',
                  text: 'İletişim',
                  icon: '',
                  type: 'raised',
                },
              ],
              buttonsHero: [
                {
                  link: '/hakkimizda',
                  text: 'Hakkımızda',
                  icon: 'about',
                  type: 'transparent',
                },
                {
                  link: '/iletisim',
                  text: 'İletişim',
                  icon: 'contact',
                  type: 'raised',
                },
              ],
            }}
          />
          <Title
            project={state as any}
            page={state as any}
            props={{
              title: 'Yapay Zeka ile Güzel Websiteleri Oluşturun!',
              description: 'Kod yazmaya gerek yok!',
              link: '',
              text: 'Keşfedin!',
            }}
          />

          <div style={{ padding: 16 }}>
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <Alert type="error" showIcon description="Bu bir hata mesajıdır!" />
              </Col>
              <Col span={12}>
                <Alert type="success" showIcon description="Bu bir başarı mesajıdır!" />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Alert type="warning" showIcon description="Bu bir uyarı mesajıdır!" />
              </Col>
              <Col span={12}>
                <Alert type="info" showIcon description="Bu bir bilgi mesajıdır!" />
              </Col>
            </Row>
          </div>
        </ConfigProvider>
      </div>
    </Browser>
  );
}
