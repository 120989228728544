import { IAction, IProjectCreateData } from '../../../service/Type';
import { createSlug } from '../../../service/util/Slug';
import {
  SET_BUILDER,
  SET_BUILDER_TYPE,
  SET_BUILDER_LANGUAGE,
  SET_BUILDER_TITLE,
  SET_BUILDER_SLOGAN,
  SET_BUILDER_TARGET,
  SET_BUILDER_PURPOSE,
  SET_BUILDER_PAGE,
  SET_BUILDER_EMAIL,
  SET_BUILDER_PHONE,
  SET_BUILDER_ADDRESS,
  SET_BUILDER_COLOR,
  SET_BUILDER_FONT,
  SET_BUILDER_SOCIAL_MEDIA, SET_BUILDER_FONT_SIZE
} from "./actionTypes";

export default function reducer(state: IProjectCreateData, { type, payload }: IAction) {
  switch (type) {
    case SET_BUILDER_TYPE:
      return {
        ...state,
        type: payload.type,
      };
    case SET_BUILDER_TITLE:
      return {
        ...state,
        title: payload.title,
        link: createSlug(payload.title),
      };
    case SET_BUILDER_PURPOSE:
      return {
        ...state,
        purpose: payload.purpose,
      };
    case SET_BUILDER_PAGE:
      return {
        ...state,
        page: payload.page,
      };
    case SET_BUILDER_TARGET:
      return {
        ...state,
        target: payload.target,
      };
    case SET_BUILDER_SLOGAN:
      return {
        ...state,
        slogan: payload.slogan,
      };
    case SET_BUILDER_FONT:
      return {
        ...state,
        fontFamily: payload.fontFamily,
      };
    case SET_BUILDER_FONT_SIZE:
      return {
        ...state,
        fontSize: payload.fontSize,
      };
    case SET_BUILDER_LANGUAGE:
      return {
        ...state,
        language: payload.language,
      };
    case SET_BUILDER:
    case SET_BUILDER_EMAIL:
    case SET_BUILDER_PHONE:
    case SET_BUILDER_ADDRESS:
    case SET_BUILDER_COLOR:
      return {
        ...state,
        ...payload,
      };
    case SET_BUILDER_SOCIAL_MEDIA:
      return {
        ...state,
        socialMedia: {
          ...state.socialMedia,
          ...payload,
        },
      };
    default:
      return state;
  }
}
