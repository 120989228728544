import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  logo: {
    title: string;
    image?: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Partner / Referans Logo Bulutu',
  description:
    'Logo Cloud, iş ortaklarımızın çeşitliliğini ve sektördeki geniş kapsamımızı yansıtarak, potansiyel müşterilere ve iş ortaklarına güven verir.',
  type: 'logo-cloud-1',
  code: 'LC1',
  category: ['logo-cloud'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'logo'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      logo: {
        type: 'array',
        title: "Logo'lar",
        items: {
          type: 'object',
          required: ['title', 'image'],
          title: '',
          properties: {
            image: {
              type: 'string',
              title: 'Resim',
            },
            title: {
              type: 'string',
              title: 'Başlık',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
    logo: {
      items: {
        image: {
          'ui:widget': 'imageUpload',
          crop: false,
        },
      },
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const content = await contentTitleDescription(project.projectId, page.pageId, `Write title and description for logo cloud section`);

    return {
      title: content?.result?.title || 'Ekimibiz',
      description: content?.result?.description || 'Deneyimli ve Uzman Profesyoneller',
      logo: [
        {
          title: 'Transistor',
          image: 'https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg',
        },
        {
          title: 'Reform',
          image: 'https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg',
        },
        {
          title: 'Tuple',
          image: 'https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg',
        },
        {
          title: 'SavvyCal',
          image: 'https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg',
        },
        {
          title: 'Statamic',
          image: 'https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg',
        },
      ],
    };
  },
};

export default config;
