import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Card, Modal, notification, Tooltip } from 'antd';
import type { IError, IContentPost, IProject } from '../../service/Type';
import { loadContentPostList, contentPostDelete } from '../../service/api/ContentPost';
import ImageScroll from '../../component/ImageScroll';
import PostEdit from './component/PostEdit';
import PostCreate from './component/PostCreate';
import { ThemeProvider } from '../../component/editor/ThemeProvider';
import './WebsitePost.scss';

interface WebsitePostProps {
  project: IProject;
}

export default function WebsitePost({ project }: WebsitePostProps) {
  const params = useParams();
  const { projectId } = params;

  const [contentPostId, setContentPostId] = useState<string>();
  const [contentCreate, setContentCreate] = useState<boolean>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [contentPostList, setPostList] = useState<IContentPost[]>([]);

  const load = async () => {
    const contentPostListResult = await loadContentPostList({
      filter: {
        projectId: projectId,
      },
    });
    if (contentPostListResult.result) {
      await setPostList(contentPostListResult.result);
    }
    if (contentPostListResult.errors) {
      await setError(contentPostListResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, [projectId]);

  if (loading) {
    return <div className="post">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="post">Hata</div>;
  }

  if (!project) {
    return <div className="post">Hata</div>;
  }

  return (
    <ThemeProvider projectId={projectId as string}>
      <Button onClick={() => setContentCreate(true)} type="primary" style={{ marginBottom: 16 }}>
        Yeni Yazı Oluştur
      </Button>
      <div className="post-list">
        {contentPostList?.map((post) => (
          <Card
            key={post.contentPostId}
            actions={[
              <Tooltip key="edit" title="Yazıları Düzenle">
                <div onClick={() => setContentPostId(post.contentPostId)}>
                  <EditOutlined />
                </div>
              </Tooltip>,
              <Tooltip key="edit" title="Yazıları Görüntüle">
                <a href={`${project.linkSchema}${project.link}/blog/${post.slug}`} target="_blank" rel="noreferrer">
                  <LinkOutlined />
                </a>
              </Tooltip>,
              <Tooltip key="delete" title="Yazıları Sil">
                <a
                  onClick={() => {
                    Modal.confirm({
                      title: 'Yazıları silmek istediğinizden emin misiniz?',
                      content: 'Yazı silindiğinde geri alınamaz.',
                      async onOk() {
                        const contentPostResult = await contentPostDelete(post.contentPostId);
                        if (contentPostResult.result) {
                          notification.success({
                            message: 'Başarı',
                            description: 'Yazı başarıyla silindi! ',
                          });
                          await load();
                        }
                      },
                    });
                  }}
                >
                  <DeleteOutlined type="link" />
                </a>
              </Tooltip>,
            ]}
            cover={<ImageScroll src={post.seoImage || ''} />}
          >
            <div>
              <h2 style={{ margin: '0 0 16px ' }}>{post.title}</h2>
              <p>{post.seoTitle}</p>
              <p>{post.seoDescription}</p>
            </div>
          </Card>
        ))}
      </div>
      {contentPostId && (
        <PostEdit
          contentPostId={contentPostId}
          onCancel={() => setContentPostId(undefined)}
          onUpdate={async () => {
            await load();
            setContentPostId(undefined);
          }}
        />
      )}
      {contentCreate && (
        <PostCreate
          projectId={project.projectId}
          onCancel={() => setContentCreate(undefined)}
          onUpdate={async () => {
            await load();
            setContentCreate(undefined);
          }}
        />
      )}
    </ThemeProvider>
  );
}
