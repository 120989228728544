import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Modal, Radio } from 'antd';
import Meta from 'antd/es/card/Meta';
import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import Breadcrumb from '../../component/Breadcrumb';
import ImageScroll from '../../component/ImageScroll';
import Browser from '../../component/Browser';
import { WebsiteSetting } from './component/WebsiteSetting';
import type { IError, ITemplate, IProject } from '../../service/Type';
import { loadTemplateList } from '../../service/api/Template';
import { loadProject } from '../../service/api/Project';
import { pageCreateEmpty, pageCreateFromTemplate } from '../../service/api/Page';
import './WebsitePageCreate.scss';
import { Helmet } from 'react-helmet';

export default function WebsitePageCreate() {
  const navigate = useNavigate();
  const params = useParams();
  const { projectId } = params;

  const [templateSelected, setTemplateSelected] = useState<ITemplate>();
  const [templatePreview, setTemplatePreview] = useState<ITemplate>();
  const [templateList, setTemplateList] = useState<ITemplate[]>([]);

  const [project, setProject] = useState<IProject>();
  const [loading, setLoading] = useState(true);

  const [option, setOption] = useState<string>('0');
  const [error, setError] = useState<IError>();

  const loadProjectDetail = async () => {
    const projectResult = await loadProject(projectId as string);
    if (projectResult.result) {
      await setProject(projectResult.result);
    }
    if (projectResult.errors) {
      await setError(projectResult.errors);
    }
  };

  const load = async () => {
    const templateResult = await loadTemplateList({});
    if (templateResult.result) {
      await setTemplateList(templateResult.result);
    }
    if (templateResult.errors) {
      await setError(templateResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
    loadProjectDetail();
  }, [projectId]);

  const handleOptionChange = (filter: string) => {
    setOption(filter);
  };

  const handleSelected = (template: ITemplate) => {
    setTemplateSelected(template);
  };

  const handleSelectedCancel = () => {
    setTemplateSelected(undefined);
  };

  const handlePreview = (template: ITemplate) => {
    setTemplatePreview(template);
  };

  const handlePreviewCancel = () => {
    setTemplatePreview(undefined);
  };

  const handleCreate = async (title: string, path: string, seoTitle: string, seoDescription: string, templateId?: string) => {
    let pageCreateResult;
    if (templateId) {
      pageCreateResult = await pageCreateFromTemplate({
        templateId,
        projectId: projectId as string,
        title,
        path,
        seoTitle,
        seoDescription,
      });
    } else {
      pageCreateResult = await pageCreateEmpty({
        projectId: projectId as string,
        title,
        path,
        seoTitle,
        seoDescription,
      });
    }

    navigate(`/project/${projectId}/sayfa/${pageCreateResult.result?.pageId}/duzenle`);
  };

  const optionList = [
    {
      type: '0',
      title: 'Tümü',
    },
    {
      type: '1',
      title: 'Kurumsal',
    },
    {
      type: '2',
      title: 'Kişisel',
    },
    {
      type: '3',
      title: 'Etkinlik',
    },
    {
      type: '4',
      title: 'Blog Sayfası',
    },
    {
      type: '5',
      title: 'Form',
    },
    {
      type: '6',
      title: 'İç Sayfa',
    },
  ];

  let templateListFiltered = templateList;
  if (option !== '0') {
    templateListFiltered = templateList.filter((template) => template.type === option);
  }

  if (loading) {
    return <div className="page">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="page">Hata</div>;
  }

  return (
    <>
      <Helmet>
        <title>Sayfa Oluştur | {project?.title}</title>
      </Helmet>
      <div className="page">
        <Breadcrumb
          items={[
            {
              title: <Link to={`/project/${projectId}`}>{project?.title}</Link>,
            },
            {
              title: 'Sayfa Oluştur',
            },
          ]}
        />

        <div className="page-title">
          <h1>Sayfa Oluştur</h1>
        </div>

        <p
          style={{
            margin: '0 0 16px 0',
          }}
        >
          Uygun bir şablon seçin ve proje gereksinimlerinize uyacak şekilde istediğiniz şekilde özelleştirin.
        </p>

        <Radio.Group
          onChange={(e) => handleOptionChange(e.target.value)}
          value={option}
          style={{ marginBottom: '16px' }}
          className="option-list"
        >
          {optionList.map((option) => (
            <Radio.Button key={option.type} value={option.type}>
              {option.title}
            </Radio.Button>
          ))}
        </Radio.Group>

        <div className="template-list">
          {option === '0' && (
            <Card
              actions={[
                <Button
                  key="create"
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() =>
                    handleSelected({
                      blockList: [],
                      templateId: '',
                      title: '',
                      type: '',
                    })
                  }
                >
                  Oluştur
                </Button>,
              ]}
              cover={<ImageScroll src={''} />}
            >
              <Meta title="Boş sayfa" description="Boş sayfa oluştur" />
            </Card>
          )}

          {templateListFiltered.map((template) => (
            <Card
              key={template.templateId}
              actions={[
                <Button key="create" type="link" icon={<EditOutlined />} onClick={() => handleSelected(template)}>
                  Oluştur
                </Button>,
                <Button key="preview" type="link" icon={<LinkOutlined />} onClick={() => handlePreview(template)}>
                  Önizle
                </Button>,
              ]}
              cover={<ImageScroll src={template.cover || ''} />}
            >
              <Meta title={template.title} description={template.description} />
            </Card>
          ))}
        </div>
      </div>

      <Modal
        open={!!templatePreview}
        onCancel={() => handlePreviewCancel()}
        onOk={() => {
          setTemplateSelected(templatePreview);
          handlePreviewCancel();
        }}
        width="100%"
        className="modal-preview"
        centered
        okText="Oluştur"
        okButtonProps={{
          icon: <EditOutlined />,
        }}
        closable={false}
        zIndex={1}
      >
        <div style={{ height: '80vh' }}>
          <Browser link={`${project?.linkSchema}${project?.link}`} title={project?.title}>
            <img src={templatePreview?.cover as string} style={{ width: '100%' }} alt="" />
          </Browser>
        </div>
      </Modal>

      {templateSelected && project && (
        <WebsiteSetting
          project={project}
          templateSelected={templateSelected}
          handleSelectedCancel={handleSelectedCancel}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
}
