import { combineReducers } from 'redux';

import profile from './Profile';
import project from './Project';
import account from './Account';
import workspace from './Workspace';

export default combineReducers({
  profile,
  project,
  account,
  workspace,
});
