import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import type { IError, IStockImage } from '../Type';

export const imagesByTitle = async (
  projectId: string,
  title: string,
): Promise<{
  result?: IStockImage[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query imagesByTitle($projectId: ID!, $title: String!) {
          imagesByTitle(projectId: $projectId, title: $title) {
            id
            urls {
              full
              raw
              regular
              small
              thumb
            }
            width
            height
            color
          }
        }
      `,
      variables: {
        projectId,
        title,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.imagesByTitle,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const sliderBySection = async (
  projectId: string,
  title: string,
): Promise<{
  result?: IStockImage[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query sliderBySection($projectId: ID!, $title: String!) {
          sliderBySection(projectId: $projectId, title: $title) {
            id
            urls {
              full
              raw
              regular
              small
              thumb
            }
            width
            height
            color
          }
        }
      `,
      variables: {
        projectId,
        title,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.sliderBySection,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
