import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  image: any;
  height: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Giriş Kapak Bloğu',
  description: 'Web sayfasının üst kısmında yer alan, büyük bir görsel ve başlık içeren giriş bölümü.',
  type: 'cover-1',
  code: 'CVR1',
  category: ['cover'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'image', 'height'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      image: {
        type: 'string',
        title: 'Görsel',
      },
      height: { type: 'string', title: 'Yükseklik', default: '' },
    },
  },
  schemaUI: {
    image: {
      'ui:widget': 'imageUpload',
      crop: false,
    },
  },
  styleDefault: {
    container: 'full',
    background: '#000',
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for cover section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || page.title,
      image: '/img/cover/01.jpg',
      height: '100vh',
    };
  },
};

export default config;
