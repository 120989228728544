import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import type {
  IContentFaq,
  IContentFeature,
  IContentMissionVisionValues,
  IContentPage,
  IContentTimeline,
  IContentTitleDescription,
  IError,
} from '../Type';

export const contentTitleDescription = async (
  projectId: string,
  pageId: string,
  prompt: string,
): Promise<{
  result?: IContentTitleDescription;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentTitleDescription($projectId: ID!, $pageId: ID!, $prompt: String!) {
          contentTitleDescription(projectId: $projectId, pageId: $pageId, prompt: $prompt) {
            title
            description
          }
        }
      `,
      variables: {
        projectId,
        pageId,
        prompt,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentTitleDescription,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentFaq = async (
  projectId: string,
  pageId: string,
  prompt: string,
): Promise<{
  result?: IContentFaq[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentFaq($projectId: ID!, $pageId: ID!, $prompt: String!) {
          contentFaq(projectId: $projectId, pageId: $pageId, prompt: $prompt) {
            title
            description
          }
        }
      `,
      variables: {
        projectId,
        pageId,
        prompt,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentFaq,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentFeature = async (
  projectId: string,
  pageId: string,
  prompt: string,
): Promise<{
  result?: IContentFeature[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentFeature($projectId: ID!, $pageId: ID!, $prompt: String!) {
          contentFeature(projectId: $projectId, pageId: $pageId, prompt: $prompt) {
            icon
            title
            description
          }
        }
      `,
      variables: {
        projectId,
        pageId,
        prompt,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentFeature,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentTimeline = async (
  projectId: string,
  pageId: string,
  prompt: string,
): Promise<{
  result?: IContentTimeline[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentTimeline($projectId: ID!, $pageId: ID!, $prompt: String!) {
          contentTimeline(projectId: $projectId, pageId: $pageId, prompt: $prompt) {
            title
          }
        }
      `,
      variables: {
        projectId,
        pageId,
        prompt,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentTimeline,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentMissionVisionValues = async (
  projectId: string,
  pageId: string,
  prompt: string,
): Promise<{
  result?: IContentMissionVisionValues;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentMissionVisionValues($projectId: ID!, $pageId: ID!, $prompt: String!) {
          contentMissionVisionValues(projectId: $projectId, pageId: $pageId, prompt: $prompt) {
            mission
            vision
            values
          }
        }
      `,
      variables: {
        projectId,
        pageId,
        prompt,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentMissionVisionValues,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentPage = async (
  projectId: string,
  pageId: string,
  prompt: string,
): Promise<{
  result?: IContentPage;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentPage($projectId: ID!, $pageId: ID!, $prompt: String!) {
          contentPage(projectId: $projectId, pageId: $pageId, prompt: $prompt) {
            content
          }
        }
      `,
      variables: {
        projectId,
        pageId,
        prompt,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPage,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
