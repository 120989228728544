import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentFeature, contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  process: {
    icon: string;
    title: string;
    description: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Süreç',
  description: 'İş süreçlerimizi nasıl ilerlettiğimiz içerikleri',
  type: 'process-1',
  code: 'PRC-1',
  category: ['process'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'process'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      process: {
        type: 'array',
        title: 'Özellikler',
        items: {
          type: 'object',
          required: ['text', 'value'],
          title: '',
          properties: {
            icon: {
              type: 'string',
              title: 'Material Symbol Icon',
            },
            title: {
              type: 'string',
              title: 'Başlık',
            },
            description: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  styleDefault: {
    background: 'rgb(55 65 81)',
    container: 'compact',
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for how we work section in page: "${page.title}"`,
    );

    const cFaq = await contentFeature(
      project.projectId,
      page.pageId,
      `Write 4 title and description for how we work and create a select an icon from google font symbols for section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || 'Özellikler',
      description: cTitleDescription?.result?.description || 'Detaylar. ',
      process: (cFaq.result || []).map((result) => ({
        icon: result.icon || '',
        title: result.title || '',
        description: result.description || '',
      })),
    };
  },
};

export default config;
