import { EditorConfigW } from '../../../../service/Type';

interface Story {
  title: string;
  type: 'image';
  src: string;
}

export interface EProps {
  storys: Story[];
}

const config: EditorConfigW<EProps> = {
  title: 'Story Gösterisi',
  description: 'Görsellerin veya içeriklerin otomatik veya kullanıcı etkileşimiyle değiştiği, dinamik bir görsel slayt gösterisi.',
  type: 'story-1',
  code: 'STR1',
  category: ['story'],
  suitable: ['main'],
  schema: {
    title: '',
    type: 'object',
    required: ['storys'],
    properties: {
      storys: {
        type: 'array',
        title: 'Slidelar',
        items: {
          properties: {
            title: {
              type: 'string',
              title: 'Story Başlığı',
            },
            src: {
              type: 'string',
              title: 'Görsel',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    storys: {
      items: {
        'ui:title': 'Story',
        src: {
          'ui:widget': 'imageUpload',
        },
        title: {
          'ui:widget': 'text',
        },
      },
    },
  },
  propsDefault: async () => {
    return {
      storys: [],
    };
  },
};

export default config;
