export const SET_BUILDER = 'SET_BUILDER';

export const SET_BUILDER_TYPE = 'SET_BUILDER_TYPE';
export const SET_BUILDER_LANGUAGE = 'SET_BUILDER_LANGUAGE';
export const SET_BUILDER_TITLE = 'SET_BUILDER_TITLE';
export const SET_BUILDER_PURPOSE = 'SET_BUILDER_PURPOSE';
export const SET_BUILDER_PAGE = 'SET_BUILDER_PAGE';
export const SET_BUILDER_TARGET = 'SET_BUILDER_TARGET';
export const SET_BUILDER_SLOGAN = 'SET_BUILDER_SLOGAN';

export const SET_BUILDER_ADDRESS = 'SET_BUILDER_ADDRESS';
export const SET_BUILDER_EMAIL = 'SET_BUILDER_EMAIL';
export const SET_BUILDER_PHONE = 'SET_BUILDER_PHONE';

export const SET_BUILDER_COLOR = 'SET_BUILDER_COLOR';
export const SET_BUILDER_FONT = 'SET_BUILDER_FONT';
export const SET_BUILDER_FONT_SIZE = 'SET_BUILDER_FONT_SIZE';

export const SET_BUILDER_SOCIAL_MEDIA = 'SET_BUILDER_SOCIAL_MEDIA';
