import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentPage } from '../../../../service/api/Content';
import { imagesByTitle } from '../../../../service/api/ImageStock';

export interface EProps {
  image: string;
  content: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Görsel İçerik Bloğu',
  description: 'Genel içerik sunumları için kullanılabilen, metin ve görselleri bir araya getiren içerik bloğu.\n',
  type: 'content-image-4',
  code: 'CI4',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['content', 'image'],
    properties: {
      content: { type: 'string', title: 'İçerik', default: '' },
      image: {
        type: 'string',
        title: 'Görsel',
      },
    },
  },
  schemaUI: {
    content: {
      'ui:widget': 'quill',
    },
    image: {
      'ui:widget': 'imageUpload',
      crop: false,
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const imageResult = await imagesByTitle(project.projectId, 'About Us');
    const cPage = await contentPage(project.projectId, page.pageId, `Write page content in page: "${page.title}"`);

    return {
      image: imageResult?.result ? imageResult?.result[Math.floor(Math.random() * imageResult?.result.length)]?.urls?.regular || '' : '',
      content: cPage?.result?.content || '',
    };
  },
};

export default config;
