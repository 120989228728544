import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Frame from 'react-frame-component';
import { Button, Radio, Tooltip, Watermark } from 'antd';
import {
  EditOutlined,
  CopyOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  SettingOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  LinkOutlined,
  SunOutlined,
  MoonOutlined,
  RedoOutlined,
  FundProjectionScreenOutlined,
  LaptopOutlined,
  TabletOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { IBlock, IPageBlock } from '../../service/TypeGraphql';
import { createPageBlockUuid } from '../../service/util/Uuid';
import * as ArrayCustom from '../../service/util/ArrayCustom';
import { EditorWrapperProps, EditorStyle, EditorProps } from '../../service/Type';
import { blockList } from './component/Data';
import ComponentStyle from './component-shared/ComponentStyle';
import EditorBlockContent from './EditorBlockContent';
import EditorBlockSwitch from './EditorBlockSwitch';
import EditorBlockCreate from './EditorBlockCreate';
import EditorPreview from './EditorPreview';
import EditorPageSetting from './EditorPageSetting';
import EditorThemeConfigProvider from './EditorThemeConfigProvider';
import { useTheme } from './ThemeProvider';
import './Editor.scss';

export default function Editor({
  pageId,
  project,
  page,
  pageBlocks,
  updatePageBlocks,
  isSubmitting,
  handleSubmit,
  handleUpdate,
}: EditorWrapperProps) {
  const [pageBlocksUpdated, setPageBlocks] = useState<IPageBlock[]>(pageBlocks);
  const [blockId, setPageBlockId] = useState<string>();
  const [blockIdSwitch, setPageBlockIdSwitch] = useState<string>();
  const [pageBlockCreateIndex, setPageBlockCreateIndex] = useState<number>(-1);
  const [pageBlockCreatePart, setPageBlockCreatePart] = useState<string>('');
  const [preview, setPreview] = useState<boolean>(false);
  const [setting, setSetting] = useState<boolean>(false);
  const [createButtonPart, setCreateButtonPart] = useState<string>();
  const [createButtonIndex, setCreateButtonIndex] = useState<number>();
  const { i18n } = useTranslation();

  // useEffect(() => {
  //   document.documentElement.style.setProperty('--color-primary', project.colorPrimary);
  //   document.documentElement.style.setProperty('--color-text', project.colorText);
  // }, []);

  useEffect(() => {
    i18n.changeLanguage(project.language).then();
  }, [project.language]);

  useEffect(() => {
    updatePageBlocks(pageBlocksUpdated);
  }, [pageBlocksUpdated]);

  const [isMobile, setIsMobile] = useState(window.matchMedia && window.matchMedia('(max-width: 920px)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 920px)');
    const handleMediaQueryChange = (e: any) => {
      setIsMobile(e.matches);
    };

    // Medya sorgusu değiştiğinde state'i güncelle
    mediaQuery.addListener(handleMediaQueryChange);

    // Cleanup fonksiyonu
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  const blockSave = async (pageBlocks: IPageBlock[]) => {
    const pageBlocksUpdated = pageBlocks;

    for (let key = 0; key < pageBlocksUpdated.length; key++) {
      pageBlocksUpdated[key].sort = key;
    }

    await setPageBlocks(pageBlocksUpdated);
  };

  const blockRegenerate = async (blockId: string) => {
    const pageBlock = pageBlocksUpdated.find((pageBlock) => pageBlock.blockId === blockId) as IPageBlock;
    const block = blockList.find((block) => block.config.type === pageBlock.block.type);
    const propsNew = await block?.config?.propsDefault(project, page);
    pageBlock.block.props = {
      ...pageBlock.block.props,
      ...propsNew,
    };

    await blockSave(pageBlocksUpdated);
  };

  const blockUpdateProps = async (blockId: string, props: any) => {
    const pageBlock = pageBlocksUpdated.find((pageBlock) => pageBlock.blockId === blockId) as IPageBlock;

    pageBlock.block.props = {
      ...props,
    };

    await blockSave(pageBlocksUpdated);
  };

  const blockUpdateStyle = async (blockId: string, style: any) => {
    const pageBlock = pageBlocksUpdated.find((pageBlock) => pageBlock.blockId === blockId) as IPageBlock;

    pageBlock.block.style = {
      ...style,
    };

    await blockSave(pageBlocksUpdated);
  };

  const blockSwitch = async (blockId: string, type: string, props: EditorProps, style: EditorStyle) => {
    setPageBlockIdSwitch(undefined);

    const pageBlock = pageBlocksUpdated.find((pageBlock) => pageBlock.blockId === blockId) as IPageBlock;

    pageBlock.block.type = type;
    pageBlock.block.props = props;
    pageBlock.block.style = style;

    await blockSave(pageBlocksUpdated);
  };

  const blockCreate = async (index: number, part: string) => {
    await setPageBlockCreateIndex(index);
    await setPageBlockCreatePart(part);
    await setPageBlockId(undefined);
  };

  const blockCreateDo = async (index: number, part: string, block: IBlock) => {
    const blockId = createPageBlockUuid();

    let sortIndex = index;
    if (part === 'main') {
      sortIndex += pageBlocks.filter((pageBlock) => pageBlock.part === 'header').length;
    }
    if (part === 'footer') {
      sortIndex += pageBlocks.filter((pageBlock) => pageBlock.part === 'main').length;
      sortIndex += pageBlocks.filter((pageBlock) => pageBlock.part === 'header').length;
    }

    const pageBlock: IPageBlock = {
      blockId: createPageBlockUuid(),
      block: {
        ...block,
        blockId,
      },
      part,
      sort: sortIndex,
      pageId,
    };

    await blockSave([
      ...pageBlocksUpdated.slice(0, sortIndex),
      {
        ...pageBlock,
        sort: sortIndex,
        blockId: createPageBlockUuid(),
      },
      ...pageBlocksUpdated.slice(sortIndex, pageBlocksUpdated.length),
    ]);
  };

  const blockCopy = async (blockId: string) => {
    const pageBlockIndex = pageBlocksUpdated.findIndex((pageBlock) => pageBlock.blockId === blockId);

    await blockSave([
      ...pageBlocksUpdated.slice(0, pageBlockIndex),
      {
        ...JSON.parse(JSON.stringify(pageBlocksUpdated.slice(pageBlockIndex, pageBlockIndex + 1)[0])),
        blockId: createPageBlockUuid(),
      },
      ...pageBlocksUpdated.slice(pageBlockIndex, pageBlocksUpdated.length),
    ]);
  };

  const blockMoveUp = async (blockId: string) => {
    const pageBlockIndex = pageBlocksUpdated.findIndex((pageBlock) => pageBlock.blockId === blockId);

    await blockSave([...ArrayCustom.move(pageBlocksUpdated, pageBlockIndex, pageBlockIndex - 1)]);
  };

  const blockMoveDown = async (blockId: string) => {
    const pageBlockIndex = pageBlocksUpdated.findIndex((pageBlock) => pageBlock.blockId === blockId);

    await blockSave([...ArrayCustom.move(pageBlocksUpdated, pageBlockIndex, pageBlockIndex + 1)]);
  };

  const blockDelete = async (blockId: string) => {
    await blockSave(pageBlocksUpdated.filter((pageBlock) => pageBlock.blockId !== blockId));
  };

  const parts = [
    {
      value: 'header',
      label: 'Header',
    },
    {
      value: 'main',
      label: 'Main',
    },
    {
      value: 'footer',
      label: 'Footer',
    },
  ];

  const { theme, themeSet } = useTheme();
  const themes = [
    {
      value: 'light',
      label: (
        <>
          <SunOutlined /> {!isMobile && ' Gündüz Modu'}
        </>
      ),
    },
    {
      value: 'dark',
      label: (
        <>
          <MoonOutlined /> {!isMobile && ' Gece Modu'}
        </>
      ),
    },
  ];

  const [size, setSize] = useState('w-screen');
  const sizes = [
    {
      value: 'max-w-screen-sm',
      label: (
        <>
          <MobileOutlined />
          {!isMobile && ' S'}
        </>
      ),
    },
    {
      value: 'max-w-screen-md',
      label: (
        <>
          <TabletOutlined />
          {!isMobile && ' M'}
        </>
      ),
    },
    {
      value: 'max-w-screen-lg',
      label: (
        <>
          <LaptopOutlined />
          {!isMobile && ' L'}
        </>
      ),
    },
    {
      value: 'w-screen',
      label: (
        <>
          <FundProjectionScreenOutlined />
          {!isMobile && ' XL'}
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   const mutationObserver = new MutationObserver((mutations) => {
  //     mutations.forEach(async (mutation) => {
  //       console.log('Mutation detected:', mutation);
  //       await setCreateButtonIndex(0);
  //     });
  //   });
  //
  //   const config = {
  //     childList: true,
  //     subtree: true,
  //     attributes: true,
  //     attributeFilter: ['href', 'rel', 'type'],
  //   };
  //
  //   mutationObserver.observe(document.head, config);
  //
  //   return () => mutationObserver.disconnect();
  // }, []);

  return (
    <>
      <div className="editor-button-wrapper">
        <div className="editor-button">
          <Button size="small" onClick={() => setSetting(true)} icon={<SettingOutlined />} style={{ backgroundColor: '#fff' }}>
            {!isMobile && 'Sayfa Ayarları'}
          </Button>

          <Button size="small" onClick={() => setPreview(true)} icon={<FolderViewOutlined />} style={{ backgroundColor: '#fff' }}>
            {!isMobile && 'Önizleme'}
          </Button>

          <Radio.Group options={themes} onChange={(e) => themeSet(e.target.value)} value={theme} optionType="button" size="small" />
          <Radio.Group options={sizes} onChange={(e) => setSize(e.target.value)} value={size} optionType="button" size="small" />

          <div style={{ flex: 1 }} />

          <a key="open" href={`${project.linkSchema}${project.link}${page.path}`} target="_blank" rel="noreferrer">
            <Button size="small" icon={<LinkOutlined />} style={{ backgroundColor: '#fff' }}>
              {!isMobile && 'Görüntüle'}
            </Button>
          </a>
          <Button
            size="small"
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
            icon={<SaveOutlined />}
            style={{ backgroundColor: '#fff' }}
          >
            {!isMobile && 'Kaydet'}
          </Button>
        </div>
      </div>
      <div className="editor-frame-wrapper">
        <React.Suspense fallback={<div className="block text-center p-24">Yükleniyor...</div>}>
          <Frame
            className={`editor-frame editor-frame-${size}`}
            head={
              <>
                {Array.from(document.querySelectorAll('style')).map((style, styleIndex) => (
                  <style key={styleIndex}>{style.innerHTML}</style>
                ))}
                {Array.from(document.querySelectorAll('link[rel="stylesheet"]')).map((link, linkIndex) => (
                  <link
                    rel="stylesheet"
                    key={linkIndex}
                    // @ts-ignore
                    href={link.href || ''}
                  />
                ))}
              </>
            }
          >
            <div className={`editor ${theme}`}>
              {parts.map((part) => {
                const pageBlocksPart = pageBlocksUpdated
                  .filter((pageBlock) => pageBlock.part === part.value)
                  .sort((a, b) => a.sort - b.sort);

                if (pageBlocksPart.length === 0) {
                  return (
                    <Watermark key={part.value} content={part.label} className={`part-${part.value} part-watermark`}>
                      <div className="block-create">
                        <Button icon={<PlusOutlined />} onClick={() => blockCreate(0, part.value)} size="small" />
                      </div>
                    </Watermark>
                  );
                }

                return (
                  <div key={part.value} className={`part-${part.value}`}>
                    {pageBlocksPart.map((pageBlock, blockIndex) => {
                      const block = blockList.find((block) => block.config.type === pageBlock.block.type);

                      if (!block) {
                        return null;
                      }

                      return (
                        <React.Fragment key={pageBlock.blockId}>
                          {createButtonPart === part.value && createButtonIndex === blockIndex && (
                            <div className="block-create">
                              <Button icon={<PlusOutlined />} size="small" onClick={() => blockCreate(blockIndex, part.value)} />
                            </div>
                          )}

                          <div
                            className="block"
                            onMouseEnter={() => {
                              setCreateButtonIndex(blockIndex);
                              setCreateButtonPart(part.value);
                            }}
                          >
                            <div className="block-action">
                              <Tooltip title="Farklı Blok Seç">
                                <Button size="small" icon={<EditOutlined />} onClick={() => setPageBlockIdSwitch(pageBlock.blockId)}>
                                  {block.config.code}
                                </Button>
                              </Tooltip>
                              <Tooltip title="İçeriği Düzenle">
                                <Button size="small" icon={<EditOutlined />} onClick={() => setPageBlockId(pageBlock.blockId)} />
                              </Tooltip>
                              <Tooltip title="İçeriği Tekrar Oluştur">
                                <Button size="small" icon={<RedoOutlined />} onClick={() => blockRegenerate(pageBlock.blockId)} />
                              </Tooltip>

                              <div style={{ flex: 1 }} />

                              <Tooltip title="Kopyala">
                                <Button size="small" icon={<CopyOutlined />} onClick={() => blockCopy(pageBlock.blockId)} />
                              </Tooltip>
                              <Tooltip title="Sil">
                                <Button size="small" icon={<DeleteOutlined />} onClick={() => blockDelete(pageBlock.blockId)} />
                              </Tooltip>
                              <Tooltip title="Yukarı Kaydır">
                                <Button
                                  size="small"
                                  icon={<ArrowUpOutlined />}
                                  onClick={() => blockMoveUp(pageBlock.blockId)}
                                  disabled={blockIndex === 0}
                                />
                              </Tooltip>
                              <Tooltip title="Aşağı Kaydır">
                                <Button
                                  size="small"
                                  icon={<ArrowDownOutlined />}
                                  onClick={() => blockMoveDown(pageBlock.blockId)}
                                  disabled={blockIndex === pageBlocksPart.length - 1}
                                />
                              </Tooltip>
                            </div>
                            <div className="block-description">
                              {block.config.title}
                              <br />
                              {block.config.description}
                            </div>
                            <EditorThemeConfigProvider project={project} isEven={blockIndex % 2 === 0}>
                              <ComponentStyle style={pageBlock.block.style}>
                                <block.editor
                                  blockId={pageBlock.blockId}
                                  style={pageBlock.block.style}
                                  props={pageBlock.block.props}
                                  onChangeProps={(props: any) => {
                                    blockUpdateProps(pageBlock.blockId, props);
                                  }}
                                  onChangeStyle={(style: any) => {
                                    blockUpdateStyle(pageBlock.blockId, style);
                                  }}
                                  project={project}
                                  page={page}
                                />
                              </ComponentStyle>
                            </EditorThemeConfigProvider>
                          </div>

                          {createButtonPart === part.value && createButtonIndex === blockIndex && (
                            <div className="block-create">
                              <Button icon={<PlusOutlined />} size="small" onClick={() => blockCreate(blockIndex + 1, part.value)} />
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Frame>
        </React.Suspense>
      </div>
      {blockId && (
        <EditorBlockContent
          open={!!blockId}
          onCancel={() => setPageBlockId(undefined)}
          onUpdate={async (props: EditorProps, style: EditorStyle) => {
            await blockUpdateProps(blockId as string, props);
            await blockUpdateStyle(blockId as string, style);
            await setPageBlockId(undefined);
          }}
          blockId={blockId as string}
          pageBlocks={pageBlocksUpdated}
          project={project}
          page={page}
        />
      )}
      {blockIdSwitch && (
        <EditorBlockSwitch
          open={!!blockIdSwitch}
          onCancel={() => setPageBlockIdSwitch(undefined)}
          onUpdate={async (type: string, props: EditorProps, style: EditorStyle) => {
            await blockSwitch(blockIdSwitch as string, type, props, style);
          }}
          blockId={blockIdSwitch as string}
          pageBlocks={pageBlocksUpdated}
          project={project}
          page={page}
        />
      )}
      {pageBlockCreateIndex > -1 && (
        <EditorBlockCreate
          open={pageBlockCreateIndex > -1}
          onCancel={() => setPageBlockCreateIndex(-1)}
          onCreate={async (block: IBlock) => {
            await blockCreateDo(pageBlockCreateIndex, pageBlockCreatePart, block);
            await setPageBlockCreateIndex(-1);
            await setPageBlockCreatePart('');
          }}
          project={project}
          page={page}
          part={pageBlockCreatePart}
        />
      )}
      {preview && (
        <EditorPreview open={preview} onCancel={() => setPreview(false)} project={project} page={page} pageBlockList={pageBlocksUpdated} />
      )}
      {setting && (
        <EditorPageSetting
          open={setting}
          onCancel={() => {
            setSetting(false);
            handleUpdate();
          }}
          page={page}
          project={project}
        />
      )}
    </>
  );
}
