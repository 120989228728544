import { EditorConfigW, IProject } from '../../../../service/Type';

export interface EProps {
  address: string;
  height: number;
}

const config: EditorConfigW<EProps> = {
  title: 'Konum ve Harita Bilgisi',
  description: 'İşletmenin konumunu, şube bilgilerini veya önemli yerleri gösteren interaktif bir harita bloğu.',
  type: 'map-1',
  code: 'MP1',
  category: ['map'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['address', 'height'],
    properties: {
      address: { type: 'string', title: 'Adres', default: '' },
      height: { type: 'number', title: 'Harita Yüksekliği', default: 500 },
    },
  },
  schemaUI: {},
  propsDefault: async (project: IProject) => {
    return {
      address: project.contactAddress || 'Türkiye',
      height: 500,
    };
  },
};

export default config;
