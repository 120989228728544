import React, { useState } from 'react';
import Breadcrumb from '../component/Breadcrumb';
import { Button, Card, Col, Radio, Row } from 'antd';
import { Link } from 'react-router-dom';
import { AppstoreAddOutlined } from '@ant-design/icons';
import Currency from '../component/format/Currency';
import type { CheckboxOptionType } from 'antd/es/checkbox/Group';

export default function Pricing() {
  const [period, setPeriod] = useState('yearly');

  const priceLevel2 = period === 'yearly' ? 60 : 110;
  const priceLevel3 = period === 'yearly' ? 320 : 500;

  const periods: CheckboxOptionType[] = [
    {
      value: 'yearly',
      label: 'Yılda Bir',
    },
    {
      value: 'monthly',
      label: 'Aydan Aya',
    },
  ];

  return (
    <div className="page">
      <Breadcrumb
        items={[
          {
            title: 'Fiyatlandırma ve Paketler',
          },
        ]}
      />

      <div className="page-title">
        <h1>Ücretlendirme</h1>
        <span style={{ flex: 1 }} />
      </div>

      <Radio.Group
        options={periods}
        onChange={(period) => setPeriod(period.target.value)}
        value={period}
        style={{ margin: '0 0 16px 0' }}
      />

      <Row gutter={16}>
        <Col span={8}>
          <Card
            title="Başlangıç"
            bordered
            style={{ marginBottom: 16 }}
            extra={<span>Ücretsiz</span>}
            actions={[
              <Link key="edit" to={`/satin-al/ucretsiz`}>
                <Button type="link" icon={<AppstoreAddOutlined />}>
                  Websitesi Oluştur
                </Button>
              </Link>,
            ]}
          >
            <div style={{ textAlign: 'center', height: 200 }}>
              <p>1 Website</p>
              <p>Yapay zeka ile hızlıca, basit ve güzel bir websitesi oluşturun.</p>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Kişisel"
            bordered
            style={{ marginBottom: 16 }}
            extra={
              <span>
                <Currency price={priceLevel2} /> / Ay
              </span>
            }
            actions={[
              <Link key="edit" to={`/satin-al/kisisel`}>
                <Button type="link" icon={<AppstoreAddOutlined />}>
                  Websitesi Oluştur
                </Button>
              </Link>,
            ]}
          >
            <div style={{ textAlign: 'center', height: 200 }}>
              <p>1 Website</p>
              <p>Yapay zeka ile hızlıca, basit ve güzel bir websitesi oluşturun.</p>
              <p>
                <strong>
                  <Currency price={priceLevel2} />
                </strong>
                / Ay
                {period === 'yearly' && (
                  <>
                    <br /> <small>Yıllık ödemede</small>
                  </>
                )}
              </p>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Ajans"
            bordered
            style={{ marginBottom: 16 }}
            extra={
              <span>
                <Currency price={priceLevel3} /> / Ay
              </span>
            }
            actions={[
              <Link key="edit" to={`/satin-al/ajans`}>
                <Button type="link" icon={<AppstoreAddOutlined />}>
                  Websitesi Oluştur
                </Button>
              </Link>,
            ]}
          >
            <div style={{ textAlign: 'center', height: 200 }}>
              <p>5 Website</p>
              <p>Kişisel plandaki tüm özellikler, 5 websitesi ve kodları indirme özelliği.</p>
              <p>
                <strong>
                  <Currency price={priceLevel3} />
                </strong>
                / Ay
                {period === 'yearly' && (
                  <>
                    <br /> <small>Yıllık ödemede</small>
                  </>
                )}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
