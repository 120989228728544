import React from 'react';
import { ConfigProvider, theme as themeAntd } from 'antd';
import { IProject } from '../../service/TypeGraphql';
import { useTheme } from './ThemeProvider';

interface EditorThemeConfigProviderProps {
  project: IProject;
  children: any | any[];
  isEven?: boolean;
}

export default function EditorThemeConfigProvider({ project, children }: EditorThemeConfigProviderProps) {
  const { defaultAlgorithm, darkAlgorithm } = themeAntd;

  const { theme } = useTheme();

  return (
    <ConfigProvider
      theme={{
        algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: project?.colorPrimary || '#106fff',
          // colorText: project?.colorText || '#000000',
          // colorLink: project?.colorPrimary || '#106fff',
          // fontSize: project.fontSize,
          // fontFamily: project.fontFamily,
        },
      }}
    >
      <div className="block-component text-black dark:text-white">{children}</div>
    </ConfigProvider>
  );
}
