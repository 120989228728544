import { EditorConfigW } from '../../../../service/Type';

export interface EProps {}

const config: EditorConfigW<EProps> = {
  title: 'Web Sayfası Alt Bilgisi',
  description:
    'Web sayfasının altında yer alan, hakkında, gizlilik politikası, sosyal medya bağlantıları gibi temel bilgilerin bulunduğu bölüm.',
  type: 'footer-5',
  code: 'FTR5',
  category: ['footer'],
  suitable: ['footer'],
  propsDefault: async () => {
    return {};
  },
};

export default config;
