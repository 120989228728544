import { IProject } from '../../../service/TypeGraphql';
import { useTheme } from '../ThemeProvider';
import { useEffect, useState } from 'react';

export default function useLogo(project: IProject) {
  const { theme } = useTheme();
  const [isDark, setIsDark] = useState(theme === 'dark');

  useEffect(() => {
    setIsDark(theme === 'dark');
  }, [theme]);

  if (project.logoDark && isDark) {
    return project.logoDark;
  }

  return project.logo;
}
