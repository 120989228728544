import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { HighlightOutlined, GlobalOutlined, CommentOutlined, ContactsOutlined } from '@ant-design/icons';
import PrivacyPolicy from './module/PrivacyPolicy';
import CookiePolicy from './module/CookiePolicy';
import TermsOfUse from './module/TermsOfUse';
import Contact from './module/Contact';

export default function Content() {
  const location = useLocation();

  const items = [
    {
      key: `/iletisim`,
      path: `/iletisim`,
      label: <Link to={`/iletisim`}>İletişim</Link>,
      icon: <ContactsOutlined />,
      element: <Contact />,
    },
    {
      key: `/gizlilik-politikasi`,
      path: `/gizlilik-politikasi`,
      label: <Link to={`/gizlilik-politikasi`}>Gizlilik Sözleşmesi</Link>,
      icon: <HighlightOutlined />,
      element: <PrivacyPolicy />,
    },
    {
      key: `/cerez-politikasi`,
      path: `/cerez-politikasi`,
      label: <Link to={`/cerez-politikasi`}>Çerez Politikası</Link>,
      icon: <GlobalOutlined />,
      element: <CookiePolicy />,
    },
    {
      key: `/kullanim-sartlari`,
      path: `/kullanim-sartlari`,
      label: <Link to={`/kullanim-sartlari`}>Kullanım Şartları</Link>,
      icon: <CommentOutlined />,
      element: <TermsOfUse />,
    },
  ];

  return (
    <>
      <div className="page flex gap-6">
        <article className="flex-1">{items.find((item) => item.path === location.pathname)?.element}</article>
        <aside>
          <Menu theme="light" mode="vertical" selectedKeys={[location.pathname]} items={items} style={{ flex: 1 }} />
        </aside>
      </div>
    </>
  );
}
