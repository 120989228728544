import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Spin } from 'antd';
import { projectCreate, projectUpdate } from '../../../service/api/Project';
import { IProjectCreateData } from '../../../service/TypeGraphql';
import { imageUpload } from '../../../service/api/Image';
import './BuilderDeploy.scss';

interface BuilderDeployProps {
  state: IProjectCreateData;
  dispatch: any;
  onPrevious: any;
  onComplete: any;
  setPercent: any;
}

export default function BuilderDeploy({ state, onPrevious, setPercent }: BuilderDeployProps) {
  const [ready, setState] = useState('ready');
  const [projectId, setProjectId] = useState<string>();

  const onDeploy = async () => {
    setPercent(50);
    setState('deploying');

    const projectCreateResult = await projectCreate(state);
    setProjectId(projectCreateResult.result?.projectId);

    if (projectCreateResult.result?.projectId) {
      let logo = '';
      let logoDark = '';
      let favicon = '';

      if (state.logo) {
        const imageUploadResult = await imageUpload(projectCreateResult.result.projectId, state.logo, 'image/png', 'logo');
        logo = imageUploadResult.result?.path || '';
      }

      if (state.logoDark) {
        const imageUploadResult = await imageUpload(projectCreateResult.result.projectId, state.logoDark, 'image/png', 'logoDark');
        logoDark = imageUploadResult.result?.path || '';
      }

      if (state.favicon) {
        const imageUploadResult = await imageUpload(projectCreateResult.result.projectId, state.favicon, 'image/png', 'favicon');
        favicon = imageUploadResult.result?.path || '';
      }

      setPercent(75);

      await projectUpdate(projectCreateResult.result.projectId, {
        logo,
        logoDark,
        favicon,
      });
    } else {
      setPercent(0);
    }

    setPercent(100);
    setState('deployed');
  };

  return (
    <div
      className="builder-deploy"
      style={{
        height: 'calc(100vh - 143px)',
      }}
    >
      {ready === 'ready' && (
        <div>
          <h2>Yayınlamak için yayınla butonuna tıklayın!</h2>
          <Space>
            <Button onClick={() => onPrevious()}>Geri</Button>
            <Button type="primary" onClick={() => onDeploy()}>
              Yayınla
            </Button>
          </Space>
        </div>
      )}

      {ready === 'deploying' && (
        <div>
          <h2 className="mb-6">Websiteniz oluşturuluyor</h2>
          <div className="mb-6">
            <Spin />
          </div>
          <p>Lütfen biz websitenizi hazırlarken biraz bekleyin!</p>
        </div>
      )}

      {ready === 'deployed' && (
        <div>
          <h2>Müjde Websiteniz Yayınlandı!</h2>
          <h3>Website adresiniz,</h3>
          <p>
            <a href={`http://${state.link}.${process.env.REACT_APP_RENDER_BASE}`} target="_blank" rel="noreferrer">
              http://{state.link}.{process.env.REACT_APP_RENDER_BASE}
            </a>
            <p className="mb-6">Websitenizi düzenleyin,</p>
            <div className="mb-6">
              <Link to={`/project/${projectId}`}>
                <Button>Websitesini Düzenle</Button>
              </Link>
            </div>
            {process.env.NODE_ENV === 'development' && (
              <>
                <p className="mb-6">Tekrar Dene</p>
                <Button onClick={() => setState('ready')}>Baştan Başla</Button>
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
}
