import { client } from './Api';
import { gql } from '@apollo/client';
import { notification } from 'antd';
import { IImage } from '../TypeGraphql';
import { IError } from '../Type';

export const imageUpload = async (
  projectId: string,
  imageData: string,
  imageMime: string,
  imageName: string,
): Promise<{
  result?: IImage;
  errors?: IError;
}> => {
  try {
    const result = await client
      .mutate({
        mutation: gql`
          mutation imageUpload($data: ImageUploadData!) {
            imageUpload(data: $data) {
              imageId
              path
              size
              width
              height
            }
          }
        `,
        variables: {
          data: {
            projectId,
            imageData,
            imageMime,
            imageName,
          },
        },
      })
      .then();

    return {
      result: result.data.imageUpload,
      errors: undefined,
    };
  } catch (e) {
    notification.error({
      message: 'Error',
      description: 'There is an error. Please try again later. ',
    });

    return {
      result: undefined,
      errors: undefined,
    };
  }
};
