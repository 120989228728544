import { Formik, ErrorMessage } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { Input, Form, Button, Card, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { languageList } from '../../../service/Language';

export default function WebsiteSettingGeneral() {
  const schema = yup.object().shape({
    language: yup.string().required('Website dili seçilmelidir'),
    title: yup.string().required('Website adı girilmelidir').max(256, 'Website adı en fazla 256 karakter uzunluğunda olabilir'),
    description: yup
      .string()
      .required('Website açıklaması girilmelidir')
      .max(256, 'Website açıklaması en fazla 256 karakter uzunluğunda olabilir'),
    slug: yup.string().required('Website linki girilmelidir').max(64, 'Website linki en fazla 64 karakter uzunluğunda olabilir'),
  });

  const onSubmit = (values: any) => {
    console.log(values);
  };

  const initialValues = {
    language: 'tr-TR',
    title: 'Altın Köfte',
    description: 'Dünyanın en şirketi',
    slug: 'test',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange
      validateOnBlur
      onSubmit={async (values) => {
        await onSubmit(values);
        return true;
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
        <Form layout="vertical">
          <Card title="Genel Ayarlar" style={{ marginBottom: 16 }}>
            <Form.Item label="Website dili">
              <Select
                value={values.language ? values.language : ''}
                style={{ width: '100%' }}
                onChange={(e) => setFieldValue('language', e)}
                options={languageList.map((language) => ({
                  label: language.nameTr,
                  value: language.code,
                }))}
              />
              <ErrorMessage name="language" component="div" className="ant-form-feedback" />
            </Form.Item>
            <Form.Item label="Website adı">
              <Input name="title" value={values.title ? values.title : ''} onChange={(e) => setFieldValue('title', e.target.value)} />
              <ErrorMessage name="title" component="div" className="ant-form-feedback" />
            </Form.Item>
            <Form.Item label="Website linki">
              <Input
                addonBefore="http://"
                addonAfter={`.${process.env.REACT_APP_RENDER_BASE}`}
                value={values.slug ? values.slug : ''}
                onChange={(e) => setFieldValue('slug', e.target.value)}
              />
              <ErrorMessage name="slug" component="div" className="ant-form-feedback" />
            </Form.Item>
          </Card>

          <Button
            onClick={() => handleSubmit()}
            type="primary"
            disabled={isSubmitting}
            icon={<SaveOutlined />}
            style={{ marginBottom: 16 }}
          >
            Kaydet
          </Button>
        </Form>
      )}
    </Formik>
  );
}
