import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Dropdown, Result, Tooltip } from 'antd';
import { LinkOutlined, EditOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { IError, IProject } from '../service/Type';
import { loadProjectList } from '../service/api/Project';
import './Dashboard.scss';

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();
  const [projectList, setProjectList] = useState<IProject[]>([]);

  const load = async () => {
    const projectResult = await loadProjectList({});
    if (projectResult.result) {
      await setProjectList(projectResult.result);
    }
    if (projectResult.errors) {
      await setError(projectResult.errors);
    }

    await setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return <div className="page">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="page">Hata</div>;
  }

  return (
    <div className="page">
      <div className="page-title">
        <h1>Projelerim</h1>
        <span style={{ flex: 1 }} />
        {projectList.length > 0 && (
          <Link to="/project-olustur">
            <Button icon={<PlusOutlined />} type="primary">
              Yeni Website Oluştur
            </Button>
          </Link>
        )}
      </div>
      {projectList.length === 0 && (
        <>
          <Result
            status="404"
            title="Henüz bir websitesi oluşturmadınız!"
            subTitle="Yeni websitesi oluşturmak için butonu kullanın."
            extra={
              <Link to="/project-olustur">
                <Button icon={<PlusOutlined />} type="primary">
                  Yeni Website Oluştur
                </Button>
              </Link>
            }
          />
        </>
      )}
      <div className="project-list">
        {projectList.map((project) => (
          <Card
            key={project.projectId}
            hoverable
            size="small"
            title={
              <>
                {project.favicon && <img className="project-favicon" src={project.favicon} alt="" />}
                {project.title}
              </>
            }
            bordered
            extra={
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'main',
                      label: <Link to={`/project/${project.projectId}/ayarlar`}>Genel Ayarlar</Link>,
                    },
                    {
                      key: 'font',
                      label: <Link to={`/project/${project.projectId}/ayarlar/font-ve-renkler`}>Font ve Renkler</Link>,
                    },
                    {
                      key: 'home',
                      label: <Link to={`/project/${project.projectId}/ayarlar/anasayfa`}>Ana Sayfa</Link>,
                    },
                    {
                      key: 'link',
                      label: <Link to={`/project/${project.projectId}/ayarlar/domain`}>Domain Ayarları</Link>,
                    },
                    {
                      key: 'chart',
                      label: <Link to={`/project/${project.projectId}/ayarlar/analitik`}>Analitik</Link>,
                    },
                    {
                      key: 'seo',
                      label: (
                        <Link to={`/project/${project.projectId}/ayarlar/arama-motoru-optimizasyonu`}>Arama Motorları Optimizasyonu</Link>
                      ),
                    },
                  ],
                }}
                placement="bottomRight"
                arrow
              >
                <Button type="link" icon={<MoreOutlined />} />
              </Dropdown>
            }
            actions={[
              <Tooltip key="edit" title="Düzenle">
                <Link to={`/project/${project.projectId}/`}>
                  <Button type="link" icon={<EditOutlined />} />
                </Link>
              </Tooltip>,
              <Tooltip key="edit" title="Görüntüle">
                <a key="link" href={`${project.linkSchema}${project.link}`} target="_blank" rel="noreferrer">
                  <Button type="link" icon={<LinkOutlined />} />
                </a>
              </Tooltip>,
            ]}
          >
            <Link to={`/project/${project.projectId}/`} className="text-black hover:text-black">
              {/*
            {project.logo && (
              <img className="project-logo" src={project.logo} alt="" />
            )}
            */}
              {project.type && (
                <>
                  <p className="mb-2">
                    <strong>Website Türü</strong> <br /> {project.type}
                  </p>
                </>
              )}
              {project.slogan && (
                <>
                  <p className="mb-2">
                    <strong>Slogan</strong> <br /> {project.slogan}
                  </p>
                </>
              )}
              {project.purpose && (
                <>
                  <p className="mb-2">
                    <strong>Amaç</strong> <br /> {project.purpose}
                  </p>
                </>
              )}
              {project.target && (
                <>
                  <p className="mb-2">
                    <strong>Hedef Kitle</strong> <br /> {project.target}
                  </p>
                </>
              )}
            </Link>
          </Card>
        ))}
      </div>
    </div>
  );
}
