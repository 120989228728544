import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { store } from '../store/store';
import { loadInitial as loadInitialAction } from '../store/actions';

export const loadInitial = async () => {
  try {
    const result = await client.query({
      query: gql`
        query initial {
          profile {
            uuid
            nameLast
            nameFirst
            email
            phone
            isActive
            isValid
            isOnBoarded
            isOnBoardedModule
          }
          account {
            isAnyUserCreated
            commission
          }
          workspace {
            isAnyProjectCreated
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    store.dispatch(
      loadInitialAction({
        profile: result.data.profile,
        account: result.data.account,
        workspace: result.data.workspace,
      }),
    );

    return result.data.profile;
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }
};
