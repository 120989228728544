import React, { useEffect, useState } from 'react';
import { Button, Drawer, Input, Select, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  SET_BUILDER_ADDRESS,
  SET_BUILDER_EMAIL,
  SET_BUILDER_PURPOSE,
  SET_BUILDER_LANGUAGE,
  SET_BUILDER_TITLE,
  SET_BUILDER_PHONE,
  SET_BUILDER_SLOGAN,
  SET_BUILDER_TARGET,
  SET_BUILDER_SOCIAL_MEDIA,
} from '../service/actionTypes';
import { IProjectCreateData } from '../../../service/TypeGraphql';
import { languageList } from '../../../service/Language';
import { socialMediaData } from '../../../component/editor/component/shared/Link';
import './BuilderBrand.scss';

interface BuilderBrandProps {
  state: IProjectCreateData;
  dispatch: any;
  onPrevious: any;
  onComplete: any;
  setPercent: any;
}

export default function BuilderBrand({ state, dispatch, onPrevious, onComplete, setPercent }: BuilderBrandProps) {
  const [showExplanationGoal, setShowExplanationGoal] = useState(false);
  const [showExplanationTarget, setShowExplanationTarget] = useState(false);

  useEffect(() => {
    let count = 0;
    count += state.title ? 1 : 0;
    count += state.purpose ? 1 : 0;
    count += state.target ? 1 : 0;
    count += state.slogan ? 1 : 0;

    setPercent((count / 4) * 100);
  }, [state.title, state.purpose, state.target, state.slogan]);

  const isValid = () => {
    return state.title;
  };

  return (
    <>
      <div>
        <div className="builder-brand flex gap-6 ">
          <div>
            <label>Website Dili</label>

            <Select
              size="large"
              value={state.language}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_LANGUAGE,
                  payload: { language: val },
                })
              }
            >
              {languageList.map((language) => (
                <Select.Option key={language.code} value={language.code}>
                  {language.nameTr} ({language.name})
                </Select.Option>
              ))}
            </Select>

            <label>Markanızın Adı</label>
            <Input
              size="large"
              value={state.title}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_TITLE,
                  payload: { title: val.target.value },
                })
              }
            />

            {state.link && (
              <>
                <p className="mb-6">
                  Geçiçi web adresiniz: <br /> {state.link}.{process.env.REACT_APP_RENDER_BASE}
                </p>
              </>
            )}

            <label>
              Web sitesi yapma amacınız
              <span> </span>
              <QuestionCircleOutlined onClick={() => setShowExplanationGoal(true)} />
            </label>

            <Input.TextArea
              size="large"
              placeholder="Satışlarımızı artırmak, Ürünlerimizi tanıtmak, İnternette bulunur hale gelmek"
              value={state.purpose || ''}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_PURPOSE,
                  payload: { purpose: val.target.value },
                })
              }
            />

            <label>
              Web sitenizle ulaşmak istediğiniz hedef kitle
              <span> </span>
              <QuestionCircleOutlined onClick={() => setShowExplanationTarget(true)} />
            </label>

            <Input.TextArea
              size="large"
              placeholder="25-35 arası kadınlar"
              value={state.target || ''}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_TARGET,
                  payload: { target: val.target.value },
                })
              }
            />

            <label>Sloganınız</label>
            <Input.TextArea
              size="large"
              placeholder="Sloganınız"
              value={state.slogan || ''}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_SLOGAN,
                  payload: { slogan: val.target.value },
                })
              }
            />
          </div>
          <div>
            <label>Adres</label>
            <Input.TextArea
              size="large"
              placeholder="Adres"
              value={state.contactAddress || ''}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_ADDRESS,
                  payload: { contactAddress: val.target.value },
                })
              }
            />

            <label>Email</label>
            <Input
              size="large"
              placeholder="Email Adresiniz"
              value={state.contactEmail || ''}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_EMAIL,
                  payload: { contactEmail: val.target.value },
                })
              }
            />

            <label>Telefon Numarası</label>
            <Input
              size="large"
              placeholder="Telefon Numarası"
              value={state.contactPhone || ''}
              onChange={(val) =>
                dispatch({
                  type: SET_BUILDER_PHONE,
                  payload: { contactPhone: val.target.value },
                })
              }
            />
          </div>
          <div>
            {socialMediaData.map((smd) => (
              <div key={smd.key}>
                <label>
                  {smd.text} <span style={{ float: 'right' }}>{smd.icon}</span>
                </label>
                <Input
                  size="large"
                  // @ts-ignore
                  value={state.socialMedia[smd.key] || ''}
                  onChange={(val) => {
                    const payload: any = {};
                    payload[smd.key] = val.target.value;
                    dispatch({
                      type: SET_BUILDER_SOCIAL_MEDIA,
                      payload,
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <Space>
          <Button onClick={() => onPrevious()}>Geri</Button>
          <Button type="primary" onClick={() => onComplete()} disabled={!isValid()}>
            Devam Et
          </Button>
        </Space>
      </div>

      <Drawer
        title="Web sitesi yapma amacınız"
        placement="right"
        onClose={() => setShowExplanationGoal(false)}
        open={showExplanationGoal}
        size="large"
      >
        <p className="mb-4">Web sitesi yapmanın birçok farklı amacı olabilir. İşte yaygın web sitesi yapma amaçlarından bazıları:</p>

        <ul className="mb-4">
          <li>
            <strong>Tanıtım ve Marka İmajı:</strong> Bir işletme, şirket, kurum veya birey, kendini ve faaliyetlerini tanıtmak, marka
            imajını oluşturmak ve güçlendirmek için web sitesi oluşturabilir. Web sitesi, potansiyel müşterilere ve ziyaretçilere işletme
            hakkında bilgi verir ve güvenilirlik sağlar.
          </li>

          <li>
            <strong>Ürün ve Hizmet Satışı:</strong> E-ticaret web siteleri, ürün ve hizmetlerin çevrimiçi olarak satışını gerçekleştirmek
            amacıyla kullanılır. Bu tür siteler, müşterilere kolay ve güvenli bir şekilde alışveriş yapma imkanı sunar.
          </li>

          <li>
            <strong>İletişim ve Müşteri Hizmetleri:</strong> Web siteleri, müşterilerle etkileşim kurmak ve onlara destek sağlamak için bir
            platform olarak kullanılabilir. İletişim formları, canlı sohbet, sıkça sorulan sorular (SSS) gibi araçlar, müşteri hizmetleri ve
            destek süreçlerini kolaylaştırır.
          </li>

          <li>
            <strong>Bilgi Paylaşımı ve Eğitim:</strong> Birçok kurum ve eğitim kuruluşu, web sitesi aracılığıyla bilgi ve içerik paylaşarak,
            eğitim ve bilgilendirme amaçlı kaynaklar sunar. Bloglar, makaleler, eğitim videoları ve rehberler, ziyaretçilere değerli
            içerikler sunar.
          </li>

          <li>
            <strong>Topluluk ve Sosyal Ağ:</strong> Web siteleri, belirli bir konu veya ilgi alanı etrafında bir topluluk oluşturmak ve
            insanları bir araya getirmek için kullanılabilir. Forumlar, blog yorumları ve sosyal medya entegrasyonları, etkileşim ve
            topluluk oluşturma açısından önemli rol oynar.
          </li>

          <li>
            <strong>Etkinlik ve Organizasyon Tanıtımı:</strong> Etkinlikler, konferanslar, fuarlar veya diğer organizasyonlar için web
            siteleri, katılımcılara ve ziyaretçilere etkinlikle ilgili bilgileri sağlar ve kayıtların yapılmasını kolaylaştırır.
          </li>

          <li>
            <strong>İstihdam ve İş Başvuruları:</strong> Şirketler, web sitelerini iş ilanlarını paylaşmak ve iş başvurularını almak için
            kullanabilir. Kariyer sayfaları, potansiyel çalışanlarla iletişim kurmak için etkili bir araçtır.
          </li>

          <li>
            <strong>Medya ve Eğlence:</strong> Medya şirketleri, eğlence içerik sağlayıcıları ve sanatçılar, web siteleri aracılığıyla
            kitlesine ulaşır ve medya içeriklerini sergiler.
          </li>
        </ul>

        <p>
          Bu sadece başlangıçta düşünülebilecek birkaç örnek. Web sitesi yapma amacı, kişisel ihtiyaçlar, ticari hedefler, organizasyonun
          misyonu ve diğer faktörlere bağlı olarak çeşitlilik gösterebilir.
        </p>
      </Drawer>

      <Drawer
        title="Web sitesi yapma amacınız"
        placement="right"
        onClose={() => setShowExplanationTarget(false)}
        open={showExplanationTarget}
        size="large"
      >
        <p>
          Bir web sitesiyle ulaşılmak istenen hedef kitleler, web sitesinin amacına, içeriğine ve hedeflerine bağlı olarak farklılık
          gösterebilir. İşte web sitesi ile ulaşılmak istenen hedef kitlelere örnekler:
        </p>

        <ul>
          <li>
            <strong>Potansiyel Müşteriler:</strong> E-ticaret siteleri veya işletme web siteleri, ürün veya hizmetlerini satın almak için
            potansiyel müşterilere ulaşmayı hedefler.
          </li>

          <li>
            <strong>Mevcut Müşteriler:</strong> İşletmeler, mevcut müşterileriyle iletişim kurmak, onlara destek sağlamak ve tekrar satışlar
            yapmak için web sitelerini kullanabilir.
          </li>

          <li>
            <strong>İlgili Endüstri Profesyonelleri:</strong> Bir şirketin, sektördeki diğer profesyonellerle işbirliği yapmak veya network
            kurmak amacıyla web sitesini hedef kitlesine ulaşmak için kullanabilir.
          </li>

          <li>
            <strong>Potansiyel İş Ortakları:</strong> İşbirliği yapmak veya yeni iş ortakları bulmak için web sitesi, hedef kitlesine
            ulaşmak için önemli bir platform olabilir.
          </li>

          <li>
            <strong>Eğitim ve Bilgi Arayanlar:</strong> Eğitim kurumları, blogcular veya danışmanlar, web sitelerini bilgi arayan insanlara
            eğitim materyalleri ve rehberlik sağlamak için kullanabilir.
          </li>

          <li>
            <strong>Basın ve Medya:</strong> Bir organizasyon, basın bültenleri, duyurular ve medya iletişimi için web sitesini kullanarak
            haber ve basın organlarına ulaşmayı hedefleyebilir.
          </li>

          <li>
            <strong>Topluluk ve Hayranlar:</strong> Sanatçılar, yazarlar veya diğer topluluklar, web sitelerini hayranlarıyla etkileşim
            kurmak, onlarla paylaşımlar yapmak ve etkileşimi teşvik etmek için kullanabilir.
          </li>

          <li>
            <strong>Araştırmacılar ve Akademisyenler:</strong> Akademik kurumlar, araştırmacıları ve akademisyenleri, çalışmalarını ve
            araştırmalarını paylaşmak ve yayınlamak için web sitesini hedef kitlesi olarak düşünebilir.
          </li>

          <li>
            <strong>Hükümet ve Kamu:</strong> Hükümet kurumları veya kamu hizmeti sunan organizasyonlar, web sitelerini vatandaşlara hizmet
            vermek, bilgi sağlamak ve iletişim kurmak için kullanabilir.
          </li>
        </ul>
        <p>
          Hedef kitleler, web sitesinin amacına ve içeriğine göre çeşitlilik gösterebilir ve farklı siteler farklı kitlelere hitap edebilir.
          Başarılı bir web sitesi, hedef kitleleri iyi tanımak, onların ihtiyaçlarını anlamak ve içerik ile tasarımı buna göre düzenlemekle
          elde edilir.
        </p>
      </Drawer>
    </>
  );
}
