import { IAction } from '../../Type';
import { LOAD_INITIAL } from '../actionTypes';
import { IProject } from '../../Type';

type IProjectReducer = {
  projectList: IProject[];
};

const initialState: IProjectReducer = {
  projectList: [],
};

export default function reducer(state = initialState, { type, payload }: IAction) {
  switch (type) {
    case LOAD_INITIAL:
      return {
        ...state,
        projectList: payload.projectList as IProject[],
      };
    default:
      return state;
  }
}
