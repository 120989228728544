import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import { IError, IContentProductListOptions, IStatus, IContentProductUpdateData, IContentProductCreateData } from '../Type';
import { IContentProduct } from '../Type';

export const loadContentProductList = async (
  options: IContentProductListOptions,
): Promise<{
  result: IContentProduct[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentProductList($options: ContentProductListOptions) {
          contentProductList(options: $options) {
            contentProductId
            title
            detail
            link
            slug
            priceMin
            priceMax
            type
            variantList {
              variantId
              price
              priceOriginal
              stock
            }
            imageList {
              imageId
              image {
                imageId
                path
              }
            }
            seoTitle
            seoDescription
            seoImage
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        options,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentProductList,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: [],
  };
};

export const loadContentProduct = async (
  contentProductId: string,
): Promise<{
  result?: IContentProduct;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentProduct($contentProductId: ID!) {
          contentProduct(contentProductId: $contentProductId) {
            contentProductId
            title
            detail
            link
            variantList {
              variantId
              stock
              priceOriginal
              price
            }
            seoTitle
            seoDescription
            seoImage
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        contentProductId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentProduct,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentProductCreate = async (
  data: IContentProductCreateData,
): Promise<{
  result?: IContentProduct;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentProductCreate($data: ContentProductCreateData!) {
          contentProductCreate(data: $data) {
            contentProductId
            title
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentProductCreate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentProductUpdate = async (
  contentProductId: string,
  data: IContentProductUpdateData,
): Promise<{
  result?: IContentProduct;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentProductUpdate($contentProductId: ID!, $data: ContentProductUpdateData!) {
          contentProductUpdate(contentProductId: $contentProductId, data: $data) {
            contentProductId
            title
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        contentProductId,
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentProductUpdate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
//
// export const contentProductActivate = async (
//   contentProductId: string,
// ): Promise<{
//   result?: IStatus;
//   errors?: IError;
// }> => {
//   try {
//     const result = await client.mutate({
//       mutation: gql`
//         mutation contentProductActivate($contentProductId: ID!) {
//           contentProductActivate(contentProductId: $contentProductId) {
//             message
//             status
//           }
//         }
//       `,
//       variables: {
//         contentProductId,
//       },
//       errorPolicy: 'all',
//       fetchPolicy: 'no-cache',
//     });
//
//     return {
//       result: result.data.contentProductActivate,
//       errors: handleError(result.errors),
//     };
//   } catch (e) {
//     notification.error({
//       message: 'Hata',
//       description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
//     });
//   }
//
//   return {
//     result: undefined,
//   };
// };
//
// export const contentProductDeactivate = async (
//   contentProductId: string,
// ): Promise<{
//   result?: IStatus;
//   errors?: IError;
// }> => {
//   try {
//     const result = await client.mutate({
//       mutation: gql`
//         mutation contentProductDeactivate($contentProductId: ID!) {
//           contentProductDeactivate(contentProductId: $contentProductId) {
//             message
//             status
//           }
//         }
//       `,
//       variables: {
//         contentProductId,
//       },
//       errorPolicy: 'all',
//       fetchPolicy: 'no-cache',
//     });
//
//     return {
//       result: result.data.contentProductDeactivate,
//       errors: handleError(result.errors),
//     };
//   } catch (e) {
//     notification.error({
//       message: 'Hata',
//       description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
//     });
//   }
//
//   return {
//     result: undefined,
//   };
// };
//

export const contentProductDelete = async (
  contentProductId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentProductDelete($contentProductId: ID!) {
          contentProductDelete(contentProductId: $contentProductId) {
            message
            status
          }
        }
      `,
      variables: {
        contentProductId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentProductDelete,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
