import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  html: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Görsel İçerik Editörü',
  description: '"What You See Is What You Get" prensibine göre çalışan, içerik oluşturma ve düzenleme için kullanılan bir editör bloğu.',
  type: 'wysiwyg-1',
  code: 'WYG1',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'html'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      src: { type: 'string', title: 'Iframe Linki', default: '' },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for content section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || page.title,
      description: cTitleDescription?.result?.description || '',
      html: '',
    };
  },
};

export default config;
