import React from 'react';
import { Form, notification, Button } from 'antd';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IPage, IProject } from '../../service/TypeGraphql';
import { pageUpdateMeta } from '../../service/api/Page';
import WebsiteSettingForm from '../../module/project-page/component/WebsiteSettingForm';

interface EditorPageSettingGeneralProps {
  onSubmitSuccess: any;
  project: IProject;
  page: IPage;
}

export default function EditorPageSettingGeneral({ onSubmitSuccess, project, page }: EditorPageSettingGeneralProps) {
  const initialValues = {
    title: page.title,
    path: page.path?.replace('/', ''),
    seoTitle: page.seoTitle,
    seoDescription: page.seoDescription,
  };

  const schema = yup.object().shape({
    title: yup.string().required('Sayfa başlığı girilmelidir').max(256, 'Sayfa başlığı en fazla 256 karakter uzunluğunda olabilir'),
    path: yup.string().max(256, 'Sayfa linki en fazla 256 karakter uzunluğunda olabilir'),
    seoTitle: yup.string().required('Seo başlığı girilmelidir').max(256, 'Seo başlığı en fazla 256 karakter uzunluğunda olabilir'),
    seoDescription: yup
      .string()
      .required('Seo açıklaması girilmelidir')
      .max(512, 'Seo açıklaması en fazla 512 karakter uzunluğunda olabilir'),
  });

  const onSubmit = async (values: any) => {
    console.log(values);

    await pageUpdateMeta(page.pageId, {
      title: values.title,
      path: values.path,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
    });

    onSubmitSuccess({
      title: values.title,
      path: values.path,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
    });

    notification.success({
      message: 'Başarı',
      description: 'Sayfa başarıyla kaydedildi. ',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange
      validateOnBlur
      onSubmit={async (values) => {
        await onSubmit(values);
        return true;
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form layout="vertical">
          <WebsiteSettingForm project={project} />
          <Button disabled={isSubmitting} onClick={() => handleSubmit()}>
            Kaydet
          </Button>
        </Form>
      )}
    </Formik>
  );
}
