import React from 'react';
import { AutoComplete, Button } from 'antd';
import { builderTypes } from '../service/Data';
import { SET_BUILDER_TYPE } from '../service/actionTypes';
import { IProjectCreateData } from '../../../service/TypeGraphql';
import './BuilderType.scss';

interface BuilderTypeProps {
  state: IProjectCreateData;
  dispatch: any;
  onComplete: any;
  onPrevious?: any;
  setPercent: any;
}

export default function BuilderType({ state, dispatch, onComplete, setPercent }: BuilderTypeProps) {
  return (
    <div
      className="builder-type"
      style={{
        height: 'calc(100vh - 143px)',
      }}
    >
      <label>Website Türünü Seçin</label>
      <div>
        <AutoComplete
          options={builderTypes}
          style={{ width: '100%', marginBottom: 16 }}
          onChange={async (type) => {
            dispatch({
              type: SET_BUILDER_TYPE,
              payload: { type },
            });
            setPercent(100);
          }}
          // onSelect={async (type) => {
          //   dispatch({
          //     type: SET_BUILDER_TYPE,
          //     payload: { type },
          //   });
          //   setPercent(100);
          // }}
          filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          placeholder="Websitenizin ne hakkında olduğunu girin"
          size="large"
          value={state.type}
          allowClear
        />
      </div>
      {/*
      <Select
        className="builder-type-selector"
        showSearch
        placeholder="Websitenizin ne hakkında olduğunu seçin"
        optionFilterProp="children"
        onKeyDown={() => {
          setPercent(20);
        }}
        onChange={async (type) => {
          dispatch({
            type: SET_BUILDER_TYPE,
            payload: { type },
          });
        }}
        filterOption={(input, option) =>
          (option?.value ?? '')
            .toLocaleLowerCase()
            .includes(input.toLocaleLowerCase())
        }
        options={builderTypes}
        size="large"
        value={state.type}
      />
      */}
      <Button type="primary" disabled={!state.type} onClick={() => onComplete()}>
        Devam Et
      </Button>
    </div>
  );
}
