import * as React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Menu, Button } from 'antd';
import { DollarOutlined, QuestionOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import keycloak from '../service/Keycloak';

const items = [
  {
    key: '/ucretlendirme',
    label: <Link to="/ucretlendirme">Ücretlendirme</Link>,
    icon: <DollarOutlined />,
  },
  {
    key: '/yardim',
    label: <Link to="/yardim">Yardım</Link>,
    icon: <QuestionOutlined />,
  },
];

export default function Layout() {
  const location = useLocation();

  return (
    <>
      <header>
        <Link to="/" className="logo">
          <img src="/img/logo/svg/doneweb-high-resolution-logo-white-transparent.svg" alt="DoneWeb" />
        </Link>
        <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname]} items={items} style={{ flex: 1 }} />
        <Button onClick={() => keycloak.logout()} icon={<LogoutOutlined />}>
          {keycloak?.profile?.firstName} {keycloak?.profile?.lastName}
        </Button>
      </header>
      <main>
        <Outlet />
      </main>
      <footer style={{ textAlign: 'center', background: '#202020', color: '#fff' }}>
        <p>© DoneWeb 2023</p>
        <p>
          Herhangi bir sorunuzda lütfen bize yazın
          <span> </span>
          <a href="mailto:yardim@pixelperfect.tr">yardim@pixelperfect.tr</a>
        </p>
        <p>
          <Link to="/kullanim-sartlari">Kullanım Şartları</Link>
          <span> ve </span>
          <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
        </p>
      </footer>
    </>
  );
}
