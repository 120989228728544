import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import { IError, ITemplateListFilters } from '../Type';
import { ITemplate } from '../Type';

export const loadTemplateList = async (
  filter: ITemplateListFilters,
): Promise<{
  result: ITemplate[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query templates($options: TemplateListOptions) {
          templateList(options: $options) {
            templateId
            title
            description
            cover
            type
            blockList {
              type
              sort
              props
              style
            }
          }
        }
      `,
      variables: {
        options: {
          filter,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.templateList,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: [],
  };
};

export const loadTemplate = async (
  templateId: string,
): Promise<{
  result?: ITemplate;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query template($templateId: ID!) {
          template(templateId: $templateId) {
            templateId
            title
            isActive
            isAnyStudyCreated
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        templateId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.template,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
