import React from 'react';
import './EditorPageSettingSeo.scss';
import { IPage, IProject } from '../../service/TypeGraphql';

interface EditorPageSettingSeoProps {
  onSubmitSuccess: any;
  project: IProject;
  page: IPage;
}

export default function EditorPageSettingSeo({ onSubmitSuccess, project, page }: EditorPageSettingSeoProps) {
  console.log(onSubmitSuccess);

  return (
    <>
      <h2 className="mb-2 font-title text-2xl">Arama sonuçları önizlemesi</h2>
      <p className="mb-4">
        Varsayılan olarak, Genel sekmesinde belirtilen başlık ve açıklama, arama sonuçlarının önizlemesi için kullanılır.
      </p>

      <div className="seo-preview mb-6">
        <div className="seo-title">{page.seoTitle}</div>
        <div className="seo-link">
          {project.linkSchema}
          {project.link}
          {page.path}
        </div>
        <div className="seo-description">{page.seoDescription}</div>
      </div>
    </>
  );
}
