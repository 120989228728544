import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationTR from './translate/tr.json';
import translationEN from './translate/en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: translationEN,
    },
    tr: {
      translations: translationTR,
    },
  },
  fallbackLng: 'tr',
  debug: false,

  ns: ['translations'],
  defaultNS: 'translations',

  supportedLngs: ['en', 'tr'],
  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
