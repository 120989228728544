import { IAction } from '../../Type';
import { LOAD_INITIAL } from '../actionTypes';
import { IWorkspace } from '../../Type';

type IWorkspaceReducer = {
  workspace?: IWorkspace;
};

const initialState: IWorkspaceReducer = {
  workspace: undefined,
};

export default function reducer(state = initialState, { type, payload }: IAction) {
  switch (type) {
    case LOAD_INITIAL:
      return {
        ...state,
        workspace: payload.workspace as IWorkspace,
      };
    default:
      return state;
  }
}
