import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTimeline, contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  timeline: {
    children: string;
    color?: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Şirket Zaman Çizelgesi',
  description: 'Şirketin veya projenin önemli tarihlerinin ve gelişmelerinin kronolojik sırayla sunulduğu zaman çizelgesi.',
  type: 'timeline-1',
  code: 'TML',
  category: ['timeline'],
  suitable: ['main'],
  schema: {
    definitions: {
      colors: {
        enum: ['red', 'blue', 'green'],
      },
    },
    type: 'object',
    required: ['title', 'timeline'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      timeline: {
        type: 'array',
        title: 'Zaman Tüneli',
        items: {
          type: 'object',
          required: ['children'],
          title: '',
          properties: {
            children: {
              type: 'string',
              title: 'Başlık',
            },
            // color: {
            //   type: 'string',
            //   title: 'Renk',
            //   $ref: '#/definitions/colors',
            // },
          },
        },
      },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for hero section in page: "${page.title}"`,
    );

    const cTimeline = await contentTimeline(
      project.projectId,
      page.pageId,
      `Write several titles for history timeline in page: "${page.title}"`,
    );

    const timeline: EProps['timeline'] =
      cTimeline.result?.map((t) => ({
        children: t?.title || '',
        color: undefined,
      })) || [];

    return {
      title: cTitleDescription?.result?.title || 'Geçmişten Bu Güne',
      description: cTitleDescription?.result?.description || 'Geçmişten Bu Güne Açıklayalım',
      timeline,
    };
  },
};

export default config;
