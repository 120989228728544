import * as React from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';

export default function LayoutProject() {
  const { projectId } = useParams();

  return (
    <>
      <header>
        <Link to="/" className="logo">
          <img src="/img/logo/svg/doneweb-high-resolution-logo-white-transparent.svg" alt="DoneWeb" />
        </Link>
        {/*
        <Link to={`/project/${projectId}/ayarlar/domain`}>
          <Button icon={<SettingOutlined />}> Domain Ayarları</Button>
        </Link>
        */}
        <span style={{ flex: 1 }} />
        <Link to={`/project/${projectId}/ayarlar`}>
          <Button icon={<SettingOutlined />}> Site Ayarları</Button>
        </Link>
        <Link to={`/project/${projectId}/sayfa-olustur`} style={{ margin: '0 0 0 16px' }}>
          <Button icon={<PlusOutlined />} type="primary">
            Yeni Sayfa Oluştur
          </Button>
        </Link>
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <p>© DoneWeb 2023</p>
        <p>
          Herhangi bir sorunuzda lütfen bize yazın
          <span> </span>
          <a href="mailto:yardim@pixelperfect.tr">yardim@pixelperfect.tr</a>
        </p>
      </footer>
    </>
  );
}
