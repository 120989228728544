import { EditorConfigW } from '../../../../service/Type';

interface Stat {
  text: string;
  value: string;
}

export interface EProps {
  stats: Stat[];
}

const config: EditorConfigW<EProps> = {
  title: 'Başarı İstatistikleri',
  description: 'Şirketin başarıları, kullanıcı sayısı gibi önemli verilerin ve istatistiklerin grafiklerle sunulduğu bilgilendirici bölüm.',
  type: 'statistic-1',
  code: 'STAT-1',
  category: ['statistic'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['stats'],
    properties: {
      stats: {
        type: 'array',
        title: 'İstatistikler',
        items: {
          type: 'object',
          required: ['text', 'value'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            value: {
              type: 'string',
              title: 'Değer',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      title: 'Başlık',
      description: 'İndirim',
      stats: [
        {
          text: 'İndirmeler',
          value: '2.7K',
        },
        {
          text: 'Kullanıcılar',
          value: '22.7K',
        },
        {
          text: 'Dosyalar',
          value: '74',
        },
        {
          text: 'Yerler',
          value: '221.7K',
        },
      ],
    };
  },
};

export default config;
