import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { CollapseProps } from 'antd';
import { contentFaq, contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  texts: CollapseProps['items'];
}

const config: EditorConfigW<EProps> = {
  title: 'Sıkça Sorulan Sorular Bölümü',
  description: 'Sıkça sorulan soruların ve cevaplarının listelendiği, kullanıcıların bilgi edinebileceği bir bölüm.',
  type: 'collapse-1',
  code: 'CLLPS1',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      texts: {
        type: 'array',
        title: 'İçerikler',
        items: {
          type: 'object',
          required: ['label', 'children'],
          title: '',
          properties: {
            label: {
              type: 'string',
              title: 'Başlık',
            },
            children: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      items: {
        children: {
          'ui:widget': 'textarea',
        },
      },
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for faq section in page: "${page.title}"`,
    );

    const cFaq = await contentFaq(
      project.projectId,
      page.pageId,
      `Write several title and description for faq section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || 'Sıkça Sorulan Sorular',
      description: cTitleDescription?.result?.description || 'Size yardım için buradayız. ',
      texts: (cFaq.result || []).map((result) => ({
        label: result.title,
        children: result.description,
      })),
    };
  },
};

export default config;
