export function createSlug(str: string) {
  return String(str)
    .replace(/Ğ/gim, 'g')
    .replace(/Ü/gim, 'u')
    .replace(/Ş/gim, 's')
    .replace(/I/gim, 'i')
    .replace(/İ/gim, 'i')
    .replace(/Ö/gim, 'o')
    .replace(/Ç/gim, 'c')
    .replace(/ğ/gim, 'g')
    .replace(/ü/gim, 'u')
    .replace(/ş/gim, 's')
    .replace(/ı/gim, 'i')
    .replace(/ö/gim, 'o')
    .replace(/ç/gim, 'c')
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}
