import React from 'react';
import {
  FaAmazon,
  FaAndroid,
  FaApple,
  FaDeviantart,
  FaDiscord,
  FaFacebook,
  FaFoursquare,
  FaInstagram,
  FaLinkedin,
  FaMedium,
  FaPinterest,
  FaQuora,
  FaReddit,
  FaSkype,
  FaSlack,
  FaSnapchat,
  FaSpotify,
  FaTiktok,
  FaTumblr,
  FaTwitch,
  FaVimeo,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { FaThreads, FaX } from 'react-icons/fa6';
import { IProject } from '../../../../service/TypeGraphql';

interface SocialMedia {
  key: string;
  text: string;
  icon: React.ReactElement;
  color: string;
}

interface SocialMediaLink extends SocialMedia {
  link: string;
}

export const socialMediaData: SocialMedia[] = [
  { key: 'urlYoutube', text: 'YouTube', icon: <FaYoutube />, color: '#ff0000' },
  { key: 'urlFacebook', text: 'Facebook', icon: <FaFacebook />, color: '#1877f2' },
  { key: 'urlInstagram', text: 'Instagram', icon: <FaInstagram />, color: '#c32aa3' },
  { key: 'urlX', text: 'X', icon: <FaX />, color: '#000' },
  { key: 'urlThreads', text: 'Threads', icon: <FaThreads />, color: '#1877f2' },
  { key: 'urlWhatsapp', text: 'WhatsApp', icon: <FaWhatsapp />, color: '#25d366' },
  { key: 'urlLinkedin', text: 'Linkedin', icon: <FaLinkedin />, color: '#0a66c2' },
  { key: 'urlPinterest', text: 'Pinterest', icon: <FaPinterest />, color: '#bd081c' },
  { key: 'urlTiktok', text: 'TikTok', icon: <FaTiktok />, color: '#010101' },
  { key: 'urlTwitch', text: 'Twitch', icon: <FaTwitch />, color: '#9146ff' },
  { key: 'urlTumblr', text: 'Tumblr', icon: <FaTumblr />, color: '#35465d' },
  { key: 'urlSnapchat', text: 'Snapchat', icon: <FaSnapchat />, color: '#fffc00' },
  { key: 'urlReddit', text: 'Reddit', icon: <FaReddit />, color: '#ff4500' },
  { key: 'urlDiscord', text: 'Discord', icon: <FaDiscord />, color: '#5865f2' },
  { key: 'urlSpotify', text: 'Spotify', icon: <FaSpotify />, color: '#1ed760' },
  { key: 'urlApple', text: 'Apple', icon: <FaApple />, color: '#a6b1b7' },
  { key: 'urlAmazon', text: 'Amazon', icon: <FaAmazon />, color: '#ff9900' },
  { key: 'urlFoursquare', text: 'Foursquare', icon: <FaFoursquare />, color: '#f94877' },
  { key: 'urlMedium', text: 'Medium', icon: <FaMedium />, color: '#02b875' },
  { key: 'urlVimeo', text: 'Vimeo', icon: <FaVimeo />, color: '#1ab7ea' },
  { key: 'urlSkype', text: 'Skype', icon: <FaSkype />, color: '#00aff0' },
  { key: 'urlAndroid', text: 'Android', icon: <FaAndroid />, color: '#a4c639' },
  { key: 'urlQuora', text: 'Quora', icon: <FaQuora />, color: '#aa2200' },
  { key: 'urlSlack', text: 'Slack', icon: <FaSlack />, color: '#4a154b' },
  { key: 'urlDeviantArt', text: 'DeviantArt', icon: <FaDeviantart />, color: '#05cc47' },
  {
    key: 'urlKariyerNet',
    text: 'Kariyer Net',
    icon: (
      <img
        src="/img/vendor/logo-kariyer.png"
        alt="Kariyer.net"
        style={{
          height: '18px',
          position: 'relative',
        }}
      />
    ),
    color: '#8316b5',
  },
];

export function getSocialMediaLinks(project: IProject) {
  const links: SocialMediaLink[] = [];

  socialMediaData.forEach((smd) => {
    // @ts-ignore
    if (project.socialMedia[smd.key]) {
      links.push({
        ...smd,
        // @ts-ignore
        link: project.socialMedia[smd.key],
      });
    }
  });

  return links;
}

export function getSocialMediaLinksFromLinks(linksSocialMedia: any) {
  const links: SocialMediaLink[] = [];

  socialMediaData.forEach((smd) => {
    // @ts-ignore
    if (linksSocialMedia[smd.key]) {
      links.push({
        ...smd,
        // @ts-ignore
        link: linksSocialMedia[smd.key],
      });
    }
  });

  return links;
}
