import React, { useEffect, useState } from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { Button, Modal } from 'antd';
import FormRJSF from '@rjsf/antd';
import FormValidator from '@rjsf/validator-ajv8';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import QuillWidget from '../../../component/editor/component-rjfs/QuillWidget';
import BackgroundWidget from '../../../component/editor/component-rjfs/BackgroundWidget';
import ImageUploadWidget from '../../../component/editor/component-rjfs/ImageUploadWidget';
import MultiUploadWidget from '../../../component/editor/component-rjfs/MultiUploadWidget';
import { loadContentPost, contentPostUpdate } from '../../../service/api/ContentPost';
import { IContentPost, IContentPostUpdateData } from '../../../service/TypeGraphql';

interface PostEditProps {
  contentPostId: string;
  onUpdate: any;
  onCancel: any;
}

export default function PostEdit({ contentPostId, onUpdate, onCancel }: PostEditProps) {
  const [contentPost, setContentPost] = useState<IContentPost>();

  const load = async () => {
    const contentPostResult = await loadContentPost(contentPostId);
    if (contentPostResult.result) {
      setContentPost(contentPostResult.result);
    }
  };

  const update = async (data: IContentPostUpdateData) => {
    await contentPostUpdate(contentPostId, {
      title: data.title,
      content: data.content,
      seoDescription: data.seoDescription,
      seoTitle: data.seoTitle,
      seoImage: data.seoImage,
    });
    onUpdate();
  };

  useEffect(() => {
    load();
  }, [contentPostId]);

  const formData = {
    ...contentPost,
  };

  const schema: RJSFSchema = {
    type: 'object',
    required: ['title', 'content', 'seoImage'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      content: { type: 'string', title: 'Yazı', default: '' },
      seoImage: { type: 'string', title: 'SEO Görsel', default: '' },
      seoTitle: { type: 'string', title: 'SEO Başlık', default: '' },
      seoDescription: { type: 'string', title: 'SEO Açıklama', default: '' },
      categoryId: { type: 'string', title: 'Kategori', default: '' },
    },
  };

  const schemaUI: UiSchema = {
    content: {
      'ui:widget': 'quill',
    },
    seoImage: {
      'ui:widget': 'imageUpload',
      crop: false,
    },
  };

  const onSubmit = async (data: any) => {
    await update(data.formData);
  };

  const widgets = {
    quill: QuillWidget,
    background: BackgroundWidget,
    imageUpload: ImageUploadWidget,
    multiUpload: MultiUploadWidget,
  };

  return (
    <Modal open footer="" onCancel={onCancel} width="80%" title={`Yazı Düzenle #${contentPostId}`}>
      <StyleProvider>
        <FormRJSF
          schema={schema}
          uiSchema={schemaUI}
          validator={FormValidator}
          formData={formData}
          onSubmit={onSubmit}
          action="test"
          widgets={widgets}
        >
          <React.Fragment />
          <div className="">
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </div>
        </FormRJSF>
      </StyleProvider>
    </Modal>
  );
}
