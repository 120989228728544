import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  image: string;
  texts: {
    title: string;
    subtitle?: string;
    description: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Görsel Slayt Gösterisi',
  description: 'Görsellerin veya içeriklerin otomatik veya kullanıcı etkileşimiyle değiştiği, dinamik bir görsel slayt gösterisi.',
  type: 'slider-content-1',
  code: 'SC1',
  category: ['slider-content'],
  suitable: ['main'],
  schema: {
    title: '',
    type: 'object',
    required: ['image', 'texts'],
    properties: {
      image: {
        type: 'string',
        title: 'Görsel',
      },
      texts: {
        type: 'array',
        title: 'Mesajlar',
        items: {
          type: 'object',
          required: ['title', 'description'],
          title: '',
          properties: {
            title: {
              type: 'string',
              title: 'Başlık',
            },
            subtitle: {
              type: 'string',
              title: 'Alt Başlık',
            },
            description: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    images: {
      'ui:options': {
        accept: '.png,.jpg,.jpeg',
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      image: '/img/slider/slide-1.jpg',
      texts: [
        {
          title: 'Slide 1',
          subtitle: 'Sub Title',
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla
laoreet justo vitae porttitor porttitor. Suspendisse in sem justo.
Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod.
Aliquam hendrerit lorem at elit facilisis rutrum. Ut at
ullamcorper velit. Nulla ligula nisi, imperdiet ut lacinia nec,
tincidunt ut libero. Aenean feugiat non eros quis feugiat.`,
        },
        {
          title: 'Slide 2',
          subtitle: 'Sub Title',
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla
laoreet justo vitae porttitor porttitor. Suspendisse in sem justo.
Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod.
Aliquam hendrerit lorem at elit facilisis rutrum. Ut at
ullamcorper velit. Nulla ligula nisi, imperdiet ut lacinia nec,
tincidunt ut libero. Aenean feugiat non eros quis feugiat.`,
        },
      ],
    };
  },
};

export default config;
