import React from 'react';
import { useTheme } from '../ThemeProvider';

interface ComponentStyleProps {
  style: any;
  children: any;
}

export default function ComponentStyle({ style, children }: ComponentStyleProps) {
  const { theme } = useTheme();
  const styleWrapper =
    theme === 'light'
      ? {
          background: style?.background || '#fff',
          color: style?.color || '#000',
        }
      : {};

  return (
    <div className="bg-white dark:bg-slate-800 dark:text-white" style={styleWrapper}>
      <div className={`${style?.container === 'full' ? '' : 'container mx-auto'}`}>{children}</div>
    </div>
  );
}
