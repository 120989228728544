import { EditorConfigW, IContentPost } from '../../../../service/Type';

export interface EProps {
  content: IContentPost;
}

const config: EditorConfigW<EProps> = {
  title: 'Blog Yazısı Detayı',
  description: 'Seçilen blog yazısının detaylarını, içeriğini ve ek bilgilerini gösteren sayfa düzeni.',
  type: 'blog-detail-1',
  code: 'BD1',
  category: ['blog-detail'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      texts: {
        type: 'array',
        title: 'Sekme',
        items: {
          type: 'object',
          required: ['label', 'children'],
          title: '',
          properties: {
            label: {
              type: 'string',
              title: 'Başlık',
            },
            children: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      content: {
        contentPostId: '123',
        title: 'Blog Title',
        content: `
          <p>Testing details</p>
        `,
        category: {
          categoryId: '123',
          title: 'Haberler',
          slug: 'haberler',
        },
        seoDescription: '',
        seoImage: 'https://place-hold.it/1920x1080',
        seoTitle: '',
        slug: 'blog-title',
        timestampCreate: new Date(),
        timestampPublish: new Date(),
        timestampUpdate: new Date(),
      },
    };
  },
};

export default config;
