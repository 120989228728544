import React from 'react';

interface CurrencyProps {
  price: number;
  decimal?: number;
}

export default function Currency({ price, decimal }: CurrencyProps) {
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: decimal || 0,
  });

  const priceFormatted = formatter.format(price);

  return <>{priceFormatted}</>;
}
