import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  title: string;
  description: string;
  link: string;
  text: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Çağrı-Aksiyon Bloğu',
  description: 'Kullanıcıları belirli bir eyleme yönlendirmek için tasarlanmış çağrı-aksiyon (CTA) bloğu.',
  type: 'call-to-action-1',
  code: 'CTA-1',
  category: ['call-to-action'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'description'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      link: { type: 'string', title: 'Link', default: '' },
      text: { type: 'string', title: 'Buton Text', default: '' },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
  },
  styleDefault: {
    container: 'compact',
    background: 'rgb(37 99 235)',
  },
  propsDefault: async () => {
    return {
      title: 'Bugün başlayın',
      description:
        'Etkileyici bir başlık yazmak için net, dikkat çekici, değer vaadi sunan, hedef kitleyi düşünen, SEO odaklı, özgün ve etkileşimi teşvik eden bir yaklaşım benimseyin.',
      link: '/iletisim',
      text: 'İletişime Geçin',
    };
  },
};

export default config;
