import { EditorConfigW } from '../../../../service/Type';

export interface EProps {}

const config: EditorConfigW<EProps> = {
  title: 'Sayfa Üst Bilgisi',
  description: 'Web sayfasının üst kısmında yer alan, logo, navigasyon menüsü ve diğer önemli bağlantıların bulunduğu bölüm.',
  type: 'header-3',
  code: 'HDR3',
  category: ['header'],
  suitable: ['header'],
  schema: {
    type: 'object',
    required: ['links', 'logoHeight'],
    properties: {
      links: {
        type: 'array',
        title: 'Bağlantılar',
        items: {
          type: 'object',
          required: ['text', 'link'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            link: {
              type: 'string',
              title: 'Bağlantı',
            },
          },
        },
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {};
  },
};

export default config;
