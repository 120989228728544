import React from 'react';
import Breadcrumb from '../component/Breadcrumb';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd';

export default function Help() {
  const text = `
        A dog is a type of domesticated animal.
        Known for its loyalty and faithfulness,
        it can be found as a welcome guest in many households across the world.
        `;

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'This is panel header 1',
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: 'This is panel header 2',
      children: <p>{text}</p>,
    },
    {
      key: '3',
      label: 'This is panel header 3',
      children: <p>{text}</p>,
    },
  ];

  return (
    <div className="page">
      <Breadcrumb
        items={[
          {
            title: 'Yardım',
          },
        ]}
      />

      <div className="page-title">
        <h1>Yardım</h1>
        <span style={{ flex: 1 }} />
      </div>

      <Collapse items={items} defaultActiveKey={['1']} />
    </div>
  );
}
