import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import { ColorPicker } from 'antd';
import './BackgroundWidget.scss';

export default function BackgroundWidget(props: WidgetProps) {
  const patterns = [
    {
      path: '/img/pattern/pack-1',
      names: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png', '9.png', '10.png'],
    },
    {
      path: '/img/pattern/pack-2',
      names: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
        'flower-pattern.jpg',
        'leaves-pattern.jpg',
        'pumpkin-pattern2.jpg',
      ],
    },
    {
      path: '/img/pattern/pack-3',
      names: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png', '9.png', '10.png'],
    },
    { path: '/img/pattern/pack-4', names: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png'] },
    {
      path: '/img/pattern/pack-5',
      names: [
        'Artboard 1.jpg',
        'Artboard 2.jpg',
        'Artboard 3.jpg',
        'Artboard 4.jpg',
        'Artboard 5.jpg',
        'Artboard 6.jpg',
        'Artboard 7.jpg',
        'Artboard 8.jpg',
        'Artboard 9.jpg',
        'Artboard 10.jpg',
        'Artboard 11.jpg',
      ],
    },
    { path: '/img/pattern/pack-6', names: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'] },
  ];

  const colors = [
    { value: 'bg-slate-50', color: 'rgb(248 250 252)' },
    { value: 'bg-slate-100', color: 'rgb(241 245 249)' },
    { value: 'bg-slate-200', color: 'rgb(226 232 240)' },
    { value: 'bg-slate-300', color: 'rgb(203 213 225)' },
    { value: 'bg-slate-400', color: 'rgb(148 163 184)' },
    { value: 'bg-slate-500', color: 'rgb(100 116 139)' },
    { value: 'bg-slate-600', color: 'rgb(71 85 105)' },
    { value: 'bg-slate-700', color: 'rgb(51 65 85)' },
    { value: 'bg-slate-800', color: 'rgb(30 41 59)' },
    { value: 'bg-slate-900', color: 'rgb(15 23 42)' },
    { value: 'bg-slate-950', color: 'rgb(2 6 23)' },
    { value: 'bg-gray-50', color: 'rgb(249 250 251)' },
    { value: 'bg-gray-100', color: 'rgb(243 244 246)' },
    { value: 'bg-gray-200', color: 'rgb(229 231 235)' },
    { value: 'bg-gray-300', color: 'rgb(209 213 219)' },
    { value: 'bg-gray-400', color: 'rgb(156 163 175)' },
    { value: 'bg-gray-500', color: 'rgb(107 114 128)' },
    { value: 'bg-gray-600', color: 'rgb(75 85 99)' },
    { value: 'bg-gray-700', color: 'rgb(55 65 81)' },
    { value: 'bg-gray-800', color: 'rgb(31 41 55)' },
    { value: 'bg-gray-900', color: 'rgb(17 24 39)' },
    { value: 'bg-gray-950', color: 'rgb(3 7 18)' },
    { value: 'bg-zinc-50', color: 'rgb(250 250 250)' },
    { value: 'bg-zinc-100', color: 'rgb(244 244 245)' },
    { value: 'bg-zinc-200', color: 'rgb(228 228 231)' },
    { value: 'bg-zinc-300', color: 'rgb(212 212 216)' },
    { value: 'bg-zinc-400', color: 'rgb(161 161 170)' },
    { value: 'bg-zinc-500', color: 'rgb(113 113 122)' },
    { value: 'bg-zinc-600', color: 'rgb(82 82 91)' },
    { value: 'bg-zinc-700', color: 'rgb(63 63 70)' },
    { value: 'bg-zinc-800', color: 'rgb(39 39 42)' },
    { value: 'bg-zinc-900', color: 'rgb(24 24 27)' },
    { value: 'bg-zinc-950', color: 'rgb(9 9 11)' },
    { value: 'bg-neutral-50', color: 'rgb(250 250 250)' },
    { value: 'bg-neutral-100', color: 'rgb(245 245 245)' },
    { value: 'bg-neutral-200', color: 'rgb(229 229 229)' },
    { value: 'bg-neutral-300', color: 'rgb(212 212 212)' },
    { value: 'bg-neutral-400', color: 'rgb(163 163 163)' },
    { value: 'bg-neutral-500', color: 'rgb(115 115 115)' },
    { value: 'bg-neutral-600', color: 'rgb(82 82 82)' },
    { value: 'bg-neutral-700', color: 'rgb(64 64 64)' },
    { value: 'bg-neutral-800', color: 'rgb(38 38 38)' },
    { value: 'bg-neutral-900', color: 'rgb(23 23 23)' },
    { value: 'bg-neutral-950', color: 'rgb(10 10 10)' },
    { value: 'bg-stone-50', color: 'rgb(250 250 249)' },
    { value: 'bg-stone-100', color: 'rgb(245 245 244)' },
    { value: 'bg-stone-200', color: 'rgb(231 229 228)' },
    { value: 'bg-stone-300', color: 'rgb(214 211 209)' },
    { value: 'bg-stone-400', color: 'rgb(168 162 158)' },
    { value: 'bg-stone-500', color: 'rgb(120 113 108)' },
    { value: 'bg-stone-600', color: 'rgb(87 83 78)' },
    { value: 'bg-stone-700', color: 'rgb(68 64 60)' },
    { value: 'bg-stone-800', color: 'rgb(41 37 36)' },
    { value: 'bg-stone-900', color: 'rgb(28 25 23)' },
    { value: 'bg-stone-950', color: 'rgb(12 10 9)' },
    { value: 'bg-red-50', color: 'rgb(254 242 242)' },
    { value: 'bg-red-100', color: 'rgb(254 226 226)' },
    { value: 'bg-red-200', color: 'rgb(254 202 202)' },
    { value: 'bg-red-300', color: 'rgb(252 165 165)' },
    { value: 'bg-red-400', color: 'rgb(248 113 113)' },
    { value: 'bg-red-500', color: 'rgb(239 68 68)' },
    { value: 'bg-red-600', color: 'rgb(220 38 38)' },
    { value: 'bg-red-700', color: 'rgb(185 28 28)' },
    { value: 'bg-red-800', color: 'rgb(153 27 27)' },
    { value: 'bg-red-900', color: 'rgb(127 29 29)' },
    { value: 'bg-red-950', color: 'rgb(69 10 10)' },
    { value: 'bg-orange-50', color: 'rgb(255 247 237)' },
    { value: 'bg-orange-100', color: 'rgb(255 237 213)' },
    { value: 'bg-orange-200', color: 'rgb(254 215 170)' },
    { value: 'bg-orange-300', color: 'rgb(253 186 116)' },
    { value: 'bg-orange-400', color: 'rgb(251 146 60)' },
    { value: 'bg-orange-500', color: 'rgb(249 115 22)' },
    { value: 'bg-orange-600', color: 'rgb(234 88 12)' },
    { value: 'bg-orange-700', color: 'rgb(194 65 12)' },
    { value: 'bg-orange-800', color: 'rgb(154 52 18)' },
    { value: 'bg-orange-900', color: 'rgb(124 45 18)' },
    { value: 'bg-orange-950', color: 'rgb(67 20 7)' },
    { value: 'bg-amber-50', color: 'rgb(255 251 235)' },
    { value: 'bg-amber-100', color: 'rgb(254 243 199)' },
    { value: 'bg-amber-200', color: 'rgb(253 230 138)' },
    { value: 'bg-amber-300', color: 'rgb(252 211 77)' },
    { value: 'bg-amber-400', color: 'rgb(251 191 36)' },
    { value: 'bg-amber-500', color: 'rgb(245 158 11)' },
    { value: 'bg-amber-600', color: 'rgb(217 119 6)' },
    { value: 'bg-amber-700', color: 'rgb(180 83 9)' },
    { value: 'bg-amber-800', color: 'rgb(146 64 14)' },
    { value: 'bg-amber-900', color: 'rgb(120 53 15)' },
    { value: 'bg-amber-950', color: 'rgb(69 26 3)' },
    { value: 'bg-yellow-50', color: 'rgb(254 252 232)' },
    { value: 'bg-yellow-100', color: 'rgb(254 249 195)' },
    { value: 'bg-yellow-200', color: 'rgb(254 240 138)' },
    { value: 'bg-yellow-300', color: 'rgb(253 224 71)' },
    { value: 'bg-yellow-400', color: 'rgb(250 204 21)' },
    { value: 'bg-yellow-500', color: 'rgb(234 179 8)' },
    { value: 'bg-yellow-600', color: 'rgb(202 138 4)' },
    { value: 'bg-yellow-700', color: 'rgb(161 98 7)' },
    { value: 'bg-yellow-800', color: 'rgb(133 77 14)' },
    { value: 'bg-yellow-900', color: 'rgb(113 63 18)' },
    { value: 'bg-yellow-950', color: 'rgb(66 32 6)' },
    { value: 'bg-lime-50', color: 'rgb(247 254 231)' },
    { value: 'bg-lime-100', color: 'rgb(236 252 203)' },
    { value: 'bg-lime-200', color: 'rgb(217 249 157)' },
    { value: 'bg-lime-300', color: 'rgb(190 242 100)' },
    { value: 'bg-lime-400', color: 'rgb(163 230 53)' },
    { value: 'bg-lime-500', color: 'rgb(132 204 22)' },
    { value: 'bg-lime-600', color: 'rgb(101 163 13)' },
    { value: 'bg-lime-700', color: 'rgb(77 124 15)' },
    { value: 'bg-lime-800', color: 'rgb(63 98 18)' },
    { value: 'bg-lime-900', color: 'rgb(54 83 20)' },
    { value: 'bg-lime-950', color: 'rgb(26 46 5)' },
    { value: 'bg-green-50', color: 'rgb(240 253 244)' },
    { value: 'bg-green-100', color: 'rgb(220 252 231)' },
    { value: 'bg-green-200', color: 'rgb(187 247 208)' },
    { value: 'bg-green-300', color: 'rgb(134 239 172)' },
    { value: 'bg-green-400', color: 'rgb(74 222 128)' },
    { value: 'bg-green-500', color: 'rgb(34 197 94)' },
    { value: 'bg-green-600', color: 'rgb(22 163 74)' },
    { value: 'bg-green-700', color: 'rgb(21 128 61)' },
    { value: 'bg-green-800', color: 'rgb(22 101 52)' },
    { value: 'bg-green-900', color: 'rgb(20 83 45)' },
    { value: 'bg-green-950', color: 'rgb(5 46 22)' },
    { value: 'bg-emerald-50', color: 'rgb(236 253 245)' },
    { value: 'bg-emerald-100', color: 'rgb(209 250 229)' },
    { value: 'bg-emerald-200', color: 'rgb(167 243 208)' },
    { value: 'bg-emerald-300', color: 'rgb(110 231 183)' },
    { value: 'bg-emerald-400', color: 'rgb(52 211 153)' },
    { value: 'bg-emerald-500', color: 'rgb(16 185 129)' },
    { value: 'bg-emerald-600', color: 'rgb(5 150 105)' },
    { value: 'bg-emerald-700', color: 'rgb(4 120 87)' },
    { value: 'bg-emerald-800', color: 'rgb(6 95 70)' },
    { value: 'bg-emerald-900', color: 'rgb(6 78 59)' },
    { value: 'bg-emerald-950', color: 'rgb(2 44 34)' },
    { value: 'bg-teal-50', color: 'rgb(240 253 250)' },
    { value: 'bg-teal-100', color: 'rgb(204 251 241)' },
    { value: 'bg-teal-200', color: 'rgb(153 246 228)' },
    { value: 'bg-teal-300', color: 'rgb(94 234 212)' },
    { value: 'bg-teal-400', color: 'rgb(45 212 191)' },
    { value: 'bg-teal-500', color: 'rgb(20 184 166)' },
    { value: 'bg-teal-600', color: 'rgb(13 148 136)' },
    { value: 'bg-teal-700', color: 'rgb(15 118 110)' },
    { value: 'bg-teal-800', color: 'rgb(17 94 89)' },
    { value: 'bg-teal-900', color: 'rgb(19 78 74)' },
    { value: 'bg-teal-950', color: 'rgb(4 47 46)' },
    { value: 'bg-cyan-50', color: 'rgb(236 254 255)' },
    { value: 'bg-cyan-100', color: 'rgb(207 250 254)' },
    { value: 'bg-cyan-200', color: 'rgb(165 243 252)' },
    { value: 'bg-cyan-300', color: 'rgb(103 232 249)' },
    { value: 'bg-cyan-400', color: 'rgb(34 211 238)' },
    { value: 'bg-cyan-500', color: 'rgb(6 182 212)' },
    { value: 'bg-cyan-600', color: 'rgb(8 145 178)' },
    { value: 'bg-cyan-700', color: 'rgb(14 116 144)' },
    { value: 'bg-cyan-800', color: 'rgb(21 94 117)' },
    { value: 'bg-cyan-900', color: 'rgb(22 78 99)' },
    { value: 'bg-cyan-950', color: 'rgb(8 51 68)' },
    { value: 'bg-sky-50', color: 'rgb(240 249 255)' },
    { value: 'bg-sky-100', color: 'rgb(224 242 254)' },
    { value: 'bg-sky-200', color: 'rgb(186 230 253)' },
    { value: 'bg-sky-300', color: 'rgb(125 211 252)' },
    { value: 'bg-sky-400', color: 'rgb(56 189 248)' },
    { value: 'bg-sky-500', color: 'rgb(14 165 233)' },
    { value: 'bg-sky-600', color: 'rgb(2 132 199)' },
    { value: 'bg-sky-700', color: 'rgb(3 105 161)' },
    { value: 'bg-sky-800', color: 'rgb(7 89 133)' },
    { value: 'bg-sky-900', color: 'rgb(12 74 110)' },
    { value: 'bg-sky-950', color: 'rgb(8 47 73)' },
    { value: 'bg-blue-50', color: 'rgb(239 246 255)' },
    { value: 'bg-blue-100', color: 'rgb(219 234 254)' },
    { value: 'bg-blue-200', color: 'rgb(191 219 254)' },
    { value: 'bg-blue-300', color: 'rgb(147 197 253)' },
    { value: 'bg-blue-400', color: 'rgb(96 165 250)' },
    { value: 'bg-blue-500', color: 'rgb(59 130 246)' },
    { value: 'bg-blue-600', color: 'rgb(37 99 235)' },
    { value: 'bg-blue-700', color: 'rgb(29 78 216)' },
    { value: 'bg-blue-800', color: 'rgb(30 64 175)' },
    { value: 'bg-blue-900', color: 'rgb(30 58 138)' },
    { value: 'bg-blue-950', color: 'rgb(23 37 84)' },
    { value: 'bg-indigo-50', color: 'rgb(238 242 255)' },
    { value: 'bg-indigo-100', color: 'rgb(224 231 255)' },
    { value: 'bg-indigo-200', color: 'rgb(199 210 254)' },
    { value: 'bg-indigo-300', color: 'rgb(165 180 252)' },
    { value: 'bg-indigo-400', color: 'rgb(129 140 248)' },
    { value: 'bg-indigo-500', color: 'rgb(99 102 241)' },
    { value: 'bg-indigo-600', color: 'rgb(79 70 229)' },
    { value: 'bg-indigo-700', color: 'rgb(67 56 202)' },
    { value: 'bg-indigo-800', color: 'rgb(55 48 163)' },
    { value: 'bg-indigo-900', color: 'rgb(49 46 129)' },
    { value: 'bg-indigo-950', color: 'rgb(30 27 75)' },
    { value: 'bg-violet-50', color: 'rgb(245 243 255)' },
    { value: 'bg-violet-100', color: 'rgb(237 233 254)' },
    { value: 'bg-violet-200', color: 'rgb(221 214 254)' },
    { value: 'bg-violet-300', color: 'rgb(196 181 253)' },
    { value: 'bg-violet-400', color: 'rgb(167 139 250)' },
    { value: 'bg-violet-500', color: 'rgb(139 92 246)' },
    { value: 'bg-violet-600', color: 'rgb(124 58 237)' },
    { value: 'bg-violet-700', color: 'rgb(109 40 217)' },
    { value: 'bg-violet-800', color: 'rgb(91 33 182)' },
    { value: 'bg-violet-900', color: 'rgb(76 29 149)' },
    { value: 'bg-violet-950', color: 'rgb(46 16 101)' },
    { value: 'bg-purple-50', color: 'rgb(250 245 255)' },
    { value: 'bg-purple-100', color: 'rgb(243 232 255)' },
    { value: 'bg-purple-200', color: 'rgb(233 213 255)' },
    { value: 'bg-purple-300', color: 'rgb(216 180 254)' },
    { value: 'bg-purple-400', color: 'rgb(192 132 252)' },
    { value: 'bg-purple-500', color: 'rgb(168 85 247)' },
    { value: 'bg-purple-600', color: 'rgb(147 51 234)' },
    { value: 'bg-purple-700', color: 'rgb(126 34 206)' },
    { value: 'bg-purple-800', color: 'rgb(107 33 168)' },
    { value: 'bg-purple-900', color: 'rgb(88 28 135)' },
    { value: 'bg-purple-950', color: 'rgb(59 7 100)' },
    { value: 'bg-fuchsia-50', color: 'rgb(253 244 255)' },
    { value: 'bg-fuchsia-100', color: 'rgb(250 232 255)' },
    { value: 'bg-fuchsia-200', color: 'rgb(245 208 254)' },
    { value: 'bg-fuchsia-300', color: 'rgb(240 171 252)' },
    { value: 'bg-fuchsia-400', color: 'rgb(232 121 249)' },
    { value: 'bg-fuchsia-500', color: 'rgb(217 70 239)' },
    { value: 'bg-fuchsia-600', color: 'rgb(192 38 211)' },
    { value: 'bg-fuchsia-700', color: 'rgb(162 28 175)' },
    { value: 'bg-fuchsia-800', color: 'rgb(134 25 143)' },
    { value: 'bg-fuchsia-900', color: 'rgb(112 26 117)' },
    { value: 'bg-fuchsia-950', color: 'rgb(74 4 78)' },
    { value: 'bg-pink-50', color: 'rgb(253 242 248)' },
    { value: 'bg-pink-100', color: 'rgb(252 231 243)' },
    { value: 'bg-pink-200', color: 'rgb(251 207 232)' },
    { value: 'bg-pink-300', color: 'rgb(249 168 212)' },
    { value: 'bg-pink-400', color: 'rgb(244 114 182)' },
    { value: 'bg-pink-500', color: 'rgb(236 72 153)' },
    { value: 'bg-pink-600', color: 'rgb(219 39 119)' },
    { value: 'bg-pink-700', color: 'rgb(190 24 93)' },
    { value: 'bg-pink-800', color: 'rgb(157 23 77)' },
    { value: 'bg-pink-900', color: 'rgb(131 24 67)' },
    { value: 'bg-pink-950', color: 'rgb(80 7 36)' },
    { value: 'bg-rose-50', color: 'rgb(255 241 242)' },
    { value: 'bg-rose-100', color: 'rgb(255 228 230)' },
    { value: 'bg-rose-200', color: 'rgb(254 205 211)' },
    { value: 'bg-rose-300', color: 'rgb(253 164 175)' },
    { value: 'bg-rose-400', color: 'rgb(251 113 133)' },
    { value: 'bg-rose-500', color: 'rgb(244 63 94)' },
    { value: 'bg-rose-600', color: 'rgb(225 29 72)' },
    { value: 'bg-rose-700', color: 'rgb(190 18 60)' },
    { value: 'bg-rose-800', color: 'rgb(159 18 57)' },
    { value: 'bg-rose-900', color: 'rgb(136 19 55)' },
    { value: 'bg-rose-950', color: 'rgb(76 5 25)' },
  ];

  console.log(props.value);

  return (
    <>
      <div className={`bg-showoff`} style={{ background: props.value }}>
        Seçilen Arkaplan
      </div>

      <h4 className="font-title text-xl mb-4 mt-6">Renk Paleti</h4>
      <ColorPicker showText={(color) => <span>Seçim: ({color.toHexString()})</span>} onChange={(e) => props.onChange(e.toHexString())} />

      <h4 className="font-title text-xl mb-4 mt-6">Pattern</h4>
      {patterns.map((pattern, patternKey) => {
        return (
          <div key={patternKey} className="columns-11 gap-1 mb-1">
            {pattern.names.map((name: string) => {
              const check = props.value === `url(${pattern.path}/${name}) center center / cover repeat`;

              return (
                <div
                  key={name}
                  className={`bg-pattern ${check ? 'checked' : ''}`}
                  onClick={() => {
                    props.onChange(`url(${pattern.path}/${name}) center center / cover repeat`);
                  }}
                >
                  <img src={`${pattern.path}/${name}`} alt="" />
                </div>
              );
            })}
          </div>
        );
      })}

      <h4 className="font-title text-xl mb-4 mt-6">Renk</h4>
      <div className="columns-11 gap-1">
        {colors.map((color) => {
          const check = props.value === color.color;

          return (
            <div
              key={color.value}
              style={{ backgroundColor: color.color }}
              className={`bg-color ${check ? 'checked' : ''}`}
              onClick={() => {
                props.onChange(color.color);
              }}
            />
          );
        })}
      </div>
    </>
  );
}
