import React, { useRef, useState } from 'react';
import './ImageScroll.scss';

interface ImageScrollProps {
  src: string;
}

export default function ImageScroll({ src }: ImageScrollProps) {
  const [isHover, setIsHover] = useState(false);

  const ref = useRef<Element>(null);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  // @ts-ignore
  const height = ref?.current?.height - 200;

  return (
    <div className="image-scroll" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img
        // @ts-ignore
        ref={ref}
        src={src}
        alt=""
        style={{ top: isHover ? `-${height}px` : 0 }}
      />
    </div>
  );
}
