import { EditorConfigW } from '../../../../service/Type';

export interface EProps {}

const config: EditorConfigW<EProps> = {
  title: 'İletişim Bilgileri',
  description: 'İletişim formu, adres, telefon ve diğer iletişim bilgilerinin yer aldığı sayfa bölümü.',
  type: 'contact-1',
  code: 'CTCT-1',
  category: ['contact'],
  suitable: ['main'],
  propsDefault: async () => {
    return {};
  },
};

export default config;
