import React from 'react';
import { Formik } from 'formik';
import { Form, Modal, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { IProject, ITemplate } from '../../../service/TypeGraphql';
import WebsiteSettingForm from './WebsiteSettingForm';
import { createSlug } from '../../../service/util/Slug';

interface WebsiteSettingProps {
  templateSelected: ITemplate | undefined;
  handleSelectedCancel: () => void;
  handleCreate: (title: string, path: string, seoTitle: string, seoDescription: string, templateId: string) => void;
  project: IProject;
}

export function WebsiteSetting({ templateSelected, handleSelectedCancel, handleCreate, project }: WebsiteSettingProps) {
  const initialValues = {
    title: templateSelected?.title || '',
    path: createSlug(templateSelected?.title || ''),
    seoTitle: '',
    seoDescription: '',
  };

  const schema = yup.object().shape({
    title: yup.string().required('Sayfa başlığı girilmelidir').max(256, 'Sayfa başlığı en fazla 256 karakter uzunluğunda olabilir'),
    path: yup.string().required('Sayfa linki girilmelidir').max(256, 'Sayfa linki en fazla 256 karakter uzunluğunda olabilir'),
    seoTitle: yup.string().required('Seo başlığı girilmelidir').max(256, 'Seo başlığı en fazla 256 karakter uzunluğunda olabilir'),
    seoDescription: yup
      .string()
      .required('Seo açıklaması girilmelidir')
      .max(512, 'Seo açıklaması en fazla 512 karakter uzunluğunda olabilir'),
  });

  const onSubmit = async (values: any) => {
    console.log(values);
    await handleCreate(values.title, values.path, values.seoTitle, values.seoDescription, templateSelected?.templateId as string);

    notification.success({
      message: 'Başarı',
      description: 'Sayfa başarıyla kaydedildi. ',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange
      validateOnBlur
      onSubmit={async (values) => {
        await onSubmit(values);
        return true;
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Modal
          open={!!templateSelected}
          onCancel={() => handleSelectedCancel()}
          onOk={() => {
            handleSubmit();
          }}
          width="80%"
          className="modal-preview"
          centered
          okText="Sayfa Oluştur"
          okButtonProps={{
            icon: <EditOutlined />,
            disabled: isSubmitting,
          }}
          closable={false}
          zIndex={2}
        >
          <div style={{ height: '60vh' }}>
            <Form layout="vertical">
              <WebsiteSettingForm project={project} />
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
}
