import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import { IError, IProjectListFilters, IStatus } from '../Type';
import { IProject, IProjectCreateData, IProjectUpdateData } from '../Type';

export const loadProjectList = async (
  filter: IProjectListFilters,
): Promise<{
  result: IProject[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query projects($options: ProjectListOptions) {
          projectList(options: $options) {
            projectId
            title
            logo
            favicon

            linkSchema
            link

            purpose
            slogan
            target

            isActive
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        options: {
          filter,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.projectList,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: [],
  };
};

export const loadProject = async (
  projectId: string,
): Promise<{
  result?: IProject;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query project($projectId: ID!) {
          project(projectId: $projectId) {
            projectId
            title

            type
            language

            linkSchema
            link

            purpose
            slogan
            target

            logo
            logoDark
            favicon

            contactAddress
            contactEmail
            contactPhone

            isBlog
            isEcommerce

            socialMedia {
              urlFacebook
              urlInstagram
              urlWhatsapp
              urlPinterest
              urlLinkedin
              urlX
              urlThreads
              urlYoutube
              urlTiktok
              urlTwitch
              urlTumblr
              urlSnapchat
              urlReddit
              urlDiscord
              urlSpotify
              urlApple
              urlAmazon
              urlFoursquare
              urlMedium
              urlVimeo
              urlSkype
              urlAndroid
              urlQuora
              urlSlack
              urlDeviantArt
              urlKariyerNet
            }

            colorPrimary
            colorText
            colorBackground
            colorSuccess
            colorError
            colorWarning
            colorInfo

            fontFamily
            fontSize

            pageList {
              pageId
              title
              path
              type
              seoDescription
              seoTitle
              seoImage
            }

            isActive
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        projectId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.project,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const projectCreate = async (
  data: IProjectCreateData,
): Promise<{
  result?: IProject;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation projectCreate($data: ProjectCreateData!) {
          projectCreate(data: $data) {
            projectId
            title
            isActive
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.projectCreate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const projectUpdate = async (
  projectId: string,
  data: IProjectUpdateData,
): Promise<{
  result?: IProject;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation projectUpdate($projectId: ID!, $data: ProjectUpdateData!) {
          projectUpdate(projectId: $projectId, data: $data) {
            projectId
            title
            isActive
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        projectId,
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.projectUpdate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const projectActivate = async (
  projectId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation projectActivate($projectId: ID!) {
          projectActivate(projectId: $projectId) {
            message
            status
          }
        }
      `,
      variables: {
        projectId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.projectActivate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const projectDeactivate = async (
  projectId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation projectDeactivate($projectId: ID!) {
          projectDeactivate(projectId: $projectId) {
            message
            status
          }
        }
      `,
      variables: {
        projectId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.projectDeactivate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
