import { EditorConfigW, IContentPostListFilters, IProject } from '../../../../service/Type';

export interface EProps {
  title: string;
  description: string;
  filter: IContentPostListFilters;
}

const config: EditorConfigW<EProps> = {
  title: 'Blog Sayfası',
  description: 'Blog yazılarının listelendiği, özet bilgilerin ve başlıkların yer aldığı genel blog sayfa yapısı.',
  type: 'blog-horizontal-4',
  code: 'BH4',
  category: ['blog'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      texts: {
        type: 'array',
        title: 'Tanımlar',
        items: {
          type: 'object',
          required: ['label', 'children'],
          title: '',
          properties: {
            label: {
              type: 'string',
              title: 'Başlık',
            },
            children: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  propsDefault: async (project: IProject) => {
    return {
      title: 'Duyurular',
      description: `${project.title}'de son olup bitenler`,
      filter: {},
    };
  },
};

export default config;
