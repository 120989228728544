import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  links: {
    text: string;
    link: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Web Sayfası Alt Bilgisi',
  description:
    'Web sayfasının altında yer alan, hakkında, gizlilik politikası, sosyal medya bağlantıları gibi temel bilgilerin bulunduğu bölüm.',
  type: 'footer-2',
  code: 'FTR2',
  category: ['footer'],
  suitable: ['footer'],
  schema: {
    type: 'object',
    required: ['links'],
    properties: {
      links: {
        type: 'array',
        title: 'Bağlantılar',
        items: {
          type: 'object',
          required: ['text', 'link'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            link: {
              type: 'string',
              title: 'Bağlantı',
            },
          },
        },
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      links: [
        {
          link: '/gizlilik-sozlesmesi',
          text: 'Gizlilik Sözleşmesi',
        },
        {
          link: '/cerez-politikasi',
          text: 'Çerez Politikası',
        },
        {
          link: '/kullanim-sartlari',
          text: 'Kullanım Şartları',
        },
      ],
    };
  },
};

export default config;
