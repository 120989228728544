import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentMissionVisionValues } from '../../../../service/api/Content';

export interface EProps {
  missionTitle: string;
  mission: string;
  visionTitle: string;
  vision: string;
  valuesTitle: string;
  values: string;
}

const config: EditorConfigW<EProps> = {
  title: 'Şirketin Temelleri',
  description: 'Şirketin misyonu, vizyonu ve temel değerlerini açıklayan bilgilendirici bölüm.',
  type: 'content-2',
  code: 'CTN2',
  category: ['content'],
  suitable: ['main'],
  styleDefault: {
    background: 'rgb(125 211 252)',
    container: 'compact',
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cMissionVisionValues = await contentMissionVisionValues(
      project.projectId,
      page.pageId,
      `Write mission, vision and values content. "`,
    );

    return {
      missionTitle: 'Misyonumuz',
      visionTitle: 'Vizyonumuz',
      valuesTitle: 'Değerlerimiz',
      mission: cMissionVisionValues?.result?.mission || '',
      vision: cMissionVisionValues?.result?.vision || '',
      values: cMissionVisionValues?.result?.values || '',
    };
  },
};

export default config;
