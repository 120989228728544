import { EditorConfigW } from '../../../../service/Type';

interface Menu {
  title: string;
  links: {
    text: string;
    link: string;
  }[];
}

export interface EProps {
  menu: Menu[];
  bulletin: boolean;
}

const config: EditorConfigW<EProps> = {
  title: 'Web Sayfası Alt Bilgisi',
  description:
    'Web sayfasının altında yer alan, hakkında, gizlilik politikası, sosyal medya bağlantıları gibi temel bilgilerin bulunduğu bölüm.',
  type: 'footer-3',
  code: 'FTR3',
  category: ['footer'],
  suitable: ['footer'],
  schema: {
    type: 'object',
    required: ['menu'],
    properties: {
      bulletin: {
        type: 'boolean',
        title: 'Bülten',
        default: true,
      },
      menu: {
        type: 'array',
        title: 'Menüler',
        items: {
          type: 'object',
          required: ['title', 'links'],
          title: '',
          properties: {
            title: {
              type: 'string',
              title: 'Menü Başlığı',
            },
            links: {
              type: 'array',
              title: 'Menüler',
              items: {
                type: 'object',
                required: ['text', 'link'],
                title: '',
                properties: {
                  text: {
                    type: 'string',
                    title: 'Başlık',
                  },
                  link: {
                    type: 'string',
                    title: 'Bağlantı',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      bulletin: true,
      menu: [
        {
          title: 'Kurumsal',
          links: [
            {
              link: '/iletisim',
              text: 'İletişim',
            },
            {
              link: '/hakkimizda',
              text: 'Hakkımızda',
            },
          ],
        },
        {
          title: 'Sözleşmeler',
          links: [
            {
              link: '/gizlilik-sozlesmesi',
              text: 'Gizlilik Sözleşmesi',
            },
            {
              link: '/cerez-politikasi',
              text: 'Çerez Politikası',
            },
            {
              link: '/kullanim-sartlari',
              text: 'Kullanım Şartları',
            },
          ],
        },
        {
          title: 'Uygulamalar',
          links: [
            {
              link: '/',
              text: 'Play Store',
            },
            {
              link: '/',
              text: 'App Store',
            },
            {
              link: '/',
              text: 'App Gallery',
            },
          ],
        },
      ],
    };
  },
};
export default config;
