import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  title: string;
  description: string;
  source: string;
  height: number;
}

const config: EditorConfigW<EProps> = {
  title: 'Harici İçerik Gömme',
  description: 'Harici web sayfalarını veya videoları içeriğinize gömme imkanı sunan bir HTML çerçeve bloğu.',
  type: 'iframe-1',
  code: 'IFRM1',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'source', 'height'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      source: { type: 'string', title: 'Iframe Linki', default: '' },
      height: { type: 'number', title: 'Iframe Yüksekliği', default: '' },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      title: 'Iframe',
      description: 'Diğer websitelerinin nasıl göründüklerini merak ediyor musunuz?',
      source: 'https://www.google.com',
      height: 500,
    };
  },
};

export default config;
