export const languageList = [
  { code: 'tr', nameEn: 'Turkish', name: 'Türkçe', nameTr: 'Türkçe' },
  {
    code: 'en',
    nameEn: 'English',
    name: 'English',
    nameTr: 'İngilizce',
  },
  {
    code: 'es',
    nameEn: 'Spanish',
    name: 'Español',
    nameTr: 'İspanyolca',
  },
  {
    code: 'fr',
    nameEn: 'French',
    name: 'Français',
    nameTr: 'Fransızca',
  },
  { code: 'de', nameEn: 'German', name: 'Deutsch', nameTr: 'Almanca' },
  { code: 'zh', nameEn: 'Chinese', name: '中文', nameTr: 'Çince' },
  { code: 'ja', nameEn: 'Japanese', name: '日本語', nameTr: 'Japonca' },
  { code: 'ru', nameEn: 'Russian', name: 'Русский', nameTr: 'Rusça' },
  {
    code: 'it',
    nameEn: 'Italian',
    name: 'Italiano',
    nameTr: 'İtalyanca',
  },
  {
    code: 'pt',
    nameEn: 'Portuguese',
    name: 'Português',
    nameTr: 'Portekizce',
  },
  { code: 'ko', nameEn: 'Korean', name: '한국어', nameTr: 'Korece' },
  { code: 'ar', nameEn: 'Arabic', name: 'العربية', nameTr: 'Arapça' },
  {
    code: 'nl',
    nameEn: 'Dutch',
    name: 'Nederlands',
    nameTr: 'Hollandaca',
  },
  { code: 'pl', nameEn: 'Polish', name: 'Polski', nameTr: 'Lehçe' },
  { code: 'sv', nameEn: 'Swedish', name: 'Svenska', nameTr: 'İsveççe' },
  { code: 'da', nameEn: 'Danish', name: 'Dansk', nameTr: 'Danca' },
  { code: 'fi', nameEn: 'Finnish', name: 'Suomi', nameTr: 'Fince' },
  {
    code: 'no',
    nameEn: 'Norwegian',
    name: 'Norsk',
    nameTr: 'Norveççe',
  },
  {
    code: 'hu',
    nameEn: 'Hungarian',
    name: 'Magyar',
    nameTr: 'Macarca',
  },
  { code: 'cs', nameEn: 'Czech', name: 'Čeština', nameTr: 'Çekçe' },
  { code: 'ro', nameEn: 'Romanian', name: 'Română', nameTr: 'Romence' },
  { code: 'el', nameEn: 'Greek', name: 'Ελληνικά', nameTr: 'Yunanca' },
  { code: 'he', nameEn: 'Hebrew', name: 'עברית', nameTr: 'İbranice' },
  { code: 'hi', nameEn: 'Hindi', name: 'हिन्दी', nameTr: 'Hintçe' },
  { code: 'th', nameEn: 'Thai', name: 'ไทย', nameTr: 'Tayca' },
  {
    code: 'id',
    nameEn: 'Indonesian',
    name: 'Bahasa Indonesia',
    nameTr: 'Endonezce',
  },
  {
    code: 'vi',
    nameEn: 'Vietnamese',
    name: 'Tiếng Việt',
    nameTr: 'Vietnamca',
  },
  { code: 'fa', nameEn: 'Persian', name: 'فارسی', nameTr: 'Farsça' },
  {
    code: 'uk',
    nameEn: 'Ukrainian',
    name: 'Українська',
    nameTr: 'Ukraynaca',
  },
  {
    code: 'bg',
    nameEn: 'Bulgarian',
    name: 'Български',
    nameTr: 'Bulgarca',
  },
  {
    code: 'hr',
    nameEn: 'Croatian',
    name: 'Hrvatski',
    nameTr: 'Hırvatça',
  },
  { code: 'sr', nameEn: 'Serbian', name: 'Српски', nameTr: 'Sırpça' },
  {
    code: 'sk',
    nameEn: 'Slovak',
    name: 'Slovenčina',
    nameTr: 'Slovakça',
  },
  {
    code: 'az',
    nameEn: 'Azeri',
    name: 'Azərbaycan dili',
    nameTr: 'Azerice',
  },
  {
    code: 'dv',
    nameEn: 'Dhivehi/Maldivian',
    name: 'ދިވެހި',
    nameTr: 'Divehi/Maldivce',
  },
  { code: 'ku', nameEn: 'Kurdish', name: 'کوردی', nameTr: 'Kürtçe' },
  { code: 'ur', nameEn: 'Urdu', name: 'اردو', nameTr: 'Urduca' },
];
