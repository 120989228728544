import { LOAD_INITIAL, ON_BOARD_MODULE } from './actionTypes';
import { IAccount, IProfile, IWorkspace } from '../Type';

export const loadInitial = (payload: { profile: IProfile; account: IAccount; workspace: IWorkspace }) => ({
  type: LOAD_INITIAL,
  payload,
});

export const moduleOnBoard = (payload: { module: string }) => ({
  type: ON_BOARD_MODULE,
  payload,
});
