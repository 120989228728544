import { EditorConfigW } from '../../../../service/Type';

export interface EProps {
  dealers: {
    dealerId: number;
    dealerName: string;
    dealerCity: string;
    dealerCounty: string;
    dealerAddress: string;
    dealerPhone: string;
    dealerMail: string;
    isApproved: number;
    coordinate: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Bayi ve Satış Noktaları',
  description: 'Şirketin bayi veya satış noktalarının listelendiği, iletişim bilgileri ve konumlarının gösterildiği bölüm.',
  type: 'dealer-1',
  code: 'DLR1',
  category: ['content'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'dealers'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      dealers: {
        type: 'array',
        title: 'Tanımlar',
        items: {
          type: 'object',
          required: ['label', 'children'],
          title: '',
          properties: {
            dealerId: {
              type: 'string',
              title: 'ID',
            },
            dealerName: {
              type: 'string',
              title: 'Bayi',
            },
            dealerCity: {
              type: 'string',
              title: 'İl',
            },
            dealerCounty: {
              type: 'string',
              title: 'İlçe',
            },
            dealerAddress: {
              type: 'string',
              title: 'Adres',
            },
            dealerPhone: {
              type: 'string',
              title: 'Telefon',
            },
            dealerMail: {
              type: 'string',
              title: 'Eposta',
            },
            coordinate: {
              type: 'string',
              title: 'Koordinat',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      dealers: [
        {
          dealerId: 1,
          dealerName: 'Bayi 1',
          dealerCity: 'Ankara',
          dealerCounty: 'Çankaya',
          dealerAddress: 'Sokullu Mehmet Pasa Mah.',
          dealerPhone: '0500 000 0000',
          dealerMail: 'emailadres@gmail.com',
          isApproved: 1,
          coordinate: '39.089004,28.9764475',
        },
      ],
    };
  },
};

export default config;
