import React from 'react';

interface Font {
  title: string;
  fontFamily: React.CSSProperties['fontFamily'];
  url?: string;
}

export const fontList: Font[] = [
  {
    title: 'Inter',
    fontFamily: "'Inter', sans-serif",
    // url: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900',
  },
  {
    title: 'Roboto',
    fontFamily: "'Roboto', sans-serif",
    // url: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900',
  },
  {
    title: 'Open Sans',
    fontFamily: "'Open Sans', sans-serif",
    // url: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800',
  },
  {
    title: 'Lato',
    fontFamily: "'Lato', sans-serif",
  },
  {
    title: 'Poppins',
    fontFamily: "'Poppins', sans-serif",
    // url: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900',
  },
  {
    title: 'Helvetica, Arial, sans-serif',
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
];
