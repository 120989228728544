import React, { useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { Block, IPage, IProject } from '../../service/Type';
import ComponentStyle from './component-shared/ComponentStyle';

interface EditorCreateAsyncBlockProps {
  block: Block;
  onCreate: any;
  project: IProject;
  page: IPage;
}

export default function EditorBlockCreateAsyncBlock({ block, project, page, onCreate }: EditorCreateAsyncBlockProps) {
  const [props, setProps] = useState();
  const [loading, setLoading] = useState(true);

  const loadProps = async () => {
    const props = await block.config.propsDefault(project, page);
    setProps(props);
    setLoading(false);
  };

  useEffect(() => {
    loadProps();
  }, []);

  if (loading) {
    return (
      <Card
        className="template-wrapper"
        size="small"
        title={
          <>
            <strong>{block.config.title}</strong>
            <br /> <small>{block.config.description}</small>
          </>
        }
      >
        <div className="template-loading">
          <Spin size="large" />
        </div>
        <div className="template-select">
          <SelectOutlined style={{ fontSize: '64px' }} />
        </div>
      </Card>
    );
  }

  return (
    <Card
      className="template-wrapper"
      size="small"
      title={
        <>
          <strong>{block.config.title}</strong>
          <br /> <small>{block.config.description}</small>
        </>
      }
      onClick={() => {
        onCreate({
          blockId: null,
          props: props,
          style: block.config.styleDefault,
          type: block.config.type,
        });
      }}
    >
      <div className="template">
        <ComponentStyle style={block.config.styleDefault}>
          <block.viewer project={project} page={page} props={props} style={block.config.styleDefault} />
        </ComponentStyle>
      </div>
      <div className="template-select">
        <SelectOutlined style={{ fontSize: '64px' }} />
      </div>
    </Card>
  );
}
