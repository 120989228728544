import { IAction } from '../../Type';
import { LOAD_INITIAL } from '../actionTypes';
import { IAccount } from '../../Type';

type IAccountReducer = {
  account?: IAccount;
};

const initialState: IAccountReducer = {
  account: undefined,
};

export default function reducer(state = initialState, { type, payload }: IAction) {
  switch (type) {
    case LOAD_INITIAL:
      return {
        ...state,
        account: payload.account as IAccount,
      };
    default:
      return state;
  }
}
