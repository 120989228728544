import { EditorConfigW } from '../../../../service/Type';

export interface Link {
  text: string;
  link: string;
  links?: Link[];
}

export interface EProps {
  logoHeight: number;
  links: Link[];
}

const config: EditorConfigW<EProps> = {
  title: 'Sayfa Üst Bilgisi',
  description: 'Web sayfasının üst kısmında yer alan, logo, navigasyon menüsü ve diğer önemli bağlantıların bulunduğu bölüm.',
  type: 'header-analiz',
  code: 'HDR-Analiz',
  category: ['header'],
  suitable: ['header'],
  schema: {
    type: 'object',
    required: ['links', 'logoHeight'],
    properties: {
      links: {
        type: 'array',
        title: 'Bağlantılar',
        items: {
          type: 'object',
          required: ['text', 'link'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            link: {
              type: 'string',
              title: 'Bağlantı',
            },
            links: {
              type: 'array',
              title: 'Alt Bağlantılar',
              items: {
                $ref: '#/properties/links/items',
              },
            },
          },
        },
      },
      logoHeight: {
        type: 'number',
        title: 'Logo Yüksekliği',
      },
    },
  },
  styleDefault: {
    container: 'compact',
    background: '#fff',
  },
  propsDefault: async () => {
    return {
      logoHeight: 80,
      links: [
        {
          link: '/hakkimizda',
          text: 'Hakkımızda',
        },
        {
          link: '/iletisim',
          text: 'İletişim',
        },
      ],
    };
  },
};

export default config;
