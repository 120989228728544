import React from 'react';
import ReactQuill from 'react-quill';
import { WidgetProps } from '@rjsf/utils';
import 'react-quill/dist/quill.snow.css';
import './QuillWidget.scss';

const modules = {
  toolbar: [[{ header: [1, 2, false] }], ['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
  clipboard: {
    matchVisual: false,
  },
};

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

export default function QuillWidget(props: WidgetProps) {
  console.log(props.value);
  return (
    <ReactQuill
      theme="snow"
      value={props.value || ''}
      onChange={(value: string) => props.onChange(value)}
      modules={modules}
      formats={formats}
      preserveWhitespace={true}
    />
  );
}
