import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';
import photos from './photos';

export interface EProps {
  title: string;
  description: string;
  photos: string[];
}

const config: EditorConfigW<EProps> = {
  title: 'Görsel Galeri',
  description: 'Seçilmiş görsellerin veya projelerin sergilendiği, kullanıcıların gezinebileceği bir galeri bloğu.',
  type: 'gallery-1',
  code: 'GLR1',
  category: ['gallery'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'photos'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      photos: {
        type: 'array',
        title: 'Fotoğraflar',
        items: {
          type: 'string',
          default: 'bazinga',
        },
      },
    },
  },
  schemaUI: {
    description: {
      'ui:widget': 'textarea',
    },
    photos: {
      'ui:widget': 'multiUpload',
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for gallery section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || page.title,
      description:
        cTitleDescription?.result?.description ||
        'Etkileyici bir başlık yazmak için net, dikkat çekici, değer vaadi sunan, hedef kitleyi düşünen, SEO odaklı, özgün ve etkileşimi teşvik eden bir yaklaşım benimseyin.',
      photos,
    };
  },
};

export default config;
