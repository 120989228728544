import React from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Card, Modal, notification, Tooltip } from 'antd';
import type { IProject } from '../../service/Type';
import { pageDelete } from '../../service/api/Page';
import ImageScroll from '../../component/ImageScroll';
import './WebsitePage.scss';

interface WebsitePageProps {
  project: IProject;
  onDelete: () => void;
}

export default function WebsitePage({ project, onDelete }: WebsitePageProps) {
  return (
    <>
      <div className="page-list">
        {project?.pageList?.map((page) => (
          <Card
            key={page.pageId}
            actions={[
              <Tooltip key="edit" title="Sayfayı Düzenle">
                <Link key="edit" to={`/project/${project.projectId}/sayfa/${page.pageId}/duzenle`}>
                  <EditOutlined />
                </Link>
              </Tooltip>,
              <Tooltip key="edit" title="Sayfayı Görüntüle">
                <a key="open" href={`http://${project.link}${page.path}`} target="_blank" rel="noreferrer">
                  <LinkOutlined />
                </a>
              </Tooltip>,
              <Tooltip key="delete" title="Sayfayı Sil">
                <a
                  onClick={() => {
                    Modal.confirm({
                      title: 'Sayfayı silmek istediğinizden emin misiniz?',
                      content: 'Sayfa silindiğinde geri alınamaz.',
                      async onOk() {
                        const project = await pageDelete(page.pageId);
                        if (project.result) {
                          notification.success({
                            message: 'Başarı',
                            description: 'Sayfa başarıyla silindi! ',
                          });
                          await onDelete();
                        }
                      },
                    });
                  }}
                >
                  <DeleteOutlined type="link" />
                </a>
              </Tooltip>,
            ]}
            cover={
              <ImageScroll src={`https://render.pixelperfect.tr/screenshot/http://${project.link}${page.path}?width=1200&height=2400`} />
            }
          >
            <div>
              <h2 style={{ margin: '0 0 16px ' }}>{page.title}</h2>
              <p>{page.seoTitle}</p>
              <p>{page.seoDescription}</p>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
}
