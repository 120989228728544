import React from 'react';
import './EditorPageSettingSocialMedia.scss';
import { IPage, IProject } from '../../service/TypeGraphql';

interface EditorPageSettingSocialMediaProps {
  onSubmitSuccess: any;
  project: IProject;
  page: IPage;
}

export default function EditorPageSettingSocialMedia({ onSubmitSuccess, project, page }: EditorPageSettingSocialMediaProps) {
  console.log(onSubmitSuccess);

  return (
    <>
      <h2 className="mb-2 font-title text-2xl">Sosyal medya paylaşım önizlemesi</h2>
      <p className="mb-4">
        Sosyal medya önizlemesi için varsayılan olarak genel sekmesinde belirtilen başlık, açıklama ve görsel kullanılır.
      </p>
      <h3 className="mb-2 font-title text-xl">Facebook</h3>
      <div className="facebook-social-media-preview mb-6">
        <div className="social-media-image">
          <img src={`https://render.pixelperfect.tr/screenshot/http://${project.link}${page.path}?width=1200&height=600`} alt="" />
        </div>
        <div className="social-media-wrapper">
          <div className="social-media-title">{project.title}</div>
          <div className="social-media-description">{project.slogan}</div>
          <div className="social-media-link">
            {project.linkSchema}
            {project.link}
            {page.path}
          </div>
        </div>
      </div>

      <h3 className="mb-2 font-title text-xl">Twitter</h3>
      <div className="twitter-social-media-preview mb-6">
        <div className="social-media-image">
          <img src={`https://render.pixelperfect.tr/screenshot/http://${project.link}${page.path}?width=1200&height=800`} alt="" />
        </div>
        <div className="social-media-wrapper">
          <div className="social-media-link">
            {project.linkSchema}
            {project.link}
            {page.path}
          </div>
          <div className="social-media-title">{project.title}</div>
          <div className="social-media-description">{project.slogan}</div>
        </div>
      </div>

      <p>
        Web sitesi görseli ve açıklaması sosyal ağlarda ve mesajlaşma programlarında hemen güncellenmeyebilir. Bazı hizmetler verileri
        manuel olarak güncellemenize olanak tanır.
      </p>
    </>
  );
}
