import React from 'react';
import { Block } from '../../../service/Type';

function importAll(r: any) {
  return r.keys().map(r);
}

// @ts-ignore
const configs = importAll(require.context('./', true, /config\.ts$/));

export const blockList: Block[] = configs.map((config: any) => ({
  config: config.default,
  editor: React.lazy(() => import(`./${config.default.type}/editor`)),
  viewer: React.lazy(() => import(`./${config.default.type}/viewer`)),
}));
