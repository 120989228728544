import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentFeature, contentTitleDescription } from '../../../../service/api/Content';

export interface EProps {
  title: string;
  description: string;
  texts: {
    icon: string;
    title: string;
    description: string;
  }[];
}

const config: EditorConfigW<EProps> = {
  title: 'Ürün ve Hizmet Özellikleri',
  description: 'Ürün veya hizmetlerin özelliklerini, avantajlarını ve benzersiz yönlerini vurgulayan bilgilendirici blok.',
  type: 'feature-4',
  code: 'FTR4',
  category: ['feature'],
  suitable: ['main'],
  schema: {
    type: 'object',
    required: ['title', 'texts'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      texts: {
        type: 'array',
        title: 'Özellikler',
        items: {
          type: 'object',
          required: ['title', 'description'],
          title: '',
          properties: {
            icon: {
              type: 'string',
              title: 'Material Symbol Icon',
            },
            title: {
              type: 'string',
              title: 'Başlık',
            },
            description: {
              type: 'string',
              title: 'Açıklama',
            },
          },
        },
      },
    },
  },
  schemaUI: {
    texts: {
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for features section in page: "${page.title}"`,
    );

    const cFaq = await contentFeature(
      project.projectId,
      page.pageId,
      `Write 6 title and description for features and create a select an icon from google font symbols for section in page: "${page.title}"`,
    );

    return {
      title: cTitleDescription?.result?.title || 'Özellikler',
      description: cTitleDescription?.result?.description || 'Detaylar. ',
      texts: (cFaq.result || []).map((result) => ({
        icon: result.icon || '',
        title: result.title || '',
        description: result.description || '',
      })),
    };
  },
};

export default config;
