import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta() {
  return (
    <Helmet defaultTitle="Doneweb" titleTemplate="%s | Doneweb">
      <meta charSet="utf-8" />
      <base href="/" target="_blank" />
      <meta name="copyright" content="YouReply Teknoloji Araştırma A.Ş." />
      <meta name="robots" content="index,follow" />
      <meta property="og:locale" content="tr" />
      <meta property="og:site_name" content="Doneweb" />
    </Helmet>
  );
}
