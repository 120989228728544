import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import type { IError, IContentPost, IContentPostUpdateData, IContentPostCreateData, IStatus, IContentPostListOptions } from '../Type';

export const loadContentPostList = async (
  options: IContentPostListOptions,
): Promise<{
  result: IContentPost[];
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentPosts($options: ContentPostListOptions) {
          contentPostList(options: $options) {
            contentPostId
            slug
            title
            content
            seoDescription
            seoTitle
            seoImage
            category {
              title
              slug
              categoryId
            }
            timestampPublish
            timestampCreate
            timestampUpdate
          }
        }
      `,
      variables: {
        options,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPostList,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: [],
  };
};

export const loadContentPost = async (
  contentPostId: string,
): Promise<{
  result?: IContentPost;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentPost($contentPostId: ID!) {
          contentPost(contentPostId: $contentPostId) {
            contentPostId
            title
            content
            slug
            seoTitle
            seoDescription
            seoImage
          }
        }
      `,
      variables: {
        contentPostId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPost,
      errors: handleError(result.errors),
    };
  } catch (e) {
    console.log(e);
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentPostCreate = async (
  data: IContentPostCreateData,
): Promise<{
  result?: IContentPost;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentPostCreate($data: ContentPostCreateData!) {
          contentPostCreate(data: $data) {
            contentPostId
          }
        }
      `,
      variables: {
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPostCreate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentPostUpdate = async (
  contentPostId: string,
  data: IContentPostUpdateData,
): Promise<{
  result?: IContentPost;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentPostUpdate($contentPostId: ID!, $data: ContentPostUpdateData!) {
          contentPostUpdate(contentPostId: $contentPostId, data: $data) {
            contentPostId
            title
          }
        }
      `,
      variables: {
        contentPostId,
        data,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPostUpdate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentPostActivate = async (
  contentPostId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentPostActivate($contentPostId: ID!) {
          contentPostActivate(contentPostId: $contentPostId) {
            message
            status
          }
        }
      `,
      variables: {
        contentPostId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPostActivate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentPostDeactivate = async (
  contentPostId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentPostDeactivate($contentPostId: ID!) {
          contentPostDeactivate(contentPostId: $contentPostId) {
            message
            status
          }
        }
      `,
      variables: {
        contentPostId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPostDeactivate,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};

export const contentPostDelete = async (
  contentPostId: string,
): Promise<{
  result?: IStatus;
  errors?: IError;
}> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation contentPostDelete($contentPostId: ID!) {
          contentPostDelete(contentPostId: $contentPostId) {
            message
            status
          }
        }
      `,
      variables: {
        contentPostId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentPostDelete,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
