import React from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { Button, Modal } from 'antd';
import FormRJSF from '@rjsf/antd';
import FormValidator from '@rjsf/validator-ajv8';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import QuillWidget from '../../../component/editor/component-rjfs/QuillWidget';
import BackgroundWidget from '../../../component/editor/component-rjfs/BackgroundWidget';
import ImageUploadWidget from '../../../component/editor/component-rjfs/ImageUploadWidget';
import MultiUploadWidget from '../../../component/editor/component-rjfs/MultiUploadWidget';
import { contentPostCreate } from '../../../service/api/ContentPost';
import { IContentPostCreateData } from '../../../service/TypeGraphql';

interface PostCreateProps {
  projectId: string;
  onUpdate: any;
  onCancel: any;
}

export default function PostCreate({ projectId, onUpdate, onCancel }: PostCreateProps) {
  const create = async (data: IContentPostCreateData) => {
    await contentPostCreate({
      projectId,
      title: data.title,
      content: data.content,
      seoDescription: data.seoDescription,
      seoTitle: data.seoTitle,
      seoImage: data.seoImage,
    });
    onUpdate();
  };

  const formData = {};

  const schema: RJSFSchema = {
    type: 'object',
    required: ['title', 'content', 'seoImage'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      content: { type: 'string', title: 'Yazı', default: '' },
      seoImage: { type: 'string', title: 'Görsel', default: '' },
      categoryId: { type: 'string', title: 'Kategori', default: '' },
    },
  };

  const schemaUI: UiSchema = {
    content: {
      'ui:widget': 'quill',
    },
    seoImage: {
      'ui:widget': 'imageUpload',
      crop: false,
    },
  };

  const onSubmit = async (data: any) => {
    await create(data.formData);
  };

  const widgets = {
    quill: QuillWidget,
    background: BackgroundWidget,
    imageUpload: ImageUploadWidget,
    multiUpload: MultiUploadWidget,
  };

  return (
    <Modal open footer="" onCancel={onCancel} width="80%" title="Yazı Oluştur">
      <StyleProvider>
        <FormRJSF
          schema={schema}
          uiSchema={schemaUI}
          validator={FormValidator}
          formData={formData}
          onSubmit={onSubmit}
          action="test"
          widgets={widgets}
        >
          <React.Fragment />
          <div className="">
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </div>
        </FormRJSF>
      </StyleProvider>
    </Modal>
  );
}
