import { EditorConfigW, IPage, IProject } from '../../../../service/Type';
import { contentTitleDescription } from '../../../../service/api/Content';

export interface Link {
  link: string;
  text: string;
  links?: Link[];
}

export interface Button {
  link: string;
  text: string;
  icon: string;
  type: 'raised' | 'transparent';
}

export interface EProps {
  title: string;
  description: string;
  image: string;
  logoHeight: number;
  links: Link[];
  buttonsHeader: Button[];
  buttonsHero: Button[];
}

const config: EditorConfigW<EProps> = {
  title: 'Ana Sayfa Giriş Bloğu',
  description: 'Büyük bir başlık görseli ve kısa bir giriş metni içeren, sayfanın üst kısmında dikkat çekici bir giriş bölümü.',
  type: 'header-5',
  code: 'HDR5',
  category: ['header'],
  suitable: ['header'],
  schema: {
    type: 'object',
    required: ['links', 'title', 'description'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      description: { type: 'string', title: 'Açıklama', default: '' },
      links: {
        type: 'array',
        title: 'Bağlantılar',
        items: {
          type: 'object',
          required: ['text', 'link'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            link: {
              type: 'string',
              title: 'Bağlantı',
            },
          },
        },
      },
      buttonsHeader: {
        type: 'array',
        title: 'Header Butonları',
        items: {
          type: 'object',
          required: ['text', 'link'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            link: {
              type: 'string',
              title: 'Bağlantı',
            },
          },
        },
      },
      buttonsHero: {
        type: 'array',
        title: 'Hero Butonları',
        items: {
          type: 'object',
          required: ['text', 'link'],
          title: '',
          properties: {
            text: {
              type: 'string',
              title: 'Başlık',
            },
            link: {
              type: 'string',
              title: 'Bağlantı',
            },
          },
        },
      },
    },
  },
  styleDefault: {
    container: 'full',
  },
  propsDefault: async (project: IProject, page: IPage) => {
    const cTitleDescription = await contentTitleDescription(
      project.projectId,
      page.pageId,
      `Write title and description for hero section for project. `,
    );

    return {
      title: cTitleDescription?.result?.title || project.title || '',
      description: cTitleDescription?.result?.description || project.slogan || '',
      logoHeight: 80,
      image: '/img/header/hero-image.jpg',
      links: [
        {
          link: '/hakkimizda',
          text: 'Hakkımızda',
        },
        {
          link: '/iletisim',
          text: 'İletişim',
        },
      ],
      buttonsHeader: [
        {
          link: '/hakkimizda',
          text: 'Hakkımızda',
          icon: '',
          type: 'transparent',
        },
        {
          link: '/iletisim',
          text: 'İletişim',
          icon: '',
          type: 'raised',
        },
      ],
      buttonsHero: [
        {
          link: '/hakkimizda',
          text: 'Hakkımızda',
          icon: 'about',
          type: 'transparent',
        },
        {
          link: '/iletisim',
          text: 'İletişim',
          icon: 'contact',
          type: 'raised',
        },
      ],
    };
  },
};

export default config;
