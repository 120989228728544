import React, { useState } from 'react';
import { EditorConfig, EditorProps, EditorStyle, IPage, IPageBlock, IProject } from '../../service/Type';
import { Button, Drawer, Segmented } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import FormRJSF from '@rjsf/antd';
import FormValidator from '@rjsf/validator-ajv8';
import { blockList } from './component/Data';
import ImageUploadWidget from './component-rjfs/ImageUploadWidget';
import MultiUploadWidget from './component-rjfs/MultiUploadWidget';
import QuillWidget from './component-rjfs/QuillWidget';
import BackgroundWidget from './component-rjfs/BackgroundWidget';
import './EditorBlockContent.scss';

interface EditorBlockContentProps {
  open: boolean;
  onCancel: any;
  onUpdate: (props: EditorProps, style: EditorStyle) => void;
  pageBlocks: IPageBlock[];
  blockId: string;
  project: IProject;
  page: IPage;
}

export default function EditorBlockContent({ open, onUpdate, onCancel, pageBlocks, blockId }: EditorBlockContentProps) {
  const [segment, setSegment] = useState('content');
  const pageBlockToEdit = pageBlocks.find((pageBlock) => pageBlock.blockId === blockId) as IPageBlock;

  const blockConfig = blockList.find((block) => block.config.type === pageBlockToEdit.block.type)?.config as EditorConfig;

  const onSubmitContent = async (values: any) => {
    await onUpdate(
      {
        ...pageBlockToEdit.block.props,
        ...values.formData,
      },
      pageBlockToEdit.block.style,
    );
  };

  const onSubmitStyle = async (values: any) => {
    await onUpdate(
      {
        ...pageBlockToEdit.block.props,
      },
      {
        ...pageBlockToEdit.block.style,
        ...values.formData,
      },
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  const widgets = {
    quill: QuillWidget,
    background: BackgroundWidget,
    imageUpload: ImageUploadWidget,
    multiUpload: MultiUploadWidget,
  };

  const transformErrors = (errors: any) => {
    return errors.map((error: any) => {
      switch (error.name) {
        case 'required':
          error.message = 'Bu alan gerekli.';
          break;
        case 'minLength':
          error.message = `${error.params.limit} karakterden kısa olmamalıdır`;
          break;
        case 'maxLength':
          error.message = `${error.params.limit} karakterden uzun olmamalıdır`;
          break;
        case 'pattern':
          error.message = 'Geçersiz format';
          break;
        case 'minimum':
          error.message = `${error.params.limit} veya daha büyük olmalıdır`;
          break;
        case 'maximum':
          error.message = `${error.params.limit} veya daha küçük olmalıdır`;
          break;
        case 'enum':
          error.message = 'İzin verilen değerlerden birine eşit olmalıdır';
          break;
        case 'format':
          error.message = `${error.params.format} biçimine uymalıdır`;
          break;
        case 'type':
          if (error.params.type === 'string') {
            error.message = 'Bu alan metin olmalıdır';
          }
          // Diğer tip hataları için benzer koşullar eklenebilir
          break;
      }
      return error;
    });
  };

  return (
    <Drawer title="Blok İçeriklerini Düzenle" open={open} onClose={onCancel} width="80%" placement="left">
      <Segmented
        block
        options={[
          {
            value: 'content',
            label: 'İçerikler',
          },
          {
            value: 'style',
            label: 'Tasarım',
          },
        ]}
        value={segment}
        onChange={(e) => setSegment(e.toString())}
        style={{
          marginBottom: 16,
        }}
      />

      {segment === 'content' && (
        <>
          {blockConfig.schema && (
            <StyleProvider>
              <FormRJSF
                schema={blockConfig.schema}
                uiSchema={blockConfig.schemaUI}
                validator={FormValidator}
                formData={pageBlockToEdit.block.props}
                onChange={log('changed')}
                onSubmit={onSubmitContent}
                onError={log('errors')}
                action="test"
                widgets={widgets}
                transformErrors={transformErrors}
              >
                <React.Fragment />
                <div className="rjsf-save">
                  <Button type="primary" htmlType="submit">
                    Kaydet
                  </Button>
                </div>
              </FormRJSF>
            </StyleProvider>
          )}
        </>
      )}

      {segment === 'style' && (
        <StyleProvider>
          <FormRJSF
            schema={{
              type: 'object',
              required: ['container', 'background'],
              properties: {
                container: {
                  type: 'string',
                  title: 'Genişlik',
                  default: 'compact',
                  oneOf: [
                    { title: 'Tam Genişlik', const: 'full' },
                    { title: 'Kompakt', const: 'compact' },
                  ],
                },
                color: { type: 'string', title: 'Yazı Rengi', default: '#000' },
                background: { type: 'string', title: 'Arkaplan', default: '#fff' },
              },
            }}
            uiSchema={{
              container: {
                'ui:widget': 'radio',
              },
              background: {
                'ui:widget': 'background',
              },
              color: {
                'ui:widget': 'color',
              },
            }}
            validator={FormValidator}
            formData={pageBlockToEdit.block.style}
            onChange={log('changed')}
            onSubmit={onSubmitStyle}
            onError={log('errors')}
            action="test"
            widgets={widgets}
            transformErrors={transformErrors}
          >
            <React.Fragment />
            <div className="rjsf-save">
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </div>
          </FormRJSF>
        </StyleProvider>
      )}
    </Drawer>
  );
}
