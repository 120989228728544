import React, { useState } from 'react';
import { Drawer, Segmented } from 'antd';
import EditorPageSettingGeneral from './EditorPageSettingGeneral';
import EditorPageSettingSeo from './EditorPageSettingSeo';
import EditorPageSettingSocialMedia from './EditorPageSettingSocialMedia';
import { IPage, IProject } from '../../service/TypeGraphql';

interface EditorPageSettingProps {
  open: boolean;
  onCancel: any;
  project: IProject;
  page: IPage;
}

export default function EditorPageSetting({ open, onCancel, project, page }: EditorPageSettingProps) {
  const [segment, setSegment] = useState('genel');

  return (
    <Drawer title="Sayfa Ayarları" open={open} onClose={onCancel} width="80%" placement="left">
      <Segmented
        block
        options={[
          {
            value: 'genel',
            label: 'Genel',
          },
          {
            value: 'seo',
            label: 'Seo',
          },
          {
            value: 'design',
            label: 'Tasarım',
          },
        ]}
        value={segment}
        onChange={(e) => setSegment(e.toString())}
        style={{ marginBottom: 16 }}
      />

      {segment === 'genel' && <EditorPageSettingGeneral onSubmitSuccess={() => onCancel()} project={project} page={page} />}

      {segment === 'seo' && <EditorPageSettingSeo onSubmitSuccess={() => {}} project={project} page={page} />}

      {segment === 'design' && <EditorPageSettingSocialMedia onSubmitSuccess={() => {}} project={project} page={page} />}
    </Drawer>
  );
}
