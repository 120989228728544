import React, { useEffect, useState } from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import { Button, Modal } from 'antd';
import FormRJSF from '@rjsf/antd';
import FormValidator from '@rjsf/validator-ajv8';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import QuillWidget from '../../../component/editor/component-rjfs/QuillWidget';
import BackgroundWidget from '../../../component/editor/component-rjfs/BackgroundWidget';
import ImageUploadWidget from '../../../component/editor/component-rjfs/ImageUploadWidget';
import MultiUploadWidget from '../../../component/editor/component-rjfs/MultiUploadWidget';
import { loadContentProduct, contentProductUpdate } from '../../../service/api/ContentProduct';
import { IContentProduct, IContentProductUpdateData } from '../../../service/TypeGraphql';

interface ProductEditProps {
  contentProductId: string;
  onUpdate: any;
  onCancel: any;
}

export default function ProductEdit({ contentProductId, onUpdate, onCancel }: ProductEditProps) {
  const [contentProduct, setContentProduct] = useState<IContentProduct>();

  const load = async () => {
    const contentProductResult = await loadContentProduct(contentProductId);
    if (contentProductResult.result) {
      setContentProduct(contentProductResult.result);
    }
  };

  const update = async (data: IContentProductUpdateData) => {
    await contentProductUpdate(contentProductId, {
      title: data.title,
      detail: data.detail,
      link: data.link,
      seoDescription: data.seoDescription,
      seoTitle: data.seoTitle,
      seoImage: data.seoImage,
    });
    onUpdate();
  };

  useEffect(() => {
    load();
  }, [contentProductId]);

  const formData = {
    ...contentProduct,
  };

  console.log('formData', formData);
  console.log('contentProduct', contentProduct);

  const schema: RJSFSchema = {
    type: 'object',
    required: ['title', 'detail', 'seoImage'],
    properties: {
      title: { type: 'string', title: 'Başlık', default: '' },
      detail: { type: 'string', title: 'Yazı', default: '' },
      seoImage: { type: 'string', title: 'Görsel', default: '' },
      link: { type: 'string', title: 'Örnek', default: '' },
      // categoryId: { type: 'string', title: 'Kategori', default: '' },
    },
  };

  const schemaUI: UiSchema = {
    detail: {
      'ui:widget': 'quill',
    },
    seoImage: {
      'ui:widget': 'imageUpload',
      crop: false,
    },
  };

  const onSubmit = async (data: any) => {
    await update(data.formData);
  };

  const widgets = {
    quill: QuillWidget,
    background: BackgroundWidget,
    imageUpload: ImageUploadWidget,
    multiUpload: MultiUploadWidget,
  };

  return (
    <Modal open footer="" onCancel={onCancel} width="80%" title={`Ürün Düzenle #${contentProductId}`}>
      {!contentProduct && <>Yükleniyor. </>}
      {contentProduct && (
        <StyleProvider>
          <FormRJSF
            schema={schema}
            uiSchema={schemaUI}
            validator={FormValidator}
            formData={formData}
            onSubmit={onSubmit}
            action="test"
            widgets={widgets}
          >
            <React.Fragment />
            <div className="">
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </div>
          </FormRJSF>
        </StyleProvider>
      )}
    </Modal>
  );
}
