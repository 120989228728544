import React from 'react';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  items: BreadcrumbItemType[];
  style?: React.CSSProperties;
}

export default function BreadcrumbComponent({ items, style }: BreadcrumbProps) {
  const itemsAll: BreadcrumbItemType[] = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    ...items,
  ];

  if (itemsAll.length === 0) {
    return null;
  }

  return <Breadcrumb style={{ margin: '0 0 16px 0', ...style }} items={itemsAll} />;
}
