import { notification } from 'antd';
import { gql } from '@apollo/client';
import { client } from './Api';
import { handleError } from './ApiError';
import type { IError, IContentSeo } from '../Type';

export const contentSeoFromPageTitle = async (
  projectId: string,
  title: string,
): Promise<{
  result?: IContentSeo;
  errors?: IError;
}> => {
  try {
    const result = await client.query({
      query: gql`
        query contentSeoFromPageTitle($projectId: ID!, $title: String!) {
          contentSeoFromPageTitle(projectId: $projectId, title: $title) {
            seoTitle
            seoDescription
          }
        }
      `,
      variables: {
        projectId,
        title,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return {
      result: result.data.contentSeoFromPageTitle,
      errors: handleError(result.errors),
    };
  } catch (e) {
    notification.error({
      message: 'Hata',
      description: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin. ',
    });
  }

  return {
    result: undefined,
  };
};
