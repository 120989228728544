import { ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { createSlug } from '../../../service/util/Slug';
import { contentSeoFromPageTitle } from '../../../service/api/Seo';
import { IProject } from '../../../service/TypeGraphql';

interface WebsiteSettingFormProps {
  project: IProject;
}

export default function WebsiteSettingForm({ project }: WebsiteSettingFormProps) {
  const { values, setFieldValue } = useFormikContext<any>();
  const [loadingSeo, setLoadingSeo] = useState(false);

  const timer = useRef<any>(null);

  useEffect(() => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(async () => {
      setLoadingSeo(true);
      const seo = await contentSeoFromPageTitle(project.projectId, values.title);
      await setFieldValue('seoTitle', seo?.result?.seoTitle);
      await setFieldValue('seoDescription', seo?.result?.seoDescription);
      setLoadingSeo(false);
    }, 300);
  }, [values.title]);

  return (
    <>
      <Form.Item label="Sayfa Başlığı">
        <Input
          name="title"
          value={values.title ? values.title : ''}
          onChange={async (e) => {
            await setFieldValue('title', e.target.value);
            await setFieldValue('path', createSlug(e.target.value));
          }}
        />
        <ErrorMessage name="title" component="div" className="ant-form-feedback" />
      </Form.Item>
      <Form.Item label="Sayfa Linki">
        <Input
          addonBefore={`${project?.linkSchema}${project?.link}/`}
          name="path"
          value={values.path ? values.path : ''}
          onChange={(e) => setFieldValue('path', e.target.value)}
          disabled={values.path === '/'}
        />
        <ErrorMessage name="path" component="div" className="ant-form-feedback" />
      </Form.Item>
      <Form.Item
        label={
          <>
            SEO Başlığı <span> </span> {loadingSeo ? <LoadingOutlined style={{ fontSize: 14, marginLeft: 4 }} spin /> : null}
          </>
        }
      >
        <Input.TextArea
          name="seoTitle"
          value={values.seoTitle ? values.seoTitle : ''}
          onChange={(e) => setFieldValue('seoTitle', e.target.value)}
        />
        <ErrorMessage name="seoTitle" component="div" className="ant-form-feedback" />
      </Form.Item>
      <Form.Item
        label={
          <>
            SEO Açıklaması <span> </span> {loadingSeo ? <LoadingOutlined style={{ fontSize: 14, marginLeft: 4 }} spin /> : null}
          </>
        }
      >
        <Input.TextArea
          name="seoDescription"
          value={values.seoDescription ? values.seoDescription : ''}
          onChange={(e) => setFieldValue('seoDescription', e.target.value)}
        />
        <ErrorMessage name="seoDescription" component="div" className="ant-form-feedback" />
      </Form.Item>
    </>
  );
}
